import * as Sentry from '@sentry/nextjs';

export function getItem(name: string): string | null {
  try {
    if (typeof localStorage === 'object') {
      return localStorage.getItem(name);
    }
  } catch (err) {
    Sentry.captureException(err);
  }
  return null;
}

export function getAndParseItem<T>(name: string): T | null {
  try {
    const value = getItem(name);
    if (value === null) {
      return value;
    }
    const parsedValue = JSON.parse(value) as T;
    return parsedValue;
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
}

export function removeItem(name: string) {
  try {
    if (typeof localStorage === 'object') {
      localStorage.removeItem(name);
    }
  } catch (err) {
    Sentry.captureException(err);
  }
}

export function setItem(name: string, item: string): boolean {
  try {
    if (typeof localStorage === 'object') {
      localStorage.setItem(name, item);
      return true;
    }
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
  return false;
}
