const SET_LOADING = 'zehitomo/layout/SET_LOADING';
const SET_SHOWING_REQUEST_MODAL = 'zehitomo/layout/SET_SHOWING_REQUEST_MODAL';
const SET_SHOWING_RATE_LIMITED = 'zehitomo/layout/SET_SHOWING_RATE_LIMITED';
const SET_LOADING_OVERRIDE = 'zehitomo/layout/SET_LOADING_OVERRIDE';

export const setLoading = (loading) => ({
  payload: { loading },
  type: SET_LOADING,
});

/**
 * This action is an escape hatch for utilizing loading state over multiple logics.
 * THIS SHOULD NOT BE THE DEFAULT LOADING USED. Should be used as a last resort because if managing loading
 * state within a single react component or in a single logic should be sufficient for most use cases.
 */
export const setLoadingOverride = (loading) => ({
  payload: { loading },
  type: SET_LOADING_OVERRIDE,
});

export const setShowingRateLimited = (showingRateLimited) => ({
  payload: { showingRateLimited },
  type: SET_SHOWING_RATE_LIMITED,
});

export const setShowingRequestModal = (showingRequestModal) => ({
  payload: { showingRequestModal },
  type: SET_SHOWING_REQUEST_MODAL,
});

export const initialState = {
  loading: false,
  rateLimited: false,
  showingRequestModal: false,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.payload.loading };
    case SET_LOADING_OVERRIDE:
      return { ...state, loadingOverride: action.payload.loading };
    case SET_SHOWING_REQUEST_MODAL:
      return { ...state, showingRequestModal: action.payload.showingRequestModal };
    case SET_SHOWING_RATE_LIMITED:
      return { ...state, showingRateLimited: action.payload.showingRateLimited };
    default:
      return state;
  }
};
export default layoutReducer;

export const loadingSelector = (state) => state.layout.loadingOverride ?? state.layout.loading;
export const showingRequestModalSelector = (state) => state.layout.showingRequestModal;
export const showingRateLimitedSelector = (state) => state.layout.showingRateLimited;
