/* eslint-disable no-restricted-properties */
import _ from 'lodash';

import { CREATE_REQUEST_SUCCESS } from 'modules/requests/duck/types';
import { UPLOAD_AVATAR_SUCCESS } from '../modules/auth/duck';
import {
  AUTH_USER,
  AUTH_USER_SUCCESS,
  AUTH_USER_ERROR,
  CLEAR_NOTIFICATIONS,
  GET_NOTIFICATION_SUCCESS,
  LIST_NOTIFICATIONS_SUCCESS,
  MARK_A_SINGLE_NOTIFICATION_SUCCESS,
  MARK_ALL_NOTIFICATIONS_SUCCESS,
  SIGN_OUT_SUCCESS,
  LOGIN_VALUE_EXISTING,
  LIST_ANNOUNCEMENTS_SUCCESS,
  MARK_A_SINGLE_ANNOUNCEMENT_SUCCESS,
  MARK_ALL_ANNOUNCEMENTS_SUCCESS,
} from './types';

export const initialState = {
  announcements: {},
  isAuthenticated: false,
  isLoadingAuth: false,
  isCredentialExisting: false,
  currentRequestUser: null,
  currentUser: null,
  loadedAnnouncements: false,
  loadedNotifications: false,
  notifications: {},
  timestamp: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_VALUE_EXISTING: {
      return {
        ...state,
        isCredentialExisting: action.payload.isCredentialExisting,
      };
    }
    case AUTH_USER: {
      return {
        ...state,
        isLoadingAuth: true,
      };
    }
    case AUTH_USER_ERROR: {
      return { ...state, isLoadingAuth: false };
    }
    case CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        currentRequestUser: {
          id: action.payload.uid,
          token: action.payload.token,
        },
      };

    case AUTH_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.currentUser,
        isAuthenticated: true,
        isLoadingAuth: false,
      };

    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        loadedNotifications: false,
        notifications: {},
        timestamp: null,
      };

    case GET_NOTIFICATION_SUCCESS: {
      const notification = action.payload;
      const { createdTime, id, time } = notification;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [id]: _.merge(state.notifications[id], notification),
        },
        timestamp: time || createdTime,
      };
    }

    case LIST_NOTIFICATIONS_SUCCESS: {
      const latest = _.maxBy(action.payload, 'time');
      return {
        ...state,
        loadedNotifications: true,
        notifications: _.reduce(
          action.payload,
          (hash, notif) => ({
            ...hash,
            [notif.id]: {
              ...state.notifications[notif],
              ...notif,
            },
          }),
          state.notifications
        ),
        timestamp: latest ? latest.time : null,
      };
    }

    case MARK_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: _.reduce(
          state.notifications,
          (hash, notif) => {
            const time = notif.time || notif.createdTime;
            const isRead = time <= action.payload.timestamp;
            return {
              ...hash,
              [notif.id]: {
                ...notif,
                isRead: isRead || null,
              },
            };
          },
          {}
        ),
      };

    case MARK_A_SINGLE_NOTIFICATION_SUCCESS: {
      const notification = action.payload;
      const { id } = notification;
      // only update state if the updated notification is currently in state
      if (state.notifications[id]) {
        return {
          ...state,
          notifications: {
            ...state.notifications,
            [id]: {
              ...state.notifications[id],
              ...notification,
            },
          },
        };
      }
      return {
        ...state,
      };
    }

    case LIST_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        loadedAnnouncements: true,
        announcements: action.payload,
      };
    }

    case MARK_A_SINGLE_ANNOUNCEMENT_SUCCESS: {
      const targetAnnouncementId = action.payload;
      const updatedAnnouncements = [...state.announcements];

      for (const announcement of updatedAnnouncements) {
        if (announcement.announcementId === targetAnnouncementId) {
          announcement.isRead = true;
          break;
        }
      }

      return {
        ...state,
        announcements: updatedAnnouncements,
      };
    }

    case MARK_ALL_ANNOUNCEMENTS_SUCCESS: {
      const status = action.payload;
      if (status !== 'OK') return { ...state };
      // eslint-disable-next-line no-return-assign
      const updatedAnnouncements = [...state.announcements].map((announcement) => ({
        ...announcement,
        isRead: true,
      }));

      return {
        ...state,
        announcements: updatedAnnouncements,
      };
    }

    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        isAuthenticated: false,
      };

    case UPLOAD_AVATAR_SUCCESS: {
      const { data } = action.payload;
      if (!data) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...data,
          profile: {
            ...state.currentUser.profile,
            ...data.profile,
          },
        },
      };
    }

    default:
      return state;
  }
};
export default reducer;
