import type { JobType, ServiceOfTheMonthJobType, VerticalPageJobType } from '../types';

// get detail of 1 job type
export const GET_DETAIL = 'zehitomo/job-types/GET_DETAIL';
export const GET_DETAIL_ERROR = 'zehitomo/job-types/GET_DETAIL_ERROR';
export const GET_DETAIL_SUCCESS = 'zehitomo/job-types/GET_DETAIL_SUCCESS';

export const LIST = 'zehitomo/job-types/LIST';
export const LIST_ERROR = 'zehitomo/job-types/LIST_ERROR';
export const LIST_SUCCESS = 'zehitomo/job-types/LIST_SUCCESS';

export const LIST_BY_CATEGORY = 'zehitomo/job-types/LIST_BY_CATEGORY';
export const LIST_BY_CATEGORY_ERROR = 'zehitomo/job-types/LIST_BY_CATEGORY_ERROR';
export const LIST_BY_CATEGORY_SUCCESS = 'zehitomo/job-types/LIST_BY_CATEGORY_SUCCESS';

export const LIST_POPULAR = 'zehitomo/job-types/LIST_POPULAR';
export const LIST_POPULAR_ERROR = 'zehitomo/job-types/LIST_POPULAR_ERROR';
export const LIST_POPULAR_SUCCESS = 'zehitomo/job-types/LIST_POPULAR_SUCCESS';

export const LOAD_RELATED = 'zehitomo/job-types/LOAD_RELATED';
export const LOAD_RELATED_ERROR = 'zehitomo/job-types/LOAD_RELATED_ERROR';
export const LOAD_RELATED_SUCCESS = 'zehitomo/job-types/LOAD_RELATED_SUCCESS';

export const LIST_SERVICE_OF_THE_MONTH = 'zehitomo/job-types/LIST_SERVICE_OF_THE_MONTH';
export const LIST_SERVICE_OF_THE_MONTH_ERROR = 'zehitomo/job-types/LIST_SERVICE_OF_THE_MONTH_ERROR';
export const LIST_SERVICE_OF_THE_MONTH_SUCCESS =
  'zehitomo/job-types/LIST_SERVICE_OF_THE_MONTH_SUCCESS';

export const SET_VERTICAL_PAGE_JOB_TYPE = 'zehitomo/job-types/SET_VERTICAL_PAGE_JOB_TYPE';

export interface State {
  hasDetails: Record<string, boolean>;
  list: Record<string, JobType>;
  listByCategory: Record<string, JobType>;
  popular: Array<JobType & { categories: Array<string> }>;
  related: Record<string, Array<JobType>>;
  serviceOfTheMonth: Array<ServiceOfTheMonthJobType>;
  verticalPageJobType: VerticalPageJobType | null;
}
