/* eslint-disable no-underscore-dangle, camelcase */

export interface ConvertExperiment {
  variation_id: string;
}

export interface ConvertData {
  experiments: Record<string, ConvertExperiment>;
}

declare global {
  export interface Window {
    _conv_q?: Array<Array<string | number>>;
    convert?: {
      currentData?: ConvertData;
    };
    convertAudience?: {
      authenticationState?: 'authenticated' | 'unauthenticated';
    };
    convertExperienceManualTriggers?: {
      [experimentId: string]: boolean;
    };
  }
}

export enum Environment {
  PreProd = 'preProd',
  Prod = 'prod',
}

interface ExperimentVariant {
  [variantName: string]: string;
}

interface ConvertExperimentEnvironment {
  experimentId: string;
  variants: ExperimentVariant;
}
