import {
  CLOSE_SOCKET,
  REGISTER_SOCKET_EVENT,
  REGISTER_SOCKET_EVENT_SUCCESS,
  SETUP_SOCKET,
} from './types';

export const closeSocket = () => ({ type: CLOSE_SOCKET });

export const registerSocketEvent = event => ({
  type: REGISTER_SOCKET_EVENT,
  payload: { event },
});
export const registerSocketEventSuccess = event => ({
  type: REGISTER_SOCKET_EVENT_SUCCESS,
  payload: { event },
});

export const setupSocket = () => ({ type: SETUP_SOCKET });
