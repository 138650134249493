// TODO Create proper action types
/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-template-expressions */
import { AnyAction } from 'redux';
import { isDefined } from 'utils/type-guards';

import { unrollMatchingCriteriaFiltersToString } from '../utils';

import {
  EXPAND_PRICING_ITEMS,
  GET_NEWEST_REVIEWS_BY_JOB_TYPE_SUCCESS,
  GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_SUCCESS,
  GET_POPULAR_PRICING_ITEMS_SUCCESS,
  GET_PRO_COUNT_SUCCESS,
  LIST_BLOG_POSTS_SUCCESS,
  LIST_BLOG_POSTS_BY_CATEGORY_SUCCESS,
  LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_SUCCESS,
  LIST_PROS,
  LIST_PROS_SUCCESS,
  LIST_PROS_GROUP,
  LIST_PROS_GROUP_SUCCESS,
  LIST_SAMPLE_REQUESTS_SUCCESS,
  State,
  GET_CASE_STUDIES,
  GET_CASE_STUDIES_SUCCESS,
  GET_CASE_STUDIES_BY_USER_ID,
  GET_CASE_STUDIES_BY_USER_ID_SUCCESS,
  GET_ALL_CASE_STUDIES_BY_USER_ID,
  GET_ALL_CASE_STUDIES_BY_USER_ID_SUCCESS,
  GET_POPULAR_REQUESTS,
  GET_POPULAR_REQUESTS_SUCCESS,
  ADD_FILTER,
  REMOVE_FILTER,
  INITIALIZE_FILTERS,
  REMOVE_MATCHING_CRITERIA_FILTER,
  ADD_MATCHING_CRITERIA_FILTER,
  UPDATE_TIME_FILTER,
  UPDATE_DAY_FILTER,
  GET_PRO_CATEGORIES_FOR_SIGN_UP_SUCCESS,
  SET_VERTICAL_PAGE_TYPE,
} from './types';

export const DEFAULT_CASE_STUDIES_PAGE_SIZE = 6;

export const initialState: State = {
  blogPosts: [],
  caseStudies: [],
  caseStudiesAll: [],
  caseStudiesFetchParameters: '',
  caseStudiesPageSize: DEFAULT_CASE_STUDIES_PAGE_SIZE,
  day: '',
  filters: {
    matchingCriteria: {},
  },
  isPopularPricingItemsExpanded: false,
  newestReviews: [],
  popularPricingItems: [],
  popularRequests: [],
  popularRequestsFetchParameters: '',
  pricingMenuItemKey: '',
  proBlogPosts: [],
  proCount: 0,
  proCountGroup: 0,
  proFetchParameters: '',
  proReviewStatistics: null,
  proJobCategoriesForSignUp: { trees: [], createdDate: -1 },
  pros: [],
  prosGroup: [],
  sampleRequests: [],
  time: '',
  totalCaseStudies: 0,
  verticalPageType: 'unknown',
};

const reducer = (state = initialState, action: AnyAction): State => {
  switch (action.type) {
    case EXPAND_PRICING_ITEMS:
      return { ...state, isPopularPricingItemsExpanded: true };

    case GET_POPULAR_PRICING_ITEMS_SUCCESS:
      return { ...state, popularPricingItems: action.payload };

    case LIST_BLOG_POSTS_SUCCESS:
      return { ...state, blogPosts: action.payload || [] };

    case LIST_BLOG_POSTS_BY_CATEGORY_SUCCESS:
      return { ...state, blogPosts: action.payload || [] };

    case LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_SUCCESS:
      return { ...state, proBlogPosts: action.payload || [] };

    case GET_NEWEST_REVIEWS_BY_JOB_TYPE_SUCCESS:
      return { ...state, newestReviews: action.payload || [] };

    case GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_SUCCESS:
      return { ...state, newestReviews: action.payload || [] };

    case GET_PRO_COUNT_SUCCESS:
      return { ...state, proCount: action.payload.count };

    case LIST_PROS: {
      const newType = [
        action.payload.jobTypeId,
        action.payload.prefectureKey,
        action.payload.cityGroup,
        action.payload.cityKey,
      ]
        .filter(isDefined)
        .join('/');
      const filtersType = Object.entries(state.filters)
        .map(([key, value]) => {
          if (key === 'matchingCriteria') {
            if (Object.keys(value).length === 0) {
              return;
            }
            const currentMatchingCriteria = unrollMatchingCriteriaFiltersToString(value);
            return `${key}=${currentMatchingCriteria}`;
          }
          return `${key}=${value}`;
        })
        .filter(isDefined)
        .join('&');
      const newFetchParameters = `${newType}${filtersType.length > 0 ? `?${filtersType}` : ''}`;
      const areFetchParametersSame =
        newFetchParameters === state.proFetchParameters &&
        action.payload.pricingMenuItemKey === state.pricingMenuItemKey;

      return {
        ...state,
        pricingMenuItemKey: action.payload.pricingMenuItemKey ?? '',
        proFetchParameters: newFetchParameters,
        pros: areFetchParametersSame ? state.pros : [],
      };
    }

    case ADD_FILTER: {
      return {
        ...state,
        filters: { ...state.filters, ...{ [action.filterId]: action.filterValue } },
      };
    }
    case REMOVE_FILTER: {
      const newFilters = { ...state.filters };
      delete newFilters[action.filterId as 'day' | 'time' | 'priceRange'];
      return { ...state, filters: newFilters };
    }
    case REMOVE_MATCHING_CRITERIA_FILTER: {
      const newMatchingCriteria = { ...state.filters.matchingCriteria };
      if (action.matchingCriteriaValues === null) {
        delete newMatchingCriteria[action.matchingCriteriaKey];
      } else {
        newMatchingCriteria[action.matchingCriteriaKey] = action.matchingCriteriaValues;
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          matchingCriteria: newMatchingCriteria,
        },
      };
    }
    case ADD_MATCHING_CRITERIA_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          matchingCriteria: {
            ...state.filters.matchingCriteria,
            ...{ [action.matchingCriteriaKey]: action.matchingCriteriaValues },
          },
        },
      };
    }

    case UPDATE_TIME_FILTER: {
      return { ...state, filters: { ...state.filters, time: action.time } };
    }

    case UPDATE_DAY_FILTER: {
      return { ...state, filters: { ...state.filters, day: action.day } };
    }

    case INITIALIZE_FILTERS: {
      const result = { ...state, filters: { ...state.filters, ...action.filters } };
      return result;
    }

    case LIST_PROS_SUCCESS:
      return {
        ...state,
        proCount: action.payload.total,
        proReviewStatistics: action.payload.proReviewStatistics,
        pros: [...state.pros, ...action.payload.pros],
      };

    case LIST_PROS_GROUP: {
      const newType = [
        action.payload.jobTypeId,
        action.payload.prefectureKey,
        action.payload.cityGroup,
        action.payload.cityKey,
      ]
        .filter(isDefined)
        .join('/');
      const areFetchParametersSame =
        newType === state.proFetchParameters &&
        action.payload.pricingMenuItemKey === state.pricingMenuItemKey;

      return {
        ...state,
        pricingMenuItemKey: action.payload.pricingMenuItemKey ?? '',
        proFetchParameters: newType,
        prosGroup: areFetchParametersSame ? state.prosGroup : [],
      };
    }

    case LIST_PROS_GROUP_SUCCESS:
      return {
        ...state,
        proCountGroup: action.payload.total,
        prosGroup: [...state.prosGroup, ...action.payload.pros],
      };

    case LIST_SAMPLE_REQUESTS_SUCCESS:
      return { ...state, sampleRequests: action.payload };

    case GET_CASE_STUDIES: {
      const newType = [
        action.payload.jobTypeId,
        action.payload.prefectureKey,
        action.payload.cityKey,
      ]
        .filter(isDefined)
        .join('/');
      const areFetchParametersSame = newType === state.caseStudiesFetchParameters;

      return {
        ...state,
        caseStudiesFetchParameters: newType,
        caseStudies: areFetchParametersSame ? state.caseStudies : [],
      };
    }

    case GET_CASE_STUDIES_BY_USER_ID: {
      const newType = [action.payload.userId].filter(isDefined).join('/');
      const areFetchParametersSame = newType === state.caseStudiesFetchParameters;

      return {
        ...state,
        caseStudiesFetchParameters: newType,
        caseStudies: areFetchParametersSame ? state.caseStudies : [],
      };
    }

    case GET_ALL_CASE_STUDIES_BY_USER_ID: {
      const newType = [action.payload.userId].filter(isDefined).join('/');
      const areFetchParametersSame = newType === state.caseStudiesFetchParameters;

      return {
        ...state,
        caseStudiesFetchParameters: newType,
        caseStudiesAll: areFetchParametersSame ? state.caseStudies : [],
      };
    }

    case GET_CASE_STUDIES_SUCCESS:
      return {
        ...state,
        totalCaseStudies: action.payload.totalCaseStudies,
        caseStudies: [...state.caseStudies, ...action.payload.caseStudies],
      };

    case GET_CASE_STUDIES_BY_USER_ID_SUCCESS:
      return {
        ...state,
        totalCaseStudies: action.payload.totalCaseStudies,
        caseStudies: [...state.caseStudies, ...action.payload.caseStudies],
      };

    case GET_ALL_CASE_STUDIES_BY_USER_ID_SUCCESS:
      return {
        ...state,
        totalCaseStudies: action.payload.totalCaseStudies,
        caseStudiesAll: action.payload.caseStudies,
      };

    case GET_POPULAR_REQUESTS: {
      const newType = [
        action.payload.jobTypeId,
        action.payload.prefectureKey,
        action.payload.cityKey,
      ]
        .filter(isDefined)
        .join('/');

      const isSimilarFetchParameters = newType === state.caseStudiesFetchParameters;

      return {
        ...state,
        popularRequestsFetchParameters: newType,
        popularRequests: isSimilarFetchParameters ? state.popularRequests : [],
      };
    }

    case GET_POPULAR_REQUESTS_SUCCESS:
      return {
        ...state,
        popularRequests: action.payload,
      };

    case GET_PRO_CATEGORIES_FOR_SIGN_UP_SUCCESS: {
      return { ...state, proJobCategoriesForSignUp: action.payload };
    }
    case SET_VERTICAL_PAGE_TYPE: {
      return { ...state, verticalPageType: action.payload.verticalPageType };
    }

    default:
      return state;
  }
};
export default reducer;
