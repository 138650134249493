import reducer from './reducers';

import logic from './logic';

export {
  closeSocket,
  registerSocketEvent,
  registerSocketEventSuccess,
  setupSocket,
} from './actions';

export { eventsSelector, socketInstanceSelector } from './selectors';

export default reducer;

export { logic };
