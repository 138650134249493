import type { Context } from '@z-components/utils/oauth';
import { removeUndefinedForNextJsSerializing } from 'utils/data-transform/next-undefined';

import {
  CREATE_REQUEST,
  GET_PRO_COUNT,
  GET_DUPLICATE_REQUEST,
  CLEAR_DUPLICATE_REQUEST,
  SET_NEW_REQUEST_MODAL_CONTEXT,
  SET_NEW_REQUEST_MODAL_PROPERTIES,
  SET_NEW_REQUEST_MODAL_PERF_TIMINGS,
  State,
} from './types';

export function createRequest({
  captchaResponse,
  clientId,
  isDirectBooking,
  jobTypeFieldsVersion,
  jobTypeId,
  language,
  // when origin exists jobTypeId reflects an alias of the originJobTypeId
  originJobTypeId,
  requestData,
  context,
  mode,
}: {
  captchaResponse?: string;
  clientId?: string;
  context?: Context;
  isDirectBooking: boolean;
  jobTypeFieldsVersion?: number;
  jobTypeId: string;
  language: string;
  mode?: string;
  originJobTypeId?: string;
  requestData: unknown;
}) {
  return {
    payload: {
      captchaResponse,
      clientId,
      context,
      isDirectBooking,
      jobTypeFieldsVersion,
      jobTypeId,
      language,
      mode,
      originJobTypeId,
      requestData,
    },
    type: CREATE_REQUEST,
  };
}

export function getDuplicateRequest(jobTypeId: string) {
  return { payload: { jobTypeId }, type: GET_DUPLICATE_REQUEST };
}

export function clearDuplicateRequest() {
  return { type: CLEAR_DUPLICATE_REQUEST };
}

export function getProCount({
  cityKey,
  jobTypeId = '',
  prefectureKey,
  zipCode,
}: {
  cityKey: string;
  jobTypeId?: string;
  prefectureKey: string;
  zipCode: string;
}) {
  return {
    payload: { cityKey, jobTypeId, prefectureKey, zipCode },
    type: GET_PRO_COUNT,
  };
}

export function setNewRequestModalProperties(properties: Partial<State['newRequestModal']>) {
  return { payload: properties, type: SET_NEW_REQUEST_MODAL_PROPERTIES };
}

export function setNewRequestModalPerfTimings(timings: {
  apiCallCompletedAt?: number;
  launchedAt: number;
  testVariant?: string;
}) {
  return { payload: timings, type: SET_NEW_REQUEST_MODAL_PERF_TIMINGS };
}

export function setNewRequestModalContext({
  address,
  city,
  prefecture,
  cityGroupName,
}: {
  address?: unknown;
  city?: { key?: string; name?: string };
  cityGroupName?: string;
  prefecture?: { key?: string; name?: string };
}) {
  return {
    payload: removeUndefinedForNextJsSerializing({
      address,
      city,
      cityGroupName,
      prefecture,
    }),
    type: SET_NEW_REQUEST_MODAL_CONTEXT,
  };
}
