import { isNil, omitBy } from 'lodash';
import { StatusCodes } from 'http-status-codes';

import httpClient, { getLanguageHeader } from 'src/http-client';
import { parseCity, parsePrefecture, parseTownArea } from 'utils/data-transform/location';
import { AnnouncementSummary, UserNotification } from './types';
import { City, Location, Prefecture, TownArea } from '../locations/types';
import { EmbedPro } from 'components/molecules/ProProfileCard';

import { AvailablePrefecture } from '@z-components/molecules/types';

type Profile = {
  avatar?: string;
  companyNameEnglish?: string;
  companyNameKanji?: string;
  fullNameEnglish?: { first: string; last: string };
  fullNameKanji?: { first: string; last: string };
  isCompany: boolean;
  languages: { en: boolean; ja: boolean };
};

type ProBlogPost = {
  _id: string;
  blogPostUrl: string;
  content?: string;
  createdAt: Date;
  jobTypeId: string;
  postDate?: Date;
  proId: string;
  rssFeedUrl?: string;
  status: 'success';
  title?: string;
  updatedAt: Date;
};

export async function changePassword(id: string, token: string, password: string) {
  const response = await httpClient.put<void>(
    `/users/${id}/change-password`,
    { password },
    { headers: { 'x-access-token': token } }
  );
  return response.data;
}

export async function auth(id: string, token: string) {
  const response = await httpClient.get(`/users/${id}`, {
    headers: { 'x-access-token': token },
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data } = response;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (data.proProfile) {
    const res = await httpClient.get(`/private-profiles/${id}`, {
      headers: { 'x-access-token': token },
    });
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currentUser: {
        ...data,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        privateProfile: res.data,
      },
    };
  }
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    currentUser: data,
  };
}

function getDisplayName(
  language: string,
  {
    isCompany,
    languages,
    companyNameEnglish,
    companyNameKanji,
    fullNameEnglish,
    fullNameKanji,
  }: Profile,
  privateMode = false
): string {
  try {
    if (isCompany) {
      if (language === 'en') {
        if (languages.en && companyNameEnglish) {
          return companyNameEnglish;
        }
        return companyNameKanji ?? '';
      }

      if (languages.ja && companyNameKanji) {
        return companyNameKanji;
      }
      return companyNameEnglish ?? '';
    }

    if (privateMode) {
      if (language === 'en') {
        if (languages.en && fullNameEnglish) {
          return `${fullNameEnglish.first} ${fullNameEnglish.last.substr(0, 1)}.`;
        }

        return `${fullNameKanji?.last ?? ''}さん`;
      }
      if (language === 'ja') {
        if (languages.ja && fullNameKanji) {
          return `${fullNameKanji.last}さん`;
        }

        return `${fullNameEnglish?.first ?? ''} ${(fullNameEnglish?.last ?? '').substr(0, 1)}.`;
      }
    }

    if (language === 'en') {
      if (languages.en && fullNameEnglish) {
        return `${fullNameEnglish.first} ${fullNameEnglish.last}`;
      }

      return `${fullNameKanji?.last ?? ''} ${fullNameKanji?.first ?? ''}`;
    }
    if (language === 'ja') {
      if (languages.ja && fullNameKanji) {
        return `${fullNameKanji.last} ${fullNameKanji.first}`;
      }

      return `${fullNameEnglish?.first ?? ''} ${fullNameEnglish?.last ?? ''}`;
    }
  } catch (_) {
    return '';
  }

  return '';
}

export async function claimItpProfile(proId: string, email: string) {
  try {
    const res = await httpClient.post<void>(`/itp-users/${proId}/claim-profile`, { email });
    return res.data;
  } catch (err) {
    return console.error(err); // eslint-disable-line no-console
  }
}

export async function listNotifications(userId: string) {
  try {
    const notificationsResponse = await httpClient.get<Array<UserNotification>>(
      '/user-notifications',
      {
        params: { userId },
      }
    );

    const notifications = notificationsResponse.data.map((notif: UserNotification) => ({
      ...notif,
      id: notif.id || notif._id,
      time: notif.time || notif.createdTime,
    }));

    return Promise.all(
      notifications.map(async (notification: UserNotification) => {
        if (notification.actor) {
          try {
            const avatarResponse = await httpClient.get<{ url: string }>(
              `/users/${notification.actor}/avatar`
            );
            return {
              ...notification,
              avatarUrl: avatarResponse.data.url,
            };
          } catch (error) {
            console.error(error); // eslint-disable-line no-console
          }
        }
        return notification;
      })
    );
  } catch (_) {
    return [];
  }
}

export async function markASingleNotification(notificationId: string) {
  const response = await httpClient.put<UserNotification>(
    `/user-notifications/${notificationId}/mark-as-read`
  );
  return response.data;
}

export async function markAllNotifications(timestamp: number) {
  const response = await httpClient.put<{ timestamp: number }>('/user-notifications/mark-as-read', {
    timestamp,
  });
  return response.data;
}

export async function listAnnouncements(userId: string) {
  const res = await httpClient.get<Array<AnnouncementSummary>>(
    `users/${userId}/announcements/read`
  );
  if (res.status !== StatusCodes.OK) return [];

  return res.data;
}

export async function markASingleAnnouncement(userId: string, announcementId: string) {
  const res = await httpClient.post(
    `users/${userId}/announcements/${announcementId}/mark-as-read/`
  );

  if (res.status !== StatusCodes.OK) return '';

  return announcementId;
}

export async function markAllAnnouncements(userId: string) {
  const res = await httpClient.post(`users/${userId}/announcements/mark-as-read`);

  if (res.status !== StatusCodes.OK) return '';

  return 'OK';
}

async function loadJobProfiles(
  { _id, id }: { _id: string; id: string },
  language: string,
  format = ''
) {
  const options = {
    headers: getLanguageHeader(language),
  };

  const userId = id || _id;

  try {
    const url = `/job-type-profile/get-by-user-id/${userId}?format=${format}`;
    const res = await httpClient.get(url, options);
    if (Array.isArray(res.data)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return res.data.map((item) => item);
    }
  } catch (_) {
    return [];
  }
  return [];
}

async function loadEnabledJobTypes(
  { id: userId, isItp }: { id: string; isItp: boolean },
  shortId: string,
  language: string
) {
  const options = {
    headers: getLanguageHeader(language),
  };
  try {
    const url = `/${isItp ? `itp-users/${shortId}` : `pros/${userId}`}/job-types`;
    const res = await httpClient.get(url, options);
    if (Array.isArray(res.data)) {
      return res.data.map(
        ({
          category,
          categories,
          clientRequest,
          id,
          isCityRequestable,
          isPrefectureRequestable,
          title,
        }) => ({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          categories,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          category,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          clientRequest,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          id,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          isCityRequestable,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          isPrefectureRequestable,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          title,
        })
      );
    }
  } catch (_) {
    return [];
  }
  return [];
}

async function loadAvailablePrefectures(
  { id, isItp }: { id: string; isItp: boolean },
  language: string
) {
  const options = {
    headers: getLanguageHeader(language),
  };
  if (isItp) return [];
  try {
    const url = `/pros/${id}/available-prefectures`;
    const res = await httpClient.get<Array<AvailablePrefecture>>(url, options);
    return res.data;
  } catch (_) {
    return [];
  }
}

async function loadLocation(
  {
    proProfile,
  }: { proProfile: { location: { city: string; townArea: string | undefined; zipCode: string } } },
  language: 'en' | 'ja'
) {
  const options = {
    headers: getLanguageHeader(language),
  };
  try {
    let cityId = proProfile.location.city;
    let townAreaId = proProfile.location.townArea;
    const { zipCode } = proProfile.location;

    if ((!cityId || !townAreaId) && zipCode) {
      const res = await httpClient.get<Location>('/locations', {
        ...options,
        params: {
          zipCode,
        },
      });
      if (!cityId) {
        cityId = res.data.city;
      }
      if (!townAreaId) {
        townAreaId = res.data.townArea;
      }
    }

    const cityRes = await httpClient.get<City>(`/location-cities/${cityId}`, options);
    const city = parseCity(cityRes.data, language);

    const prefectureRes = await httpClient.get<Prefecture>(
      `/location-prefectures/${city.prefecture}`,
      options
    );
    const prefecture = parsePrefecture(prefectureRes.data, language);

    let townArea: { id: string; name: string } | null = null;
    if (townAreaId) {
      const townAreaRes = await httpClient.get<TownArea>(
        `/location-town-areas/${townAreaId}`,
        options
      );
      townArea = parseTownArea(townAreaRes.data, language);
    }

    return {
      city: { key: city.key, name: city.name },
      prefecture: { key: prefecture.key, name: prefecture.name },
      townArea: { id: townArea?.id ?? null, name: townArea?.name ?? null },
      zipCode,
    };
  } catch (_) {
    return {};
  }
}

async function loadPrivateProfile({ id, isItp }: { id: string; isItp: boolean }, language: string) {
  const options = {
    headers: getLanguageHeader(language),
  };
  if (!isItp) {
    try {
      const res = await httpClient.get(`/private-profiles/${id}`, options);
      if (res.data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.data;
      }
    } catch (_) {
      return {};
    }
  }
  return {};
}

async function loadReviews({ id, isItp }: { id: string; isItp: boolean }, language: string) {
  const options = {
    headers: getLanguageHeader(language),
  };
  const counts = [0, 0, 0, 0, 0];

  if (!isItp) {
    try {
      const reviewsRes = await httpClient.get<Array<{ rating: number }>>('/pro-feedbacks', {
        ...options,
        params: {
          userId: id,
        },
      });

      const list = reviewsRes.data;
      const total = list.length;

      list.forEach(({ rating }: { rating: number }) => {
        if (rating > 0) {
          counts[rating - 1] += 1;
        }
      });
      return {
        counts,
        list,
        total,
      };
    } catch (_) {
      return {
        counts,
        list: [],
        total: 0,
      };
    }
  }
  return {
    counts,
    list: [],
    total: 0,
  };
}

async function loadBlogPosts({
  _id,
  proProfile,
  id,
  profile,
  slug,
}: {
  _id: string;
  id: string;
  proProfile: Record<string, unknown>;
  profile: Record<string, Profile>;
  slug: string;
}) {
  const proId = id || _id;

  try {
    const res = await httpClient.get<Array<ProBlogPost> | undefined>(
      `/pro-blog-posts/pro/${proId}`
    );

    let blogPosts: Array<ProBlogPost> = [];

    if (res.data) {
      blogPosts = res.data.map((blog: ProBlogPost) => ({
        ...blog,
        pro: {
          customProfileUrl: proProfile.customProfileUrl || null,
          id,
          profile,
          slug,
        },
      }));
    }

    return blogPosts;
  } catch (_) {
    return [];
  }
}

export async function loadPro(id: string, language: 'en' | 'ja' = 'ja', format = '') {
  let userId;
  const proId = encodeURIComponent(id);
  const options = {
    headers: getLanguageHeader(language),
  };
  // 1. try to get by slug
  try {
    const res = await httpClient.get<{ id: string } | undefined>(
      `/pros/get-by-slug/${proId}?caseStatus=published&isServicePage=true`,
      options
    );
    if (res.data?.id) {
      userId = res.data.id;
    } else {
      userId = proId;
    }
  } catch (_) {
    userId = proId;
  }

  // 2. load by userId (from slug or proId)
  let user;
  try {
    const res = await httpClient.get(
      `/pros/${userId}?caseStatus=published&isServicePage=true`,
      options
    );
    if (res.data) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      user = res.data;
    }
  } catch (_) {
    // move on to itp
  }

  // 3. if all fail then check from itp, return null if not found
  if (!user) {
    try {
      const res = await httpClient.get(`/itp-users/${proId}`, options);
      if (res.data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        user = {
          ...res.data,
          isItp: true,
        };
      } else {
        return null;
      }
    } catch (_) {
      return null;
    }
  }

  const [
    jobProfiles,
    enabledJobTypes,
    availablePrefectures,
    location,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    privateProfile,
    reviews,
    blogPosts,
  ] = await Promise.all([
    // load split
    loadJobProfiles(user, language, format),
    loadEnabledJobTypes(user, proId, language),
    loadAvailablePrefectures(user, language),
    loadLocation(user, language),
    loadPrivateProfile(user, language),
    loadReviews(user, language),
    loadBlogPosts(user),
  ]);

  // assign split
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  user.jobProfiles = jobProfiles;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  user.enabledJobTypes = enabledJobTypes;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  user.location = location;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  user.privateProfile = privateProfile;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  user.reviews = reviews;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  user.availablePrefectures = availablePrefectures;

  // set user name, location name
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const cityName = (user.location?.city?.name ?? '') as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const prefectureName = (user.location?.prefecture?.name ?? '') as string;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  user.name = getDisplayName(language, user.profile);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  user.location.name =
    language === 'en'
      ? `${cityName ? `${cityName}, ` : ''}${prefectureName}`
      : `${prefectureName}${cityName}`;

  // TODO remove this, `mainJobType` should be always available
  if (enabledJobTypes.length) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    user.proProfile.mainJobType = enabledJobTypes[0].id;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  user.blogPosts = blogPosts;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return user;
}

export async function loadProSimpleProfile(
  id: string,
  language: 'en' | 'ja' = 'ja'
): Promise<EmbedPro | null> {
  let userId;
  const proId = encodeURIComponent(id);
  const options = {
    headers: getLanguageHeader(language),
  };
  // 1. try to get by slug
  try {
    const res = await httpClient.get<{ id: string } | undefined>(
      `/pros/get-by-slug/${proId}`,
      options
    );
    if (res.data?.id) {
      userId = res.data.id;
    } else {
      userId = proId;
    }
  } catch (_) {
    userId = proId;
  }

  // 2. load by userId (from slug or proId)
  let user: (EmbedPro & { isItp: boolean; profile: Profile }) | null = null;
  try {
    const res = await httpClient.get(`/pros/${userId}`, options);
    if (res.data) {
      user = res.data as EmbedPro & { isItp: boolean; profile: Profile };
    }
  } catch (_) {
    // move on to itp
  }

  // 3. if all fail then check from itp, return null if not found
  if (!user) {
    try {
      const res = await httpClient.get(`/itp-users/${proId}`, options);
      if (res.data) {
        user = {
          ...res.data,
          isItp: true,
        } as EmbedPro & { isItp: boolean; profile: Profile };
      } else {
        return null;
      }
    } catch (_) {
      return null;
    }
  }

  const availablePrefectures = await loadAvailablePrefectures(user, language);

  const embedPro: EmbedPro = {
    id: user.id,
    name: getDisplayName(language, user.profile),
    availablePrefectures,
    avatar: user.profile.avatar ?? '',
    currentTier: user.currentTier,
    proProfile: user.proProfile ?? {},
    shortId: user.shortId,
    slug: user.slug,
  };

  // omit undefined | null values from returned EmbedPro to avoid nextjs build error
  return omitBy(embedPro, isNil) as EmbedPro;
}

export async function getProPricingMenus({
  userId,
  language = 'ja',
}: {
  language?: string;
  userId: string;
}) {
  const options = {
    headers: getLanguageHeader(language),
  };
  try {
    const res = await httpClient.get(`/users/${userId}/pricing-menu`, options);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return res.data || [];
  } catch (_) {
    return [];
  }
}

export async function loadProBlogPostByPostId(postId: string) {
  try {
    const res = await httpClient.get<ProBlogPost>(`/pro-blog-posts/${postId}`);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return res.data;
  } catch (_) {
    return null;
  }
}
