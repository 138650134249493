import ReactGA from 'react-ga';

import { getItem } from './localStorage';

const initializeGA = () => {
  // @ts-expect-error window object extended
  if (!window.GA_INITIALIZED) {
    const userId = getUserIdFromLocalStorage();
    console.log('GA init'); // eslint-disable-line
    ReactGA.initialize(`${process.env.GOOGLE_ANALYTICS_ID as string}`, {
      gaOptions: userId ? { userId } : {},
    });
    // @ts-expect-error window object extended
    window.GA_INITIALIZED = true;
  }
};

export const logPageView = (dimensions: ReactGA.FieldsObject) => {
  initializeGA();

  const page = `${window.location.pathname}${window.location.search}`;
  console.log(`UA - Logging pageview for ${page} with dimensions`, dimensions); // eslint-disable-line no-console
  ReactGA.set(dimensions);
  ReactGA.pageview(page);
};

export const logEvent = (category = '', action = '', label = '') => {
  if (category && action) {
    initializeGA();

    // eslint-disable-next-line no-console
    console.log(
      `UA - Logging event for category ${category} and action ${action}${
        label ? ` with label ${label}` : ''
      }`
    );

    const args: ReactGA.EventArgs = { action, category, label };

    // https://zehitomo.atlassian.net/browse/PROD-3852
    // Experiment with using the beacon transport when logging an event that happens immediately before a page navigation
    if (
      typeof navigator !== 'undefined' &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      navigator.sendBeacon &&
      ['newRequestSuccess'].includes(action)
    ) {
      args.transport = 'beacon';
    }

    ReactGA.event(args);
  }
};

export const logException = (description = '', fatal = false) => {
  if (description) {
    initializeGA();

    ReactGA.exception({ description, fatal });
  }
};

export const setFields = (fields: ReactGA.FieldsObject = {}) => {
  initializeGA();

  ReactGA.set(fields);
};

export const getUserIdFromLocalStorage = () => {
  try {
    const storageAuthData = getItem('ngStorage-authData');
    if (storageAuthData) {
      const authData = JSON.parse(storageAuthData) as { uid?: string };
      return authData.uid ?? null;
    }
  } catch (error) {
    // Ignore error, handle as unauthenticated
  }
  return null;
};

export const isUserAuthenticated = () => getUserIdFromLocalStorage() !== null;

export enum DIMENSION_INDEX {
  Tag = 1,
  ABTasty,
  jobTypeId,
  prefectureKey,
}

/**
 * Always set a value for Google Analytics custom dimensions so that all metrics are included in the reports
 * @see {@link https://stackoverflow.com/a/10507555}
 */
export const DEFAULT_GA_CUSTOM_DIMENSION_VALUE = '(no value)';
