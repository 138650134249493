import { TFunction } from 'react-i18next';
import { LocalBusiness, WithContext } from 'schema-dts';
import memoize from 'memoize-one';

import { C1_CATEGORIES } from 'utils/marketing-pages';
import { LinkGroup } from 'modules/common/components/InternalLinkGroups';
import { VerticalPageJobType } from 'modules/job-types/types';
import {
  PillType,
  ScrollerItemData,
  DropdownItemTypes,
} from 'components/molecules/PillFilters/PillFilters.types';
import { isDefined } from 'utils/type-guards';

import type { FilterState } from './duck/types';
import type { ListProsParameters, ProLocalBusiness } from './marketing-page.types';
import { UtmParameter } from './type';
import { utmParamPairsForSimplifiedLayout, utmDisplayJobTypeSet } from 'src/utils/constant';
import { MajorCityService } from 'src/services/location-major-city';
import CityGroupingService from 'src/services/city-grouping';
import { VerticalPageTypeValues } from 'constants/vertical-lp';

const REVIEW_MAX_VALUE = 5;
const REVIEW_MIN_VALUE = 1;

export const formatLocationTitle = (
  language: 'en' | 'ja',
  prefecture: { name?: string },
  city: { name?: string }
): string => {
  const prefectureName = prefecture.name ?? '';
  const cityName = city.name ?? '';
  return language === 'en' ? `${cityName}, ${prefectureName}` : `${prefectureName}${cityName}`;
};

export const appendUpdatedAtString = ({
  language = 'ja',
  title,
}: {
  language?: string;
  title: string;
}): string => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();
  const updatedAt = language === 'ja' ? `(${year}年${month}月更新)` : `(${year}/${month} Update)`;
  return `${title} ${updatedAt}`;
};

interface Location {
  key: string;
  name: string;
}
interface Breadcrumb {
  link: string;
  text: string;
}

export const generateLocationBreadcrumbs = ({
  prefix,
  jobType,
  prefecture,
  cityGroup,
  majorCity,
  city,
  pageType,
}: {
  prefix: string;
  jobType: Pick<VerticalPageJobType, 'id' | 'title' | 'categories'>;
  prefecture: Location;
  cityGroup?: Location;
  majorCity?: Location;
  city?: Location;
  pageType: VerticalPageTypeValues;
}): Array<Breadcrumb> => {
  const basePath = `${prefix}/${jobType.categories.join('/')}/${jobType.id}`;
  const crumbs: Array<Breadcrumb> = [];

  // Prefecture
  crumbs.push({
    link: `${basePath}/${prefecture.key}`,
    text:
      city || cityGroup || majorCity
        ? `${prefecture.name}の${jobType.title}`
        : `${prefecture.name}内`,
  });

  const cityGroupAndMajorCityCrumbs = getCityGroupAndMajorCityCrumbs(
    { jobType, prefecture, cityGroup, city, pageType },
    basePath
  );
  crumbs.push(...cityGroupAndMajorCityCrumbs);

  if (city) {
    crumbs.push({
      link: `${basePath}/${prefecture.key}/${city.key}`,
      text: `${city.name}内`,
    });
  }

  // add "search for pros" text to the last breadcrumb item
  const lastIndex = crumbs.length - 1;
  const lastCrumb = crumbs[lastIndex].text;
  crumbs[lastIndex].text = `${lastCrumb}の近くの${jobType.title}のプロを探す`;

  return crumbs;
};

const getCityGroupAndMajorCityCrumbs = (
  {
    jobType,
    prefecture,
    cityGroup,
    majorCity,
    city,
    pageType,
  }: {
    jobType: Pick<VerticalPageJobType, 'id' | 'title'>;
    prefecture: Location;
    cityGroup?: Location;
    city?: Location;
    majorCity?: Location;
    pageType: VerticalPageTypeValues;
  },
  basePath: string
): Array<Breadcrumb> => {
  const positions = [];
  if (cityGroup && !MajorCityService.isCityGroupMajorCity(cityGroup.key)) {
    positions.push({
      link: `${basePath}/${prefecture.key}/${cityGroup.key}`,
      text: city || majorCity ? `${cityGroup.name}内の${jobType.title}` : `${cityGroup.name}内`,
    });
  }

  const partsForMajorCity = MajorCityService.getPartsForMajorCity(
    { prefecture, cityGroup, city },
    CityGroupingService.isGroupingEnabledForPageType.bind(null, pageType)
  );
  if (Array.isArray(partsForMajorCity)) {
    const majorCityPart = partsForMajorCity[partsForMajorCity.length - 1];
    const crumbText =
      city || MajorCityService.isCityGroupInverted(cityGroup?.key)
        ? `${majorCityPart.name}内の${jobType.title}`
        : `${majorCityPart.name}内`;
    const link = `${basePath}/${partsForMajorCity.map((part) => part.key).join('/')}`;
    positions.push({ link, text: crumbText });
  }

  if (MajorCityService.isCityGroupInverted(cityGroup?.key)) {
    positions.reverse();
  }
  return positions;
};

export const generateInternalMenus = ({
  cities,
  city,
  cityGroups,
  cityGroupKey,
  cityGroupName,
  jobType,
  language,
  pageType,
  popularJobTypes,
  prefecture,
  prefectures,
  relatedJobTypes,
  t,
}: {
  cities: Array<{ id: string; key: string; name: string }>;
  city: { id?: string; _id: string; key: string; name: string };
  cityGroups: Array<{ key: string; name: string }>;
  cityGroupKey: string;
  cityGroupName: string;
  jobType: { categories: Array<string>; id: string };
  language: string;
  pageType: string;
  popularJobTypes: Array<{
    categories: Array<string>;
    id: string;
    translations?: { seoName?: string; requestType?: string };
  }>;
  prefecture: { key: string; name: string };
  prefectures: Array<{ id: string; key: string; name: string }>;
  relatedJobTypes: Array<{
    categories?: Array<string>;
    id: string;
    translations?: { requestType?: string; seoName?: string };
  }>;
  t: TFunction;
}): Array<LinkGroup> => {
  const prefix = language === 'en' ? '/en' : '';
  const basePath = `${prefix}/${jobType.categories.join('/')}/${jobType.id}`;
  let groups: Array<LinkGroup> = [];

  const prefecturesMenu = {
    links: (pageType === 'prefecture'
      ? prefectures.filter((nearbyPref) => nearbyPref.key !== prefecture.key)
      : prefectures
    ).map(({ key, name }) => ({
      route: `${basePath}/${key}`,
      text: name,
    })),
    title: t('internalLinks.availablePrefectures'),
  };

  const citiesMenu = {
    links: cities
      .filter(({ id }) => id !== city._id && id !== city.id)
      .map(({ key, name }) => ({
        route: `${basePath}/${prefecture.key}/${key}`,
        text: name,
      })),
    title: t('internalLinks.availableCities'),
  };

  const cityGroupCitiesMenu = {
    links: generateCityGroupAndMajorCityMenus({
      locations: cities.filter(({ id }) => id !== city._id && id !== city.id),
      pageType: 'cityGroupCity',
      basePath,
      prefectureKey: prefecture.key,
      language,
      cityGroupKey,
      jobType,
    }),
    title: t('internalLinks.availableCities'),
  };

  const majorCityCitesMenu = {
    links: generateCityGroupAndMajorCityMenus({
      locations: cities.filter(({ id }) => id !== city._id && id !== city.id),
      pageType: 'majorCityCity',
      basePath,
      prefectureKey: prefecture.key,
      language,
      jobType,
    }),
    title: t('internalLinks.availableCities'),
  };

  const majorCitiesMenu = {
    links: generateCityGroupAndMajorCityMenus({
      locations: cities.filter(({ id }) => id !== city._id && id !== city.id),
      pageType: 'majorCity',
      basePath,
      prefectureKey: prefecture.key,
      language,
      jobType,
    }),
    title: t('internalLinks.availableCities'),
  };

  const cityGroupsMenu =
    cityGroups.length > 0
      ? {
          links: generateCityGroupAndMajorCityMenus({
            locations: cityGroups,
            pageType: 'cityGroup',
            basePath,
            prefectureKey: prefecture.key,
            language,
            jobType,
          }),
          title: t('internalLinks.availableCityGroups'),
        }
      : majorCitiesMenu;

  const relatedJobTypesMenu = {
    links: relatedJobTypes.map(({ categories, translations, id }) => ({
      // TODO: needs investigation about cases that relatedJobType without categories
      route: categories ? `${prefix}/${categories.join('/')}/${id}` : `${prefix}/${id}`,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      text: translations?.seoName || translations?.requestType || '',
    })),
    title: t('internalLinks.related'),
  };

  const popularJobTypesMenu = {
    links: popularJobTypes
      .filter(({ id }) => id !== jobType.id)
      .map(({ categories, id, translations }) => ({
        route: `${prefix}/${categories.join('/')}/${id}/${prefecture.key}`,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        text: translations?.seoName || translations?.requestType || '',
      })),
    title: t('internalLinks.samePrefecture', { prefecture: prefecture.name }),
  };

  const popularJobTypesGroupMenu = {
    links: popularJobTypes
      .filter(({ id }) => id !== jobType.id)
      .map(({ categories, id, translations }) => ({
        route: `${prefix}/${categories.join('/')}/${id}/${prefecture.key}/${cityGroupKey}`,
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        text: translations?.seoName || translations?.requestType || '',
      })),
    title: t('internalLinks.samePrefecture', { prefecture: `${prefecture.name}${cityGroupName}` }),
  };

  switch (pageType) {
    case 'jobType':
      groups = [
        prefecturesMenu,
        relatedJobTypesMenu,
        {
          links: C1_CATEGORIES.map((categoryId) => ({
            route: `${prefix}/${categoryId}`,
            text: t(`categories.${categoryId}`),
          })),
          title: t('footer.browseByCategory'),
        },
      ];
      break;
    case 'prefecture':
      groups = [cityGroupsMenu, popularJobTypesMenu, relatedJobTypesMenu, prefecturesMenu];
      break;
    case 'majorCity':
      groups = [majorCityCitesMenu, popularJobTypesGroupMenu, relatedJobTypesMenu];
      break;
    case 'group':
      groups = [cityGroupCitiesMenu, popularJobTypesGroupMenu, relatedJobTypesMenu];
      break;
    case 'city':
    case 'groupCity':
      groups = [
        {
          links: popularJobTypes
            .filter((popularJobType) => popularJobType.id !== jobType.id)
            .map(({ categories, id, translations }) => ({
              route: `${prefix}/${categories.join('/')}/${id}/${prefecture.key}/${city.key}`,
              // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
              text: translations?.seoName || translations?.requestType || '',
            })),
          title: t('internalLinks.sameCity', { city: city.name }),
        },
        citiesMenu,
        prefecturesMenu,
      ];
      break;
    default:
      groups = [];
  }
  return groups;
};

const generateCityGroupAndMajorCityMenus = ({
  locations,
  pageType,
  basePath,
  prefectureKey,
  language,
  cityGroupKey,
  jobType,
}: {
  locations: Array<{ key: string; name: string }>;
  pageType: 'majorCity' | 'cityGroup' | 'cityGroupCity' | 'majorCityCity';
  basePath: string;
  prefectureKey: string;
  language: string;
  cityGroupKey?: string;
  jobType: { categories: Array<string>; id: string };
}) => {
  const uniqueSet = new Set<string>();
  const majorCity = MajorCityService.getMajorCityFromPrefectureKey(prefectureKey);
  if (
    pageType === 'cityGroup' &&
    majorCity &&
    !MajorCityService.doesMajorCityOverlapWithCityGroup(majorCity.key, jobType.id)
  ) {
    locations.push({ key: majorCity.key, name: majorCity.name[language as 'en' | 'ja'] ?? '' });
  }

  return locations.reduce<Array<{ route: string; text: string }>>((acc, location) => {
    const { key, name } = location;
    let text = name;
    let route = `${basePath}/${prefectureKey}/${key}`;

    switch (pageType) {
      case 'majorCity':
      case 'cityGroupCity':
        {
          if (MajorCityService.isCityGroupInverted(cityGroupKey)) {
            acc.push({ route, text });
            return acc;
          }
          const majorCityFromCityKey = MajorCityService.getMajorCityFromCityKey(key);
          if (majorCityFromCityKey) {
            const isChildOfMajorCity = MajorCityService.isChildOfMajorCity(key);
            const doesMajorCityOverlap = MajorCityService.doesMajorCityOverlapWithCityGroup(
              majorCityFromCityKey.key,
              jobType.id
            );
            if (isChildOfMajorCity && !doesMajorCityOverlap) {
              text = majorCityFromCityKey.name[language as 'en' | 'ja'] ?? '';
              route = `${basePath}/${prefectureKey}/${majorCityFromCityKey.key}`;
            }
          }
        }
        break;

      case 'cityGroup':
        if (MajorCityService.isCityGroupMajorCity(key)) {
          const majorCity = MajorCityService.getMajorCityOrMajorCityParentFromCityGroupKey(key);
          text = majorCity?.name[language as 'en' | 'ja'] ?? name;
          route = `${basePath}/${prefectureKey}/${majorCity?.key ?? key}`;
        }
        break;

      case 'majorCityCity': {
        const majorCity = MajorCityService.getMajorCityFromCityKey(key);
        if (majorCity?.cityKeys.includes(key)) {
          acc.push({ route, text });
          return acc;
        }
        return acc;
      }

      default:
        break;
    }

    if (!uniqueSet.has(text)) {
      uniqueSet.add(text);
      acc.push({ route, text });
    }

    return acc;
  }, []);
};

export const doesBannerOverlapFooter = ({
  maxDisplayDocumentY,
}: {
  maxDisplayDocumentY?: number | null;
} = {}) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!document || !window) return false;
  let maxDocumentHeight = 0;
  const EXTRA_HEIGHT_MULTIPLIER = 1.05;

  if (!maxDisplayDocumentY) {
    maxDocumentHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  }
  return (
    window.scrollY >
    (maxDisplayDocumentY ?? maxDocumentHeight) - window.innerHeight * EXTRA_HEIGHT_MULTIPLIER
  );
};

export const DEFAULT_JUMBOTRON_HEIGHT = 350;

export const PHONE_NUMBER_OPTIONAL_VERTICAL = [
  'ballet-instructor',
  'ballroomdance-instructor',
  'bellydance-instructor',
  'catering',
  'cheerdance-instructor',
  'flower-arrangement-instructor',
  'garage-carport-professional',
  'gardener',
  'guitar-teacher',
  'handyman',
  'hip-hop-dance-instructor',
  'hula-instructor',
  'japanese-calligraphy-instructor',
  'japanesedance-instructor',
  'karaoke-singing-instructor',
  'korean-teacher',
  'painting-sketching-instructor',
  'personal-trainer',
  'photographer',
  'photography-instructor',
  'piano-teacher',
  'pilates-instructor',
  'plumbing-construction',
  'poledance-instructor',
  'running-instructor',
  'sign-teacher',
  'stretching-instructor',
  'swimming-lessons',
  'tapdance-instructor',
  'tapdance-instructor',
  'ukulele-instructor',
  'violin-instructor',
  'vocal-instructor',
  'yoga-instructor',
  'zumba-instructor',
];

export const getStructuredLocalBusinessForPro = memoize(
  ({ name, image, description, address, reviewStatistics }: ProLocalBusiness) => {
    const localBusiness: WithContext<LocalBusiness> = {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      name,
      description,
      image:
        image ??
        'https://res.cloudinary.com/zehitomo/image/upload/v1652747398/assets/openGraphLogo.png',
      address: {
        '@type': 'PostalAddress',
        postalCode: address.zipCode,
        addressRegion: address.prefecture,
        addressLocality: `${address.city}${address.townArea ?? ''}`,
        addressCountry: 'JP',
      },
    };

    // aggregateRating should only be included if we have reviews
    if (reviewStatistics && reviewStatistics.reviewCount > 0) {
      localBusiness.aggregateRating = {
        '@type': 'AggregateRating',
        bestRating: REVIEW_MAX_VALUE,
        worstRating: REVIEW_MIN_VALUE,
        ...reviewStatistics,
      };
    }

    return JSON.stringify(localBusiness);
  }
);

export const getStructuredLocalBusinessWithAggregateReviewData = memoize(
  (
    jobTypeName: string,
    proReviewStatistics: { ratingValue: number; reviewCount: number } | null
  ): string => {
    if (!jobTypeName) {
      return JSON.stringify({});
    }

    let reviewData;

    if (
      proReviewStatistics &&
      proReviewStatistics.ratingValue >= 1 &&
      proReviewStatistics.reviewCount > 0
    ) {
      reviewData = proReviewStatistics;
    } else {
      return JSON.stringify({});
    }

    const localBusinessWithAggregateRating: WithContext<LocalBusiness> = {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      address: { '@type': 'PostalAddress', addressCountry: 'JP' },
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: REVIEW_MAX_VALUE,
        worstRating: REVIEW_MIN_VALUE,
        ...reviewData,
      },
      image:
        'https://res.cloudinary.com/zehitomo/image/upload/v1652747398/assets/openGraphLogo.png',
      name: jobTypeName,
    };

    return JSON.stringify(localBusinessWithAggregateRating);
  }
);

export const MATCHING_CRITERIA_QUERY_PARAM_KEY = 'matchingCriteria';
export const PAGE_QUERY_PARAM_KEY = 'page';
export const PRICE_RANGE_QUERY_PARAM_KEY = 'priceRange';

export const getQueryParamFromUrlSearchParamsForKey = (query: URLSearchParams, key: string) => {
  const queryParam = query.get(key);
  return queryParam ? queryParam.toString() : '';
};

export const setupPricingFilters = (
  jobType: VerticalPageJobType,
  priceRange: string,
  onPriceRangeFilterValueChanged: (data: string) => void,
  t: TFunction
) => {
  const pricingFilterDropDowns: Array<ScrollerItemData> = [];

  const displayablePricingFilterRange = jobType.pricingFilterRange?.map((pricingRange) => {
    const prices = pricingRange.split('-');

    const firstNumber = Number(prices[0]);
    const secondNumber = Number(prices[1]);
    const showRangeDash = firstNumber !== 0 && secondNumber !== 0;

    const firstNumberLocalized =
      firstNumber === 0
        ? ''
        : firstNumber.toLocaleString('ja-JP', {
            currency: 'JPY',
            style: 'currency',
          });
    const secondNumberLocalized =
      secondNumber === 0
        ? ''
        : secondNumber.toLocaleString('ja-JP', {
            currency: 'JPY',
            style: 'currency',
          });

    const label = `${firstNumberLocalized} ${showRangeDash ? '-' : '~'} ${secondNumberLocalized}`;

    return {
      id: pricingRange,
      key: 'priceRange',
      value: pricingRange,
      isItemSelected: priceRange === pricingRange,
      label,
      onListValueSelected: onPriceRangeFilterValueChanged,
    };
  });

  displayablePricingFilterRange?.unshift({
    id: DropdownItemTypes.Unselect,
    key: 'priceRange',
    value: DropdownItemTypes.Unselect,
    isItemSelected: false,
    label: t('marketingPages:pricingFilter.unselectFilter'),
    onListValueSelected: onPriceRangeFilterValueChanged,
  });

  // In figma this is part of the pillText, throwing it in a span so I can style it. Inlining the
  // styles because this thing is so minor and importing styles into this file is not a pattern we
  // should start
  const DropDownIcon = () => <span style={{ fontSize: '10px', marginLeft: '2px' }}>▼</span>;

  pricingFilterDropDowns.push({
    dropdownList: displayablePricingFilterRange,
    id: 'priceRange',
    key: 'dropdown',
    value: 'dropdown',
    isToggleSelected: Boolean(priceRange),
    pillText: '予算',
    pillIcon: <DropDownIcon />,
    iconPosition: 'right',
    type: PillType.Dropdown,
  });

  return pricingFilterDropDowns;
};

export const setupMatchingCriteriaFilterToggleItems = (
  jobType: VerticalPageJobType,
  matchingCriteriaQueryString: string
): Array<ScrollerItemData> => {
  const matchingCriteria = matchingCriteriaQueryString
    ? matchingCriteriaQueryString.split(',')
    : [];
  const matchingCriteriaToggles: Array<ScrollerItemData> = [];
  if (!jobType.filterSettings?.matchingCriteria || !jobType.matchingCriteriaFilters) {
    return [];
  }

  const matchingCriteriaFilterKeys = Object.keys(jobType.matchingCriteriaFilters);

  for (const fieldKey of matchingCriteriaFilterKeys) {
    const matchingCriteriaFilter = jobType.matchingCriteriaFilters[fieldKey];
    for (const matchingCriteriaFilterOption of matchingCriteriaFilter.options) {
      const matchingCriteriaKeyAndValue = `${fieldKey}.${matchingCriteriaFilterOption.value}`;
      matchingCriteriaToggles.push({
        key: fieldKey,
        value: matchingCriteriaFilterOption.value,
        id: matchingCriteriaFilterOption.label,
        isToggleSelected: matchingCriteria.includes(matchingCriteriaKeyAndValue),
        link: true,
        matchingCriteriaKey: fieldKey,
        matchingCriteriaOptionValue: matchingCriteriaFilterOption.value,
        onToggleValueChanged: undefined,
        pillText: matchingCriteriaFilterOption.label,
        type: PillType.Toggle,
      });
    }
  }

  return matchingCriteriaToggles;
};

const formatMatchingCriteria = (key: string, value: string) => `${key}.${value}`;
export const unrollMatchingCriteriaFiltersToString = (
  matchingCriteria: FilterState['matchingCriteria']
) =>
  Object.keys(matchingCriteria)
    .map((matchingCriteriaKey) => {
      const paramValues: Array<string> = [];
      matchingCriteria[matchingCriteriaKey]?.forEach((matchingCriteriaValue) => {
        paramValues.push(formatMatchingCriteria(matchingCriteriaKey, matchingCriteriaValue));
      });
      return paramValues;
    })
    .flat()
    .join(',');

const transformMatchingCriteriaStringToMatchingCriteriaObject = (matchingCriteria: string) => {
  const matchingCriteriaObject: FilterState['matchingCriteria'] = {};
  const matchingCriteriaArray = matchingCriteria.split(',');
  matchingCriteriaArray.forEach((matchingCriteriaString) => {
    const [key, value] = matchingCriteriaString.split('.');
    if (key && value) {
      if (!matchingCriteriaObject[key]) {
        matchingCriteriaObject[key] = [];
      }
      // @ts-expect-error checked case above>
      matchingCriteriaObject[key].push(value);
    }
  });
  return matchingCriteriaObject;
};

export const transformFilterQueryStringToFilterState = (
  queryArgs: string | Record<string, string | undefined>
): FilterState => {
  let query: URLSearchParams;
  if (typeof queryArgs === 'object') {
    const filteredByDefinedEntries = Object.entries(queryArgs).filter(
      (test): test is [string, string] => isDefined(test[1])
    );
    query = new URLSearchParams(Object.fromEntries(filteredByDefinedEntries));
  } else {
    query = new URLSearchParams(queryArgs);
  }

  const matchingCriteriaQueryString = query.get(MATCHING_CRITERIA_QUERY_PARAM_KEY) ?? '';
  const matchingCriteriaByKey = transformMatchingCriteriaStringToMatchingCriteriaObject(
    matchingCriteriaQueryString
  );
  const priceRange = query.get(PRICE_RANGE_QUERY_PARAM_KEY);
  const day = query.get('day');
  const time = query.get('time');

  return {
    ...(Object.keys(matchingCriteriaByKey).length > 0
      ? { matchingCriteria: matchingCriteriaByKey }
      : { matchingCriteria: {} }),
    ...(priceRange ? { priceRange: [priceRange] } : {}),
    ...(time ? { time } : {}),
    ...(day ? { day } : {}),
  };
};

export const transformFilterStateToListProParameters = (
  filters: FilterState
): Pick<ListProsParameters, 'matchingCriteria' | 'day' | 'time' | 'priceRange'> => {
  const { priceRange, day, time, matchingCriteria } = filters;
  const filterParams: Pick<ListProsParameters, 'matchingCriteria' | 'day' | 'time' | 'priceRange'> =
    {
      day: undefined,
      matchingCriteria: undefined,
      priceRange: undefined,
      time: undefined,
    };
  if (Object.keys(matchingCriteria).length > 0) {
    filterParams.matchingCriteria = unrollMatchingCriteriaFiltersToString(matchingCriteria);
  }
  if (day) {
    filterParams.day = day;
  }
  if (time) {
    filterParams.time = time;
  }
  if (priceRange) {
    [filterParams.priceRange] = priceRange;
  }
  return filterParams;
};

interface TitleAndDescription {
  description: string;
  title: string;
  ogTitle?: string;
}

export const getLandingPageTitleAndDescriptionWithPageInformation = (
  title: string,
  description: string,
  language: string,
  listProPageNumber: number,
  caseStudyPageNumber: number
): TitleAndDescription => {
  const output: TitleAndDescription = {
    title: appendUpdatedAtString({ title, language }),
    description,
  };
  const additionalSeoInfoPrefix: Array<string> = [];
  const additionalSeoInfoPostfix: Array<string> = [];
  if (listProPageNumber > 1) {
    let proPageInfo = `プロ一覧${listProPageNumber}ページ目`;
    if (language === 'en') {
      proPageInfo = `Pro Page ${listProPageNumber}`;
    }
    additionalSeoInfoPrefix.push(proPageInfo);
  }
  if (caseStudyPageNumber > 1) {
    let caseStudyPageInfo = `事例${caseStudyPageNumber}ページ目`;
    if (language === 'en') {
      caseStudyPageInfo = `Case Study Page ${caseStudyPageNumber}`;
    }
    additionalSeoInfoPostfix.push(caseStudyPageInfo);
  }
  if (additionalSeoInfoPostfix.length === 0 && additionalSeoInfoPrefix.length === 0) {
    return output;
  }
  let delimiterCharacter = '、';
  if (language === 'en') {
    delimiterCharacter = ',';
  }

  const descriptionPostfix = language === 'en' ? '' : 'です。';
  output.title = [
    ...additionalSeoInfoPrefix,
    appendUpdatedAtString({ language, title }),
    ...additionalSeoInfoPostfix,
  ].join(' | ');

  output.ogTitle = [...additionalSeoInfoPrefix, title].join(' | ');
  output.description = `${description}${[
    ...additionalSeoInfoPrefix,
    ...additionalSeoInfoPostfix,
  ].join(delimiterCharacter)}${descriptionPostfix}`;

  return output;
};

export const get5HomeVerticalJobTypes = (jobTypeId: string) => {
  if (jobTypeId === 'home-reform-general') {
    return [
      'carpenter',
      'civil-construction-specialist',
      'design-renovation-specialist',
      'home-reform-specialist',
      'interiorfinishingconstruction',
    ];
  }
  return [];
};

export const formatProCardServiceLocation = ({
  serviceLocationCity,
  serviceLocationCityGroup,
  serviceLocationPrefecture,
}: {
  serviceLocationCity?: string;
  serviceLocationCityGroup?: string;
  serviceLocationPrefecture?: string;
}): string | undefined => {
  let serviceLocation;

  if (serviceLocationPrefecture) {
    if (serviceLocationCity) {
      serviceLocation = `${serviceLocationPrefecture}${serviceLocationCity}`;
    } else if (serviceLocationCityGroup) {
      serviceLocation = `${serviceLocationPrefecture}${serviceLocationCityGroup}`;
    } else {
      serviceLocation = serviceLocationPrefecture;
    }
  } else {
    serviceLocation = undefined;
  }

  return serviceLocation;
};

export function containsUtmPair(utmParams: UtmParameter, utmList: Array<UtmParameter>): boolean {
  return utmList.some(
    (pair) => pair.utmMedium === utmParams.utmMedium && pair.utmSource === utmParams.utmSource
  );
}
