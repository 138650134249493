import _ from 'lodash';
import { createLogic } from 'redux-logic';

import { languageSelector } from 'modules/common/duck';

import { jobTypeDetailSelector, listProSignUpSelector } from './selectors';

import {
  GET_DETAIL,
  GET_DETAIL_ERROR,
  GET_DETAIL_SUCCESS,
  LIST,
  LIST_ERROR,
  LIST_SUCCESS,
  LIST_POPULAR,
  LIST_POPULAR_ERROR,
  LIST_POPULAR_SUCCESS,
  LOAD_RELATED,
  LOAD_RELATED_ERROR,
  LOAD_RELATED_SUCCESS,
  LIST_BY_CATEGORY,
  LIST_BY_CATEGORY_ERROR,
  LIST_BY_CATEGORY_SUCCESS,
  LIST_SERVICE_OF_THE_MONTH,
  LIST_SERVICE_OF_THE_MONTH_ERROR,
  LIST_SERVICE_OF_THE_MONTH_SUCCESS,
} from './types';

import {
  list,
  listByCategoryId,
  listPopular,
  loadJobTypeWithFirstPro,
  loadRelated,
  listServiceOfTheMonth,
} from '../service';
import { RootState } from 'store/types';

import {
  getDetail as getDetailAction,
  list as listAction,
  listPopular as listPopularAction,
  loadRelated as loadRelatedAction,
  listByCategoryId as listByCategoryIdAction,
  listServiceOfTheMonth as listServiceOfTheMonthAction,
} from './actions';

const getDetailLogic = createLogic<RootState, ReturnType<typeof getDetailAction>['payload']>({
  process({ action, getState }) {
    const language = languageSelector(getState());
    return loadJobTypeWithFirstPro(action.payload.jobTypeId, language, action.payload.format);
  },
  processOptions: {
    failType: GET_DETAIL_ERROR,
    successType: GET_DETAIL_SUCCESS,
  },
  type: GET_DETAIL,
  validate({ action, getState }, allow, reject) {
    // reject if there's no jobTypeId provided
    if (!action.payload.jobTypeId) {
      return reject({ type: GET_DETAIL_ERROR });
    }

    const state = getState();

    // only load if detail is not loaded
    const hasDetails = jobTypeDetailSelector(state);
    if (!hasDetails[action.payload.jobTypeId]) {
      return allow(action);
    }

    return reject({ type: GET_DETAIL_ERROR });
  },
});

const listJobTypesLogic = createLogic<RootState, ReturnType<typeof listAction>['payload']>({
  latest: true,
  process({ action, getState }) {
    const { proSignUp } = action.payload;
    const language = languageSelector(getState());
    const query = proSignUp ? { proSignUp: true } : { clientRequest: true };
    return list(query, language);
  },
  processOptions: {
    failType: LIST_ERROR,
    successType: LIST_SUCCESS,
  },
  type: LIST,
  validate({ getState, action }, allow, reject) {
    const state = getState();

    const { proSignUp } = action.payload;
    const jobTypes = listProSignUpSelector(state);
    // load if list is not loaded
    if (_.isEmpty(jobTypes)) {
      return allow(action);
    }
    // we will always reload if proSignUp is true.
    // If not we need to have a way of knowing when language is changed for proSignUp list
    // TODO find a way to avoid this.
    if (proSignUp) {
      return allow(action);
    }

    // ignore if list jobTypes already loaded
    return reject({ type: LIST_ERROR });
  },
});

const listPopularLogic = createLogic<RootState, ReturnType<typeof listPopularAction>['payload']>({
  process({ action, getState }) {
    const language = languageSelector(getState());
    return listPopular(action.payload, language);
  },
  processOptions: {
    failType: LIST_POPULAR_ERROR,
    successType: LIST_POPULAR_SUCCESS,
  },
  type: LIST_POPULAR,
});

const loadRelatedLogic = createLogic<RootState, ReturnType<typeof loadRelatedAction>['payload']>({
  process({ action, getState }) {
    const language = languageSelector(getState());
    return loadRelated(
      {
        jobTypeId: action.payload.id,
        prefectureId: action.payload.prefectureId,
        requestableOnly: action.payload.requestableOnly,
      },
      language
    );
  },
  processOptions: {
    failType: LOAD_RELATED_ERROR,
    successType: LOAD_RELATED_SUCCESS,
  },
  type: LOAD_RELATED,
  validate({ action }, allow, reject) {
    return action.payload.id ? allow(action) : reject({ type: LOAD_RELATED_ERROR });
  },
});

const listByCategoryLogic = createLogic<
  RootState,
  ReturnType<typeof listByCategoryIdAction>['payload']
>({
  type: LIST_BY_CATEGORY,
  processOptions: {
    failType: LIST_BY_CATEGORY_ERROR,
    successType: LIST_BY_CATEGORY_SUCCESS,
  },
  process({ action }) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { id } = action.payload;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return listByCategoryId(id);
  },
});

const listServiceOfTheMonthLogic = createLogic<
  RootState,
  ReturnType<typeof listServiceOfTheMonthAction>
>({
  process() {
    return listServiceOfTheMonth();
  },
  processOptions: {
    failType: LIST_SERVICE_OF_THE_MONTH_ERROR,
    successType: LIST_SERVICE_OF_THE_MONTH_SUCCESS,
  },
  type: LIST_SERVICE_OF_THE_MONTH,
});

export default [
  getDetailLogic,
  listJobTypesLogic,
  listPopularLogic,
  loadRelatedLogic,
  listByCategoryLogic,
  listServiceOfTheMonthLogic,
];
