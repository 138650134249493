import { Context } from '@z-components/utils/oauth';

export const makeContextHeader = (
  context?: Context
): { 'x-social-context': string } | Record<string, unknown> =>
  context === undefined ? {} : { 'x-social-context': context };

export const makeSourceHeader = (
  source?: string
): { 'client-source': string } | Record<string, unknown> =>
  source === undefined ? {} : { 'client-source': source };

const CLIENT_SOURCES = {
  REQUEST_FORM: 'request-form',
};

export const makeRequestFormClientSourceString = (
  options: { mode?: string; step?: string } = {}
) => {
  const { mode } = options;
  const modePart = mode ? `-${mode}` : '';
  const stepPart = options.step ? `-${options.step}` : '';
  return `${CLIENT_SOURCES.REQUEST_FORM}${stepPart}${modePart}`;
};
