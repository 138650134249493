import { State as CommonState } from 'modules/common/duck';
import { State as JobTypesState } from 'modules/job-types/duck';
import { State as MarketingPagesState } from 'modules/marketing-pages/duck';
import { State as TranslationsState } from 'modules/translations/duck';
import { State as RequestsState } from 'modules/requests/duck';
import { State as UsersState } from 'modules/users/duck';

export interface RootState {
  common: CommonState;
  form: Record<string, unknown>;
  jobCategories: Record<string, unknown>;
  jobTypes: JobTypesState;
  layout: Record<string, unknown>;
  locations: Record<string, unknown>;
  marketingPages: MarketingPagesState;
  requests: RequestsState;
  socket: Record<string, unknown>;
  translations: TranslationsState;
  users: UsersState;
}

export const SET_CACHED_STATES = 'zehitomo/index/SET_CACHED_STATES';
