/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { combineReducers } from 'redux';

import reducer from './reducers';
import commonLogics from './logic';

// from sub-modules

import authReducers from '../modules/auth/duck';
import profileReducers, { logic as profileLogics } from '../modules/profiles/duck';

const logic = [...commonLogics, ...profileLogics];

export {
  auth,
  changePassword,
  credentialExisting,
  listNotifications,
  markAllNotifications,
  markASingleNotification,
  listAnnouncements,
  markASingleAnnouncement,
  markAllAnnouncements,
  signOut,
} from './actions';

export {
  announcementSelector,
  clientNotificationsSelector,
  currentRequestUserSelector,
  currentUserSelector,
  currentUserLanguagesSelector,
  hasUnreadNotificationsSelector,
  isAuthenticatedSelector,
  proNotificationsSelector,
  getCurrentUser,
  getIsLoadingAuth,
} from './selectors';

// sub-module exports
export { loadPro, proSelector, proJobTypesSelector } from '../modules/profiles/duck';

export { AUTH_USER_SUCCESS, SIGN_OUT_SUCCESS, State } from './types';

export { logic };

const fallback = (state) => state;

export default combineReducers({
  common: reducer || fallback,
  auth: authReducers || fallback,
  profiles: profileReducers || fallback,
});
