export const CREATE_REQUEST = 'zehitomo/requests/CREATE_REQUEST';
export const CREATE_REQUEST_ERROR = 'zehitomo/requests/CREATE_REQUEST_ERROR';
export const CREATE_REQUEST_SUCCESS = 'zehitomo/requests/CREATE_REQUEST_SUCCESS';

export const GET_PRO_COUNT = 'zehitomo/requests/GET_PRO_COUNT';
export const GET_PRO_COUNT_ERROR = 'zehitomo/requests/GET_PRO_COUNT_ERROR';
export const GET_PRO_COUNT_SUCCESS = 'zehitomo/requests/GET_PRO_COUNT_SUCCESS';

export const GET_DUPLICATE_REQUEST = 'zehitomo/requests/GET_DUPLICATE_REQUEST';
export const GET_DUPLICATE_REQUEST_ERROR = 'zehitomo/requests/GET_DUPLICATE_REQUEST_ERROR';
export const GET_DUPLICATE_REQUEST_SUCCESS = 'zehitomo/requests/GET_DUPLICATE_REQUEST_SUCCESS';
export const CLEAR_DUPLICATE_REQUEST = 'zehitomo/requests/CLEAR_DUPLICATE_REQUEST';

export const SET_NEW_REQUEST_MODAL_CONTEXT = 'zehitomo/requests/SET_NEW_REQUEST_MODAL_CONTEXT';

export const SET_NEW_REQUEST_MODAL_PERF_TIMINGS =
  'zehitomo/requests/SET_NEW_REQUEST_MODAL_PERF_TIMINGS';

export const SET_NEW_REQUEST_MODAL_PROPERTIES =
  'zehitomo/requests/SET_NEW_REQUEST_MODAL_PROPERTIES';

// TODO: (dricholm) Added basic types for state for now
export interface State {
  // TODO: Should include additional properties
  createdRequest: { createdTime: Date; expirationTime: Date; id: string } | null;
  duplicateRequestId: string | null;
  isDuplicateRequestLoading: boolean | null;
  newRequestModal?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context?: { address: any; city: any; prefecture: any };
    initialValues?: Record<string, unknown>;
    isSubtitleWithoutJobType?: boolean;
    isTestVariation?: boolean;
    jobTypeId?: string;
    originJobTypeId?: string;
    perfTimings?: {
      apiCallCompletedAt?: number;
      launchedAt: number;
      testVariant?: string;
    };
    proCount?: number;
    selectedProId?: string;
    shouldSkipIntroStep: boolean;
    showCloseButton?: boolean;
    stepsToSkipOnFirstTime?: Array<string>;
  };
}
