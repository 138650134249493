import { createLogic } from 'redux-logic';

import { languageSelector } from 'modules/common/duck';

import * as types from './types';

import {
  getCity,
  getGeoLocation,
  getPrefecture,
  listCities,
  listCityGroups,
  listPrefectures,
} from '../service';

const getCityLogic = createLogic({
  type: types.GET_CITY,
  processOptions: {
    failType: types.GET_CITY_ERROR,
    successType: types.GET_CITY_SUCCESS,
  },
  process({ action, getState }) {
    const language = languageSelector(getState());
    return getCity(action.payload, language);
  },
  validate({ action }, allow, reject) {
    if (action.payload?.cityKey && action.payload.prefectureKey) {
      return allow(action);
    }

    // ignore if no city and prefecture key is provided
    return reject();
  },
});

const getGeoLocationLogic = createLogic({
  type: types.GET_GEOLOCATION,
  processOptions: {
    failType: types.GET_GEOLOCATION_ERROR,
    successType: types.GET_GEOLOCATION_SUCCESS,
  },
  process({ action, getState }) {
    const language = languageSelector(getState());
    return getGeoLocation(action.payload.ip, language);
  },
});

const getPrefectureLogic = createLogic({
  type: types.GET_PREFECTURE,
  processOptions: {
    failType: types.GET_PREFECTURE_ERROR,
    successType: types.GET_PREFECTURE_SUCCESS,
  },
  validate({ action }, allow, reject) {
    if (action.payload?.prefectureKey) {
      return allow(action);
    }

    // ignore if no prefecture key is provided
    return reject();
  },
  process({ action, getState }) {
    const language = languageSelector(getState());
    return getPrefecture(action.payload, language);
  },
});

const listCitiesLogic = createLogic({
  type: types.LIST_CITIES,
  processOptions: {
    failType: types.LIST_CITIES_ERROR,
    successType: types.LIST_CITIES_SUCCESS,
  },
  validate({ action }, allow, reject) {
    if (action.payload?.jobTypeId && action.payload.prefectureKey) {
      return allow(action);
    }

    // ignore if no prefecture key is provided
    return reject();
  },
  process({ action, getState }) {
    const language = languageSelector(getState());
    return listCities(action.payload, language);
  },
});

const listCityGroupsLogic = createLogic({
  type: types.LIST_CITY_GROUPS,
  processOptions: {
    failType: types.LIST_CITY_GROUPS_ERROR,
    successType: types.LIST_CITY_GROUPS_SUCCESS,
  },
  validate({ action }, allow, reject) {
    if (action.payload?.prefectureKey) {
      return allow(action);
    }

    // ignore if no prefecture key is provided
    return reject();
  },
  process({ action, getState }) {
    const language = languageSelector(getState());
    return listCityGroups(action.payload, language);
  },
});

const listPrefecturesLogic = createLogic({
  type: types.LIST_PREFECTURES,
  processOptions: {
    failType: types.LIST_PREFECTURES_ERROR,
    successType: types.LIST_PREFECTURES_SUCCESS,
  },
  validate({ action }, allow, reject) {
    if (action.payload?.jobTypeId) {
      return allow(action);
    }

    // ignore if no jobTypeId is provided
    return reject();
  },
  process({ action, getState }) {
    const language = languageSelector(getState());
    return listPrefectures(action.payload, language);
  },
});

export default [
  getCityLogic,
  getGeoLocationLogic,
  getPrefectureLogic,
  listCitiesLogic,
  listPrefecturesLogic,
  listCityGroupsLogic,
];
