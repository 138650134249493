import _ from 'lodash';
import { createSelector } from 'reselect';

import { allRelatedSelector, listClientRequestableSelector } from 'modules/job-types/duck';

const profileSelector = (state) => state.users.profiles;

export const proSelector = createSelector(profileSelector, (users) => users.pro);

export const proJobTypesSelector = createSelector(
  proSelector,
  listClientRequestableSelector,
  allRelatedSelector,
  (pro, jobTypes, related) => {
    // FIXME: The 'if' statement is insufficiently conditional and poses a risk of bugs. It would be better to rewrite it in this way.
    // if (Array.isArray(pro.enabledJobTypes) && pro.enabledJobTypes.length) {
    if (pro.enabledJobTypes) {
      if (pro.enabledJobTypes.length > 1) {
        return pro.enabledJobTypes;
        // FIXME: this 'else if' statement is unnecessary
      } else if (pro.enabledJobTypes.length === 1) {
        const jobTypesRelatedAndExcludedProsOwnPrimaryJobType =
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          (related[pro.enabledJobTypes[0].id] || []).filter(
            (jobType) => jobType.id !== pro.enabledJobTypes[0].id
          );
        return [...pro.enabledJobTypes, ...jobTypesRelatedAndExcludedProsOwnPrimaryJobType];
      }
    }

    return jobTypes;
  }
);

export const proMainJobTypeSelector = createSelector(
  proSelector,
  listClientRequestableSelector,
  (pro, jobTypes) => {
    const mainJobType = _.get(pro, 'proProfile.mainJobType');
    // eslint-disable-next-line no-restricted-properties
    return _.find(jobTypes, (jobType) => jobType.id === mainJobType);
  }
);

export const proNameSelector = createSelector(proSelector, (pro) => _.get(pro, 'name', ''));

export const isValidItpProSelector = createSelector(
  proSelector,
  (pro) =>
    pro.isItp &&
    !pro.converted &&
    _.has(pro, 'proProfile.enabledJobTypesArray') &&
    pro.proProfile.enabledJobTypesArray.length > 0
);
