import { LOAD_PRO_SUCCESS } from './types';

export const initialState = { pro: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PRO_SUCCESS:
      return { ...state, pro: action.payload };

    default:
      return state;
  }
};
export default reducer;
