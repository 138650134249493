/* eslint-disable no-console */
import { FC, useEffect, useRef } from 'react';
import { AppInitialProps, AppProps, NextWebVitalsMetric } from 'next/app';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { compose } from 'redux';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import 'core-js/features/string/replace-all';
import 'core-js/features/array/find-last-index';
import { isAuthenticatedSelector, auth } from 'modules/users/duck';
import { wrapper } from 'src/store/index';
import { initialize as initializeSessionContext } from 'utils/session-context';
import { getUserIdFromLocalStorage, setFields as setGoogleAnalyticsFields } from 'utils/analytics';
import createReporter, { Nav } from 'utils/web-vitals-reporter';
import { getCookieByKey, AUTH_COOKIE, consumeAuthCookie } from '@z-components/utils/oauth';
import { setAudienceAuthenticationState } from 'utils/convert';

import '../src/styles/styles.scss';
import { OPTIMIZE_TEST_ID_LIST } from 'constants/google-optimize';
import { captureException } from '@sentry/nextjs';
import { useConvert } from 'src/hooks';
import { onINP } from 'web-vitals/attribution';

if (process.env.ENABLE_MSW === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  import('../mocks/msw');
}

// Workaround for HubSpot chat SDK & TS typing
interface scopeWindow {
  HubSpotConversations?: {
    widget: {
      refresh: () => void;
    };
  };
  Munchkin?: {
    init: (id: string) => void;
  };
  location: {
    href: string;
  };
}
declare let window: scopeWindow;

const WEB_VITALS_ENDPOINT_URL = `${process.env.API_HOST ?? ''}/api/events/web-vitals`;

// Interval in ms to check if Munchkin has been added probably to the Window object
const MUNCHKIN_CHECK_INTERVAL = 100;

/**
 * Initialize munchkin found in Window object.
 * The implementation here follows the step described in Marketo documentation
 * https://experienceleague.adobe.com/docs/marketo/using/product-docs/administration/additional-integrations/add-munchkin-tracking-code-to-your-website.html
 * Note : to get some value described in the document, an Admin level Access to Marketo Engage dashboard is required
 * @param {string} munchkinId
 */
const initializeMunchkin = (munchkinId: string) => {
  const intervalHandler = setInterval(() => {
    if (window.Munchkin !== undefined) {
      window.Munchkin.init(munchkinId);
      clearInterval(intervalHandler);
    }
  }, MUNCHKIN_CHECK_INTERVAL);
};

// Next.js provides generic getInitialProps when using class MyApp extends App which will be picked up by wrapper,
// so you must not extend App as you'll be opted out of Automatic Static Optimization:
// https://err.sh/next.js/opt-out-auto-static-optimization
// Just export a regular Functional Component as in the example above.
// Also workaround for https://github.com/vercel/next.js/issues/8592
const MyApp: FC<AppProps<AppInitialProps> & { err?: unknown }> = ({
  Component,
  err,
  pageProps,
}) => {
  const router = useRouter();
  const mounted = useRef(false);
  const dispatch = useDispatch();
  useEffect(() => {
    // would be required for any oauth flow that redirects back to z-web
    // with an auth token. dropped pro registration is a flow that consumes this logic
    const authCookie = getCookieByKey(document.cookie, AUTH_COOKIE);
    if (authCookie) {
      try {
        consumeAuthCookie({ url: window.location.href });
        dispatch(auth());
      } catch (error) {
        captureException(error);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    initializeSessionContext(document.location.href, router.pathname.replace('/', ''));
  });

  useEffect(() => {
    onINP((metric) => {
      try {
        const nav: Nav = navigator;
        const effectiveType = nav.connection?.effectiveType;
        const beacon = {
          connection: { effectiveType },
          cpus: nav.hardwareConcurrency,
          memory: nav.deviceMemory,
          url: location.href,
          userAgent: nav.userAgent,
          interactionTarget: metric.attribution.interactionTarget,
          interactionType: metric.attribution.interactionType,
          processingDuration: metric.attribution.processingDuration,
          navigationType: metric.navigationType,
          INP: metric.value,
          rating: metric.rating,
        };

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (navigator.sendBeacon) {
          navigator.sendBeacon(WEB_VITALS_ENDPOINT_URL, JSON.stringify(beacon));
        }
      } catch (err) {
        console.warn('Unable to send INP beacon', err);
      }
    });
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (process.env.MARKETO_MUNCHKIN_ID) {
      initializeMunchkin(process.env.MARKETO_MUNCHKIN_ID);
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  const isAuthenticated = useSelector<boolean>(isAuthenticatedSelector);
  useEffect(() => {
    const userId = getUserIdFromLocalStorage();
    setGoogleAnalyticsFields({ userId });
  }, [isAuthenticated]);
  useEffect(() => {
    const userId = getUserIdFromLocalStorage();
    setAudienceAuthenticationState(userId);
  }, []);

  const handleRouteEventHubspot = () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.refresh();
    }
  };
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteEventHubspot);
    return () => {
      router.events.off('routeChangeComplete', handleRouteEventHubspot);
    };
  });
  // Setting up base convert for subcomponents
  useConvert();

  return (
    <>
      {process.env.GOOGLE_TAG_MANAGER_ID && (
        <Script
          strategy="afterInteractive"
          id="gtm-script"
          src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GOOGLE_TAG_MANAGER_ID}`}
        />
      )}
      <Script strategy="afterInteractive" src="https://statics.a8.net/a8sales/a8sales.js" />
      <Script strategy="afterInteractive" src="https://statics.a8.net/a8sales/a8crossDomain.js" />
      {process.env.GOOGLE_RECAPTCHA_V3_KEY && (
        <>
          <Script
            strategy="afterInteractive"
            id="google-recpatcha_v3"
            src={`https://www.google.com/recaptcha/api.js?render=${process.env.GOOGLE_RECAPTCHA_V3_KEY}`}
          />
        </>
      )}
      {process.env.GOOGLE_OPTIMIZE_ID && OPTIMIZE_TEST_ID_LIST.length > 0 && (
        <Script
          strategy="afterInteractive"
          id="google-optimize-script"
          src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GOOGLE_OPTIMIZE_ID}`}
        />
      )}
      {process.env.GOOGLE_OPTIMIZE_GA4_ID && OPTIMIZE_TEST_ID_LIST.length > 0 && (
        <Script
          strategy="afterInteractive"
          id="google-optimize-script-ga4"
          src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GOOGLE_OPTIMIZE_GA4_ID}`}
        />
      )}
      {process.env.CONVERT_ACCOUNT_ID && process.env.CONVERT_ACCOUNT_PROJECT_ID && (
        <Script
          strategy="afterInteractive"
          id="convert-script"
          src={`https://cdn-4.convertexperiments.com/js/${process.env.CONVERT_ACCOUNT_ID}-${process.env.CONVERT_ACCOUNT_PROJECT_ID}.js`}
        />
      )}
      {(process.env.GOOGLE_OPTIMIZE_ID || process.env.GOOGLE_OPTIMIZE_GA4_ID) &&
        OPTIMIZE_TEST_ID_LIST.length > 0 && (
          <Script
            dangerouslySetInnerHTML={{
              __html:
                `(function(w,l){` +
                `w[l] = w[l] || [];w[l].push({'event':'optimize.activate'});` +
                `})(window,'dataLayer');`,
            }}
            id="google-optimize-activate"
          />
        )}
      {process.env.NODE_ENV === 'production' && (
        <Script
          strategy="afterInteractive"
          id="hs-script-loader"
          async={true}
          defer={true}
          src="//js.hs-scripts.com/3926470.js"
        />
      )}
      {process.env.NODE_ENV === 'development' && (
        <Script
          strategy="afterInteractive"
          id="hs-script-loader"
          async={true}
          defer={true}
          src="//js.hs-scripts.com/21112044.js"
        />
      )}
      {process.env.MARKETO_MUNCHKIN_ID && (
        <Script strategy="afterInteractive" src="//munchkin.marketo.net/munchkin.js" />
      )}
      <Component {...pageProps} err={err} />
    </>
  );
};

export default compose(appWithTranslation, wrapper.withRedux)(MyApp);

const report = createReporter(WEB_VITALS_ENDPOINT_URL);

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (
    metric.label === 'web-vital' &&
    typeof window !== 'undefined' &&
    /^\/(home|lifestyle|learning|business|events|pro)/.test(document.location.pathname)
  ) {
    report(metric);
  }
}
