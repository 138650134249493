import _ from 'lodash';
import { captureException } from '@sentry/nextjs';

import httpClient, { getLanguageHeader } from 'src/http-client';
import { parseCity, parsePrefecture } from 'utils/data-transform/location';
import type { PublicRequest } from 'modules/requests/service';
import type { City, Location } from 'utils/data-transform/location';
import type { State } from './duck';
import type {
  BlogPost,
  LandingPagePro,
  ProBlogPost,
  ListProsRequestParams,
  ProJobCategoriesForSignup,
} from './marketing-page.types';
import type { CaseStudy } from '@z-components/organisms/CaseStudyCardProProfile/types';
import type { ProCardProProfile } from 'modules/marketing-pages/components/ListPros/ProCard';
import {
  NUM_NEARBY_CITIES_PROS_PER_PAGE,
  NUM_NEARBY_CITIES_PROS_PER_PAGE_OVER_PAGE2,
} from 'constants/vertical-lp';

// TODO: Use this import `get5HomeVerticalJobTypes` on Story-10, PROD-4889
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { get5HomeVerticalJobTypes } from './utils';
import { ProFeedbackByJobType } from './duck/types';

function formatPros(
  pros: Array<Omit<LandingPagePro, 'page'>>,
  language: string,
  page: number
): Array<LandingPagePro> {
  return pros.map((pro) => {
    if (_.has(pro, 'location.city.key') && _.has(pro, 'location.prefecture.key')) {
      return {
        ...pro,
        location: {
          ...(pro.location ?? {}),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          city: parseCity(pro.location?.city as City, language),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          prefecture: parsePrefecture(pro.location?.prefecture as Location, language),
          title: pro.location?.title ?? '',
        },
        page,
      };
    }

    return { ...pro, page };
  });
}

export async function listBlogPosts(language = 'ja') {
  const { data } = await httpClient.get<Array<BlogPost>>('/blog-posts', {
    headers: getLanguageHeader(language),
  });
  return data;
}

export async function listBlogPostsByCategoryId(categoryId: string) {
  const { data } = await httpClient.get<Array<BlogPost>>(`/blog-posts/${categoryId}`);
  return data;
}

export async function listProBlogPostsByJobTypeId(jobTypeId: string) {
  const { data } = await httpClient.get<Array<ProBlogPost>>(
    `/pro-blog-posts/job-type/${jobTypeId}`
  );
  return data;
}

export async function getProJobCategoriesForSignUpTree() {
  // 11/18/2022: z-api only looks for existence of path as a query param to do tree minimization
  const { data } = await httpClient.get<ProJobCategoriesForSignup>(
    'pro-job-categories-for-signup/tree?path=initial'
  );
  return data;
}

export async function listPros({
  cityGroup,
  cityKey,
  day,
  excludeCities,
  jobTypeId,
  language,
  matchingCriteria,
  page,
  prefectureKey,
  priceRange,
  pricingMenuItemKey,
  size,
  skip,
  time,
}: {
  cityGroup?: string;
  cityKey?: string;
  day?: string;
  excludeCities?: Array<string>;
  jobTypeId: string;
  language: string;
  matchingCriteria?: string;
  page: number;
  prefectureKey?: string;
  priceRange?: string;
  pricingMenuItemKey?: string;
  size: number;
  skip?: number;
  time?: string;
}) {
  let listUrl = '/pro-location-jobtype-ref/pros-by-job';
  let pageSize = size;
  let pageSkip = skip;
  // In case we're on page 2+, but we're accidentally using size for page 1, we fix size/skip
  if (size === NUM_NEARBY_CITIES_PROS_PER_PAGE) {
    if (page > 1) {
      pageSize = NUM_NEARBY_CITIES_PROS_PER_PAGE_OVER_PAGE2;
    }
    if (page > 1) {
      pageSkip =
        NUM_NEARBY_CITIES_PROS_PER_PAGE + NUM_NEARBY_CITIES_PROS_PER_PAGE_OVER_PAGE2 * (page - 2);
    }
  }

  const params: ListProsRequestParams = {
    day,
    jobType: jobTypeId,
    language: language as 'en' | 'ja',
    matchingCriteriaFilter:
      (cityKey || prefectureKey) && matchingCriteria ? matchingCriteria : undefined,
    priceRange: priceRange ? priceRange : undefined,
    pricingMenuItemKey: pricingMenuItemKey ? pricingMenuItemKey : undefined,
    size: pageSize,
    skip: pageSkip,
    time,
  };

  if (cityGroup && cityKey) {
    listUrl = '/pro-location-jobtype-ref/pros-by-job-prefecture-and-city-not-nearby-cities';
    params.city = cityKey;
    params.prefecture = prefectureKey;
  } else if (cityGroup && !cityKey) {
    listUrl = '/pro-location-jobtype-ref/pros-by-job-and-city-group';
    params.cityGroup = cityGroup;
    params.excludeCities = excludeCities;
  } else if (cityKey) {
    listUrl = '/pro-location-jobtype-ref/pros-by-job-prefecture-and-city';
    params.city = cityKey;
    params.prefecture = prefectureKey;
  } else if (prefectureKey) {
    listUrl = '/pro-location-jobtype-ref/pros-by-prefecture-and-job';
    params.prefecture = prefectureKey;
  }

  const {
    data: { pros, totalPros, proReviewStatistics = null },
  } = await httpClient.get<{
    proReviewStatistics: { ratingValue: number; reviewCount: number } | null;
    pros: Array<Omit<LandingPagePro, 'page'>>;
    totalPros: number;
  }>(listUrl, {
    headers: getLanguageHeader(language),
    params,
  });

  const formattedPros = formatPros(pros, language, page);
  return {
    proReviewStatistics,
    pros: formattedPros,
    total: totalPros,
  };
}

export async function getNewestReviewsByJobType({ jobTypeId }: { jobTypeId: string }) {
  const response = await httpClient.get<State['newestReviews']>(
    `/pro-feedbacks/get-newest-by-jobtype/${jobTypeId}`
  );

  return Array.isArray(response.data) ? response.data : [];
}

export async function getNewestReviewsByJobTypeAndPrefecture({
  jobTypeId,
  prefecture,
}: {
  jobTypeId: string;
  prefecture: string;
}) {
  const response = await httpClient.get<State['newestReviews']>(
    `/pro-feedbacks/get-newest-by-jobtype/${jobTypeId}/${prefecture}`
  );
  return Array.isArray(response.data) ? response.data : [];
}

export async function getProCount({
  cityKey,
  jobTypeId,
  language,
  prefectureKey,
}: {
  cityKey?: string;
  jobTypeId: string;
  language?: string;
  prefectureKey?: string;
}) {
  const count = await httpClient
    .get('/pro-location-jobtype-ref/pros-count', {
      params: { cityKey, jobTypeId, language, prefectureKey },
    })
    .then((res) => (typeof res.data === 'number' ? res.data : 0));
  return { count };
}

export async function listSampleRequests({
  cityId,
  jobTypeId,
  language,
  prefectureId,
  limit,
}: {
  cityId?: string;
  jobTypeId: string;
  language: string;
  limit?: number;
  prefectureId?: string;
}) {
  const response = await httpClient.get<State['sampleRequests']>('/sample-requests/query', {
    headers: getLanguageHeader(language),
    params: { cityId, jobTypeId, language, limit, prefectureId },
  });
  return Array.isArray(response.data) ? response.data : [];
}

export async function trackUserEvent({
  event,
  properties,
  type,
}: {
  event: string;
  properties: Record<string, any>;
  type: string;
}) {
  await httpClient.post('/events', { event, meta: { timestamp: Date.now() }, properties, type });
}

export async function getPopularPricingItems({
  cityKey,
  jobTypeId,
  language = 'ja',
  prefectureKey,
}: {
  cityKey?: string;
  jobTypeId: string;
  language?: string;
  prefectureKey?: string;
}) {
  const response = await httpClient.get<{
    popularItems: State['popularPricingItems'];
  }>('/pricing-menu/popular-items', { params: { cityKey, jobTypeId, language, prefectureKey } });
  return response.data.popularItems;
}

export async function getCaseStudies({
  city,
  cityGroup,
  jobTypeId,
  language,
  currentPage,
  pageSize,
  prefecture,
}: {
  city?: string;
  cityGroup?: string;
  currentPage: number;
  jobTypeId: string;
  language: string;
  pageSize: number;
  prefecture?: string;
}) {
  // API end points for published case studies
  let apiEndpoint = `case-studies/job-type/${jobTypeId}`;
  if (prefecture && cityGroup && !city) {
    apiEndpoint = `case-studies/job-type/${jobTypeId}/${prefecture}/cityGroup/${cityGroup}`;
  }
  if (prefecture && cityGroup && city) {
    apiEndpoint = `case-studies/job-type/${jobTypeId}/${prefecture}/city/${city}`;
  }
  if (prefecture && !cityGroup && city) {
    apiEndpoint = `case-studies/job-type/${jobTypeId}/${prefecture}/city/${city}`;
  }
  if (prefecture && !cityGroup && !city) {
    apiEndpoint = `case-studies/job-type/${jobTypeId}/${prefecture}`;
  }
  const {
    data: { caseStudies, totalCaseStudies },
  } = await httpClient.get<{
    caseStudies: Array<CaseStudy>;
    totalCaseStudies: number;
  }>(apiEndpoint, {
    headers: getLanguageHeader(language),
    params: {
      currentPage,
      pageSize,
    },
  });

  // This API don't have totalFilteredOutCaseStudies, use totalCaseStudies.
  return {
    caseStudies: caseStudies.map((caseStudy: CaseStudy) => ({ ...caseStudy, page: currentPage })),
    totalCaseStudies,
  };
}

export async function getCaseStudiesByUserId({
  // TODO: Use var `jobTypeId` on Story-10, PROD-4889
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  jobTypeId,
  pageSize,
  userId,
}: {
  jobTypeId: string;
  pageSize: number;
  userId: string;
}) {
  // API end points for all case studies of a pro
  const apiEndpoint = `case-studies/pros/${userId}`;
  const {
    data: { caseStudies, totalFilteredOutCaseStudies },
  } = await httpClient.get<{
    caseStudies: Array<CaseStudy>;
    totalFilteredOutCaseStudies: number;
  }>(apiEndpoint, {
    params: {
      pageSize,
      caseStatus: 'published', // add this query param to get only published case studies
      isServicePage: true,
    },
  });

  // NOTE: The `page` property is designated as required based on its type
  // However, 'paging' is not necessary on the 'service profile page',
  // so it is cumbersome to require it as an argument for this function.
  // so pass the dummy value of 1 at here.
  const dummyPageNumber = 1;

  let cases = [];

  // TODO: Use this block on Story-10, PROD-4889
  // if (jobTypeId) {
  //   const homeVerticalJobTypes = get5HomeVerticalJobTypes(jobTypeId);
  //   const filterFunction = homeVerticalJobTypes.length
  //     ? (caseStudy: CaseStudy) => homeVerticalJobTypes.includes(caseStudy.jobTypeId)
  //     : (caseStudy: CaseStudy) => caseStudy.jobTypeId === jobTypeId;

  //   cases = caseStudies
  //     .filter(filterFunction)
  //     .map((caseStudy: CaseStudy) => ({ ...caseStudy, page: dummyPageNumber }))
  //     .slice(0, pageSize);
  // } else {
  //   cases = caseStudies
  //     .map((caseStudy: CaseStudy) => ({ ...caseStudy, page: dummyPageNumber }))
  //     .slice(0, pageSize);
  // }

  // TODO: Delete this block on Story-10, PROD-4889
  cases = caseStudies
    .map((caseStudy: CaseStudy) => ({ ...caseStudy, page: dummyPageNumber }))
    .slice(0, pageSize);

  // Current codebase using totalCaseStudies, but it should be totalFilteredOutCaseStudies
  return {
    caseStudies: cases,
    totalCaseStudies: totalFilteredOutCaseStudies,
  };
}

/**
 * =============================
 * Start Popular Request Section
 * =============================
 */

export interface PopularRequestSampleApply {
  coverLetter: string;
  proProfile: ProCardProProfile;
}

export interface PopularRequest {
  id: string;
  request: PublicRequest;
  sampleApplyInfo: PopularRequestSampleApply;
}

export async function getPopularRequests({
  cityKey,
  jobTypeId,
  prefectureKey,
}: {
  cityKey: string | undefined;
  jobTypeId: string;
  prefectureKey: string | undefined;
}) {
  try {
    const { data: popularRequests } = await httpClient.get<Array<PopularRequest>>(
      `requests/popular-requests/`,
      {
        params: {
          cityKey,
          jobTypeId,
          prefectureKey,
        },
      }
    );

    return popularRequests;
  } catch (error) {
    return [];
  }
}

/**
 * ===========================
 * End Popular Request Section
 * ===========================
 */

interface GetBlogPostByJobTypeIdParams {
  isCostTag?: boolean;
  jobTypeId: string;
  options?: { blogPostNameToFilter?: string };
}
export const getInternalBlogPostsByJobTypeId = async ({
  isCostTag = false,
  jobTypeId,
  options: { blogPostNameToFilter } = {},
}: GetBlogPostByJobTypeIdParams): Promise<Array<BlogPost>> => {
  let internalJobTypeRelatedBlogPosts: Array<BlogPost>;
  try {
    const endpoint = `/internal-blog-posts/job-type/${jobTypeId}?isCostTag=${isCostTag.toString()}`;
    ({ data: internalJobTypeRelatedBlogPosts } = await httpClient.get<Array<BlogPost>>(endpoint));
  } catch (error) {
    captureException(error);
    return [];
  }
  return blogPostNameToFilter
    ? internalJobTypeRelatedBlogPosts.filter(({ name }) => name !== blogPostNameToFilter)
    : internalJobTypeRelatedBlogPosts;
};

interface GetBlogPostByCategoryIdParams {
  categoryId: string;
}
export const getInternalBlogPostsByCategoryId = async ({
  categoryId,
}: GetBlogPostByCategoryIdParams): Promise<Array<BlogPost>> => {
  let internalJobTypeRelatedBlogPosts: Array<BlogPost>;
  try {
    ({ data: internalJobTypeRelatedBlogPosts } = await httpClient.get<Array<BlogPost>>(
      `/internal-blog-posts/category/${categoryId}`
    ));
  } catch (error) {
    captureException(error);
    return [];
  }

  return internalJobTypeRelatedBlogPosts;
};

export async function getProFeedBacksByJobTypeId({
  jobTypeId,
  rate,
  page,
}: {
  jobTypeId: string;
  page: number;
  rate?: number;
}) {
  return httpClient
    .get<ProFeedbackByJobType>(`/pro-feedbacks/get-by-job-type/${jobTypeId}`, {
      params: {
        page,
        rate,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      captureException(error);
    });
}
