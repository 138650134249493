import { logEvent } from '../analytics';
import { trackingEventForGA4 } from '../eventTrackingForGA4';
import { sendTelemetryBeacon, TelemetryBeacon } from 'utils/telemetry';
import { setItem, getAndParseItem, removeItem } from 'utils/sessionStorage';

interface AnalyticsActionMap {
  [jobTypeId: string]: Record<string, boolean> | undefined;
}

interface GAProperties {
  action: string;
  category: string;
  customParameters?: Record<string, string>;
  label?: string;
}

export interface LogOptions {
  beacon?: Omit<TelemetryBeacon, 'action'>;
  jobTypeId?: string;
  shouldDedupeEventsInSession?: boolean;
}

interface TelemetryOptions {
  beacon?: TelemetryBeacon;
}

/**
 * Keeping abstract for now but could imagine this holding vars like correlationId, conversationId
 * @deprecated
 * Deprecating because this wasn't a useful abstraction and will effectively be deprecated after
 * https://github.com/zehitomo/z-web/pull/2295
 */
export default class Analytics {
  private static analyticsActionMap = 'analyticsActionMap';
  constructor() {
    if (this.constructor === Analytics) {
      throw new Error('Analytics is an abstract class.');
    }
  }

  private static hasDuplicateEventAction(action: string, jobTypeId: string) {
    const analyticsActionMap = getAndParseItem<AnalyticsActionMap>(Analytics.analyticsActionMap);
    if (analyticsActionMap?.[jobTypeId]?.[action]) {
      return true;
    }
    return false;
  }

  private static setAnalyticsActionMap(action: string, jobTypeId: string) {
    const analyticsActionMap = getAndParseItem<AnalyticsActionMap>(Analytics.analyticsActionMap);
    if (analyticsActionMap === null) {
      setItem(Analytics.analyticsActionMap, { [jobTypeId]: { [action]: true } });
    } else if (analyticsActionMap[jobTypeId] === undefined) {
      setItem(Analytics.analyticsActionMap, {
        ...analyticsActionMap,
        [jobTypeId]: { [action]: true },
      });
    } else {
      setItem(Analytics.analyticsActionMap, {
        ...analyticsActionMap,
        [jobTypeId]: { ...analyticsActionMap[jobTypeId], [action]: true },
      });
    }
  }

  static unsetAnalyticsActionMap(jobTypeId: string) {
    const analyticsActionMap = getAndParseItem<AnalyticsActionMap>(Analytics.analyticsActionMap);
    if (analyticsActionMap?.[jobTypeId]) {
      delete analyticsActionMap[jobTypeId];
      if (Object.keys(analyticsActionMap).length === 0) {
        removeItem(Analytics.analyticsActionMap);
      } else {
        setItem(Analytics.analyticsActionMap, analyticsActionMap);
      }
    }
  }

  static logGA(
    { action, category, label, customParameters }: GAProperties = {
      action: '',
      category: '',
      customParameters: {},
      label: '',
    },
    { beacon, shouldDedupeEventsInSession, jobTypeId }: LogOptions | undefined = {}
  ) {
    if (
      shouldDedupeEventsInSession &&
      jobTypeId &&
      Analytics.hasDuplicateEventAction(action, jobTypeId)
    ) {
      return;
    }
    logEvent(category, action, label);
    trackingEventForGA4(action, category, label, customParameters);
    if (beacon && action) {
      const beaconWithGAAction = { ...beacon, action };
      sendTelemetryBeacon(beaconWithGAAction);
    }

    if (shouldDedupeEventsInSession && jobTypeId) {
      Analytics.setAnalyticsActionMap(action, jobTypeId);
    }
  }

  static sendTelemetry({ beacon }: TelemetryOptions) {
    if (beacon) {
      sendTelemetryBeacon(beacon);
    }
  }

  static makeRequestCategoryString(jobTypeId: string) {
    return `NewRequest:${jobTypeId}`;
  }
}
