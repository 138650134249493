import * as sessionStorage from './sessionStorage';
import * as localStorage from './localStorage';

export function getItem(name: string): string | null {
  const item = localStorage.getItem(name);
  if (item) {
    return item;
  }
  return sessionStorage.getItem(name);
}

export function removeItem(name: string) {
  localStorage.removeItem(name);
  sessionStorage.removeItem(name);
}

export function setItem(name: string, item: string) {
  if (!localStorage.setItem(name, item)) {
    sessionStorage.setItem(name, item);
  }
}
