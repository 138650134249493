import _ from 'lodash';

import { normalizeToHalfWidth } from 'utils/data-transform/string';

export const required =
  (message = 'form:errors.required') =>
  (value) => {
    if (typeof value === 'string') {
      return value.trim() ? undefined : [message];
    }

    return value ? undefined : [message];
  };

export const maxLength = (max) => (value) =>
  value && value.length > max ? ['form:errors.maxLength', { max }] : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? ['form:errors.minLength', { min }] : undefined;

export const maxValue = (max) => (value) =>
  typeof value === 'number' && value > max ? ['form:errors.max', { max }] : undefined;

export const minValue = (min) => (value) =>
  typeof value === 'number' && value < min ? ['form:errors.min', { min }] : undefined;

export const regex =
  (pattern, customError = 'form:errors.pattern') =>
  (value) => {
    if (value) {
      return new RegExp(pattern).test(value) ? undefined : [customError];
    }
    return undefined;
  };

export const address = (value) => {
  if (!value) return undefined;
  return value.includes('ZERO_RESULTS') ? ['form:errors.address'] : undefined;
};

// https://help.xmatters.com/ondemand/trial/valid_email_format.htm
export const EMAIL_PATTERN =
  /^[a-zA-Z0-9][a-zA-Z0-9_.+-]*[a-zA-Z0-9]@[a-zA-Z0-9][a-zA-Z0-9-.]*[a-zA-Z0-9]\.[a-zA-Z0-9]{2,}$/;
export const REPEATING_SYMBOLS = /([_.+-])\1{1}/;

export const emailValidator = (value) => {
  const isCorrectEmailPattern = new RegExp(EMAIL_PATTERN).test(value);
  const hasRepeatingSymbols = new RegExp(REPEATING_SYMBOLS).test(value);

  if (
    !isCorrectEmailPattern ||
    hasRepeatingSymbols ||
    (window.location.href.includes('www.zehitomo.com') && value.includes('+'))
  ) {
    return ['form:errors.email'];
  }

  return undefined;
};

export const phone = (value) => {
  if (!value) return undefined;

  const inputToHalfWidth = normalizeToHalfWidth(value);
  const hasRepeatingDigits = /(\d)\1{4,}/.test(inputToHalfWidth);
  const isValidJapanesePhoneNumber =
    /^0[5789]0[1-9]{1}\d{7}$/.test(inputToHalfWidth) || /^0[1-9]{2}\d{7}$/.test(inputToHalfWidth);
  const hasConsecutiveNumbers = /01234|12345|23456|34567|45678|56789|67890/.test(inputToHalfWidth);
  const hasTenOrElevenDigits = /^\d{10,11}$/.test(inputToHalfWidth);
  return !hasRepeatingDigits &&
    isValidJapanesePhoneNumber &&
    !hasConsecutiveNumbers &&
    hasTenOrElevenDigits
    ? undefined
    : ['form:errors.phone'];
};
export const fullNameEnglish = (value) =>
  /^[ -~]+$/.test(value) ? undefined : ['form:errors.name.english'];

export const fullNameJapanese = (value) => {
  const hasRepeatingHiragana = /^([ぁ-ゞ])\1*$/.test(value) || /^([ぁ-ゞ])\1*$/.test(value);
  const forbiddenNamesRegex = new RegExp(['匿名', 'とくめい'].join('|'));
  const containsForbiddenName = forbiddenNamesRegex.test(value);
  const onlyContainsJapanese = /^[^ -~｡-ﾟ]*$/.test(value);
  if (!onlyContainsJapanese) {
    return ['form:errors.name.kanji'];
  }
  if (hasRepeatingHiragana || containsForbiddenName) {
    return ['form:errors.invalidName'];
  }
  return undefined;
};

export const zipCodeValidator = (value) => {
  const fulfilledSevenDigits = /^\d{3}-?\d{4}$/.test(value);
  const lastFourDigitsIsAllZero = /^\d{3}-?0{4}$/.test(value);
  if (!fulfilledSevenDigits) {
    return ['form:errors.zipCodeNotExists'];
  }
  if (lastFourDigitsIsAllZero) {
    return ['form:errors.zipCodeLastFourDigitsIsAllZero'];
  }
  return undefined;
};

export const companyNameFuriganaRequired =
  (message = 'form:errors.required') =>
  (value, formValues) => {
    if (!formValues.profile?.companyNameKanji) return undefined;
    if (typeof value === 'string') {
      return value.trim() ? undefined : [message];
    }

    return value ? undefined : [message];
  };

export default function getValidators(props) {
  const validators = [];

  if (props.required) {
    validators.push(required(typeof props.required === 'string' ? props.required : undefined));
  }

  if (props.companyNameFuriganaRequired) {
    validators.push(
      companyNameFuriganaRequired(
        typeof props.companyNameFuriganaRequired === 'string'
          ? props.companyNameFuriganaRequired
          : undefined
      )
    );
  }

  if (typeof props.minLength === 'number') {
    validators.push(minLength(props.minLength));
  }

  if (typeof props.maxLength === 'number') {
    validators.push(maxLength(props.maxLength));
  }

  if (typeof props.min === 'number') {
    validators.push(minValue(props.min));
  }

  if (typeof props.max === 'number') {
    validators.push(maxValue(props.max));
  }

  if (typeof props.pattern === 'string' || _.isRegExp(props.pattern)) {
    validators.push(regex(props.pattern));
  }
  if (typeof props.pattern === 'object' && props.pattern.regex) {
    validators.push(regex(props.pattern.regex, props.pattern.error));
  }

  if (props.address) {
    validators.push(address);
  }

  if (props.email) {
    validators.push(emailValidator);
  }

  if (props.phone) {
    validators.push(phone);
  }

  if (props.zipCode) {
    validators.push(zipCodeValidator);
  }

  if (props.fullNameEnglish) {
    validators.push(fullNameEnglish);
  }

  if (props.fullNameJapanese) {
    validators.push(fullNameJapanese);
  }

  return validators;
}
