export function waitForWindowProperty(propertyName: string, timeout = 5000) {
  let intervalId: NodeJS.Timeout | undefined;
  return {
    promise: new Promise((resolve, reject) => {
      const startTime = Date.now();
      const hundredMS = 100;

      intervalId = setInterval(() => {
        const castedWindow = window as unknown as Record<string, unknown>;
        if (castedWindow[propertyName] && intervalId) {
          clearInterval(intervalId);
          resolve(castedWindow[propertyName]);
        } else if (Date.now() - startTime > timeout && intervalId) {
          clearInterval(intervalId);
          reject(new Error(`Timeout waiting for ${propertyName}`));
        }
      }, hundredMS);
    }),
    intervalId,
  };
}
