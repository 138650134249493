/* eslint-disable no-underscore-dangle, camelcase */

import { ConvertExperimentEnvironment, Environment } from './types.d';
import { CONFIGS, Experiment, Goal, GOAL_CONFIGS, VariantsMap } from 'utils/convert/config';

function getEnv(): Environment {
  return process.env.APP_ENV === 'production' ? Environment.Prod : Environment.PreProd;
}

function getExperimentConfig(experiment: Experiment): ConvertExperimentEnvironment {
  const env = getEnv();
  return CONFIGS[experiment][env];
}

function getGoalId(goal: Goal): string {
  const env = getEnv();
  return GOAL_CONFIGS[env][goal];
}

export function getActiveVariationForExperiment<E extends Experiment>(
  experiment: E
): keyof VariantsMap[E] | null {
  const config = getExperimentConfig(experiment);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const variationId = window.convert?.currentData?.experiments?.[config.experimentId]?.variation_id;

  if (variationId !== undefined) {
    for (const [variantName, variantId] of Object.entries(config.variants)) {
      if (variantId === String(variationId)) {
        return variantName as keyof VariantsMap[E];
      }
    }
  }

  return null;
}

export function isVariantActive<E extends Experiment>(
  experiment: E,
  variantName: keyof VariantsMap[E]
): boolean {
  const activeVariantName = getActiveVariationForExperiment(experiment);
  return variantName === activeVariantName;
}

export function executeExperiment(experiment: Experiment) {
  const config = getExperimentConfig(experiment);
  window.convertExperienceManualTriggers = window.convertExperienceManualTriggers ?? {};
  window.convertExperienceManualTriggers[config.experimentId] = true;
  window._conv_q = window._conv_q ?? [];
  try {
    window._conv_q.push(['executeExperiment', config.experimentId]);
  } catch (_error) {
    // intentionally left blank
  }
}

export function triggerGoal(goal: Goal) {
  const goalId = getGoalId(goal);
  if (goalId) {
    window._conv_q = window._conv_q ?? [];
    window._conv_q.push(['triggerConversion', goalId]);
  }
}

export function setAudienceAuthenticationState(userId: string | null) {
  if (typeof window === 'undefined') {
    return;
  }
  const authenticationState = userId ? 'authenticated' : 'unauthenticated';
  const convertAudience = getOrInitializeConvertAudienceOnWindow();
  if (convertAudience) {
    convertAudience['authenticationState'] = authenticationState;
  }
}

function getOrInitializeConvertAudienceOnWindow() {
  if (typeof window === 'undefined') {
    return;
  }
  window.convertAudience = window.convertAudience ?? {};
  return window.convertAudience;
}
export function isConvertLoaded() {
  // @ts-expect-error im indexing into window as a one off check. Dont want to make window generally indexable
  return typeof window !== 'undefined' && typeof window[getConvertWindowProperty()] !== 'undefined';
}

export function getConvertWindowProperty() {
  return 'convert';
}
