export const CLOSE_SOCKET = 'zehitomo/common/CLOSE_SOCKET';
export const CLOSE_SOCKET_ERROR = 'zehitomo/common/CLOSE_SOCKET_ERROR';
export const CLOSE_SOCKET_SUCCESS = 'zehitomo/common/CLOSE_SOCKET_SUCCESS';

export const REGISTER_SOCKET_EVENT = 'zehitomo/common/REGISTER_SOCKET_EVENT';
export const REGISTER_SOCKET_EVENT_SUCCESS =
  'zehitomo/common/REGISTER_SOCKET_EVENT_SUCCESS';

export const SETUP_SOCKET = 'zehitomo/common/SETUP_SOCKET';
export const SETUP_SOCKET_ERROR = 'zehitomo/common/SETUP_SOCKET_ERROR';
export const SETUP_SOCKET_SUCCESS = 'zehitomo/common/SETUP_SOCKET_SUCCESS';
