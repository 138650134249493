import uuid from 'uuid';
import _ from 'lodash';
import {
  getSessionIdCookie,
  deleteSessionIdCookie,
  getAnonymousIdCookie,
  deleteAnonymousIdCookie,
  makeSessionIdCookie,
  makeAnonymousIdCookie,
  LOCAL_STORAGE_KEYS,
} from '@z-components/utils/oauth';
import { TELEMETRY_STORAGE_KEYS } from '@z-components/utils/telemetry';
import { getAndParseItem } from './localStorage';

interface CreateTelemetryBeaconOpts {
  action?: string;
  anonymousId?: string | null;
  apiDuration?: number;
  // should not be included in body but as a header!
  clientIp?: string | null;
  content?: string;
  context?: string;
  conversationId?: string;
  correlationId?: string;
  duration?: number;
  errorCodes?: string;
  jobTypeId?: string;
  provider?: string;
  result?: string;
  sessionId?: string | null;
  /** Can be used to describe step modality. (e.g. a single step with multiple forms) */
  stepMode?: string;
  stepName?: string;
  success?: boolean;
  testVariant?: string | null;
  userId?: string;
}

export type TelemetryBeacon = {
  anonymousId: string | null;
  // optional on server side
  pageLoadId?: string;
  sessionId: string | null;
} & CreateTelemetryBeaconOpts;

export type PartialBeacon = Omit<TelemetryBeacon, 'action'> & {
  conversationId: string;
  correlationId: string;
};

const makeTelemetryUrl = (endpoint: 'telemetry' | 'funnel') => {
  let apiHost: string;
  if (process.env.NODE_ENV === 'test') {
    apiHost = 'http://localhost:3000';
  } else if (typeof window === 'undefined') {
    // prefer api server to server when on the server side so that we make calls within our VPC
    apiHost = (process.env.API_HOST_SERVER_TO_SERVER ?? process.env.API_HOST) as string;
  } else {
    apiHost = process.env.API_HOST as string;
  }
  return `${apiHost}/api/events/${endpoint}`;
};
const TELEMETRY_URL = makeTelemetryUrl('telemetry');
const FUNNEL_URL = makeTelemetryUrl('funnel');

const pageLoadId = uuid();

let anonymousId: string | null = null;
let sessionId: string | null = null;
const removeQuotes = (id: string | null) => {
  if (!id) {
    return id;
  }
  return typeof id === 'string' ? id.replace(/"/g, '') : null;
};

if (typeof window !== 'undefined') {
  anonymousId = removeQuotes(localStorage.getItem(TELEMETRY_STORAGE_KEYS.anonymousId));
  sessionId = removeQuotes(sessionStorage.getItem(TELEMETRY_STORAGE_KEYS.sessionId));

  // initialize persistent identifiers
  try {
    if (!anonymousId) {
      localStorage.setItem(
        TELEMETRY_STORAGE_KEYS.anonymousId,
        JSON.stringify(getAnonymousIdCookie(document.cookie) ?? uuid())
      );
      anonymousId = removeQuotes(localStorage.getItem(TELEMETRY_STORAGE_KEYS.anonymousId));
      deleteAnonymousIdCookie({ url: window.location.href });
    }

    if (!sessionId) {
      sessionStorage.setItem(
        TELEMETRY_STORAGE_KEYS.sessionId,
        JSON.stringify(getSessionIdCookie(document.cookie) ?? uuid())
      );
      sessionId = removeQuotes(sessionStorage.getItem(TELEMETRY_STORAGE_KEYS.sessionId));
      deleteSessionIdCookie({ url: window.location.href });
    }
  } catch (_error) {
    // unable to access browser storage, perhaps due to private browsing or the user's privacy settings
  }
}

export function createTelemetryBeacon(opts: CreateTelemetryBeaconOpts) {
  return {
    conversationId: uuid(),
    correlationId: uuid(),
    pageLoadId,
    anonymousId,
    sessionId,
    ...opts,
  };
}

type FunnelEventOptions = {
  stepMode?: string;
  testVariant?: string | null;
};
export function sendFunnelEvent(
  jobTypeId: string,
  stepName: string,
  { testVariant, stepMode }: FunnelEventOptions = { testVariant: '0' }
) {
  return sendTelemetryBeacon(
    createTelemetryBeacon({ stepName, jobTypeId, testVariant, stepMode }),
    'funnel'
  );
}

export function sendTelemetryBeacon(
  payload: TelemetryBeacon,
  endpoint: 'telemetry' | 'funnel' = 'telemetry'
) {
  const headers: { 'z-connecting-ip'?: string } = {};
  if (payload.clientIp) {
    headers['z-connecting-ip'] = payload.clientIp;
    delete payload.clientIp;
  }
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const finalPayload = _.omitBy(payload, _.isNil);
  if (!finalPayload.userId) {
    const userId = getAndParseItem<string>(LOCAL_STORAGE_KEYS.USER_ID);
    if (userId) {
      finalPayload.userId = userId;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (typeof navigator !== 'undefined' && navigator.sendBeacon) {
    navigator.sendBeacon(
      endpoint === 'telemetry' ? TELEMETRY_URL : FUNNEL_URL,
      JSON.stringify(finalPayload)
    );
    return;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  fetch(endpoint === 'telemetry' ? TELEMETRY_URL : FUNNEL_URL, {
    body: JSON.stringify(finalPayload),
    keepalive: true,
    method: 'POST',
    headers,
  });
}

// TODO: Unify behavior between this function and the server side one, http client needs to be
// updated as well when we do this.
export function getPersistentTracingIdentifiers() {
  return { sessionId, anonymousId };
}

/**
 * Gets persistent tracing identifiers from cookies
 *
 * NOTE: This function does not use the module vars sessionId, anonymousId because they are NOT
 * Unique per request.
 */
export function getPersistentTracingIdentifiersServerSide(cookies: string) {
  const serverSessionId = getSessionIdCookie(cookies);
  const serverAnonymousId = getAnonymousIdCookie(cookies);
  return { serverAnonymousId, serverSessionId };
}

/**
 * ClientSide
 * Useful for forwarding persistent identifiers stored in client browsers to serverside to
 * enable continuity in beacons sent
 */
export function setPersistentTracingIdentifiersInCookies() {
  const { sessionId: persistentSessionId, anonymousId: persistentAnonymousId } =
    getPersistentTracingIdentifiers();
  makeSessionIdCookie({ value: persistentSessionId, url: window.location.href, sameSite: 'Lax' });
  makeAnonymousIdCookie({
    value: persistentAnonymousId,
    url: window.location.href,
    sameSite: 'Lax',
  });
}
