import { JobTypeAlias } from './types';

export function getAliasProperties(jobTypeId: string, jobTypeAliases: Array<JobTypeAlias>) {
  if (!Array.isArray(jobTypeAliases)) {
    return {
      isSubtitleWithoutJobType: false,
      jobTypeAlias: undefined,
      jobTypeId,
      originJobTypeId: undefined,
    };
  }
  const jobTypeAlias = jobTypeAliases.find((alias) => alias.jobTypeId === jobTypeId);

  if (jobTypeAlias) {
    return {
      isSubtitleWithoutJobType: true,
      jobTypeAlias: jobTypeAlias.alias,
      jobTypeId,
      originJobTypeId: jobTypeId,
    };
  }

  // case for home-reform-general where there is some special behavior related to isSubtitleWithoutJobType because
  // it is the alias of other job types.
  const isJobTypeIdAliasType = jobTypeAliases.some((alias) => alias.alias === jobTypeId);
  return {
    isSubtitleWithoutJobType: isJobTypeIdAliasType,
    jobTypeAlias: undefined,
    jobTypeId,
    originJobTypeId: undefined,
  };
}

/**
 * TODO: Remove this function and use the above when we implement or remove the A/B test
 *
 * @deprecated
 */
export function deprecatedGetAliasProperties(
  jobTypeId: string,
  jobTypeAliases: Array<JobTypeAlias>,
  modalProperties: Record<string, unknown>
) {
  const jobTypeAlias = jobTypeAliases.find((val) => val.jobTypeId === jobTypeId);

  if (jobTypeAlias) {
    modalProperties.jobTypeId = jobTypeAlias.alias;
    modalProperties.originJobTypeId = jobTypeId;
  }

  const alias = jobTypeAliases.find((val) => val.alias === jobTypeId);

  if (jobTypeAlias || alias) {
    modalProperties.isSubtitleWithoutJobType = true;
  } else {
    modalProperties.isSubtitleWithoutJobType = false;
  }
}
