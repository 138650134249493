export const LOCAL_STORAGE_KEYS = {
  AUTH_DATA: 'ngStorage-authData',
  FAILED_ATTEMPTS: 'ngStorage-failedLoginCount',
  USER_ID: 'ngStorage-currentUid',
  SOCIAL_REQUEST_META: 'ngStorage-socialRequestMeta',
};

export const Context = {
  clientRegistration: 'client-registration' as const,
  proRegistration: 'pro-registration' as const,
  requestForm: 'request-form' as const,
  signIn: 'sign-in' as const,
  unknownContext: 'unknown-context' as const,
};

export const AUTH_COOKIE = 'z-authData';
export const SOCIAL_ACCESS_COOKIE = 'z-socialOauth';
export const NONCE_COOKIE_KEY = 'z-oauth-nonce';

export const Cookies = {
  authCookie: AUTH_COOKIE,
  oauthNonceCookie: NONCE_COOKIE_KEY,
  anonymousIdCookie: 'z_anid',
  sessionIdCookie: 'z_sid',
};
