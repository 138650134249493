import logic from './logic';
import reducer from './reducers';

export {
  getDetail,
  list,
  listPopular,
  loadRelated,
  listByCategoryId,
  listServiceOfTheMonth,
} from './actions';
export {
  allRelatedSelector,
  jobTypeDetailSelector,
  jobTypeListByCategorySelector,
  jobTypeSelector,
  listClientRequestableSelector,
  listProSignUpSelector,
  popularJobTypesSelector,
  proSignUpJobTypeSelector,
  relatedJobTypesSelector,
  serviceOfTheMonthSelector,
  verticalPageJobTypeSelector,
} from './selectors';
export * from './types';

export default reducer;
export { logic };
