interface LoadingCacheOptions<K, V> {
  loader: (key: K) => Promise<V>;
  maxAge: number;
}

interface CachedValue<V> {
  expires: number;
  value: V;
}

/**
 * Bare-bones implementation of a Guava-style Loading Cache. Keys are cached indefinitely and stale values are refreshed
 * asynchronously.
 */
export default class LoadingCache<K, V> {
  private options: LoadingCacheOptions<K, V>;

  private cache = new Map<K, CachedValue<V>>();

  /**
   * Constructor
   *
   * @param options
   */
  constructor(options: LoadingCacheOptions<K, V>) {
    this.options = options;
  }

  /**
   * Gets a value from the cache. If the key has expired, the stale value will be returned while a refresh is performed
   * asynchronously.
   *
   * @param key
   */
  public async get(key: K): Promise<V> {
    const cacheEntry = this.cache.get(key);

    if (!cacheEntry) {
      return this.load(key);
    } else if (cacheEntry.expires < Date.now()) {
      // Swallowing this error because without it, we get "UnhandledPromiseRejection" errors
      // eslint-disable-next-line no-void,@typescript-eslint/no-empty-function
      void this.load(key).catch(() => {});
    }

    return cacheEntry.value;
  }

  /**
   * Invokes the loader and caches the return value
   *
   * @param key
   * @private
   */
  private async load(key: K) {
    const value = await this.options.loader(key);
    this.cache.set(key, {
      expires: Date.now() + this.options.maxAge,
      value,
    });

    return value;
  }
}
