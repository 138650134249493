import { Action } from 'redux';
import { createLogic } from 'redux-logic';
import { createSelector } from 'reselect';
import { captureException } from '@sentry/nextjs';

import { languageSelector } from 'modules/common/duck';
import { RootState } from 'store/types';

import { get } from './service';

export interface State {
  [id: string]: Record<string, string> | undefined;
}

export const LOAD = 'zehitomo/translations/LOAD';
export interface LoadAction extends Action {
  payload: { id: string };
  type: typeof LOAD;
}
export const load = (id: string): TranslationsActions => ({
  payload: { id },
  type: LOAD,
});

export const LOAD_ERROR = 'zehitomo/translations/LOAD_ERROR';
export interface LoadErrorAction extends Action {
  type: typeof LOAD_ERROR;
}
export const loadError = (): TranslationsActions => ({ type: LOAD_ERROR });

export const LOAD_SUCCESS = 'zehitomo/translations/LOAD_SUCCESS';
export interface LoadSuccessAction extends Action {
  payload: {
    [key: string]: string;
    id: string;
  };
  type: typeof LOAD_SUCCESS;
}

export type TranslationsActions = LoadAction | LoadErrorAction | LoadSuccessAction;

const loadTranslationLogic = createLogic<RootState, LoadAction['payload']>({
  async process({ action, getState }) {
    const language = languageSelector(getState());
    try {
      const translations = await get(action.payload.id, language);
      return translations;
    } catch (error) {
      captureException(error);
      throw error;
    }
  },
  processOptions: {
    failType: LOAD_ERROR,
    successType: LOAD_SUCCESS,
  },
  type: LOAD,
  validate({ getState, action }, allow, reject) {
    const state = getState();

    // Reloading translations is not necessary
    if (state.translations[action.payload.id]) {
      return reject(loadError());
    }

    return allow(action);
  },
});
export const logic = [loadTranslationLogic];

export default function reducer(state = {}, action: TranslationsActions) {
  switch (action.type) {
    case LOAD_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    default:
      return state;
  }
}

const translationsSelector = (state: RootState) => state.translations;

// TODO: Make this work without a cast
const translationIdSelector = (_state: RootState, props: any) =>
  (props as { translationId: string }).translationId;

export const translationSelector = createSelector(
  translationsSelector,
  translationIdSelector,
  (translations, id) => translations[id] ?? {}
);

/**
 * https://react-redux.js.org/api/hooks#useselector-examples
 * when the selector is used in multiple component instances and depends on the component's props,
 * you need to ensure that each component instance gets its own selector instance
 */
export const makeSelectTranslations = () => translationSelector;
