import { AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { RootState } from 'store/types';

export interface State {
  language: string;
}

export const SET_LANGUAGE = 'zehitomo/common/SET_LANGUAGE';

export const setLanguage = (language: string) => ({
  payload: language,
  type: SET_LANGUAGE,
});

const initialState = { language: '' };

const commonReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, language: (action as ReturnType<typeof setLanguage>).payload };

    default:
      return state;
  }
};
export default commonReducer;

const commonSelector = (state: RootState) => state.common;

export const languageSelector = createSelector(commonSelector, (common) => common.language);
