/* eslint-disable id-length */
import { parseCity, parsePrefecture, parseCityGroup } from 'utils/data-transform/location';
import httpClient, { getLanguageHeader } from 'src/http-client';

import type { City, CityGroup, Location, LocationConfiguration, Prefecture } from './types';

export async function getCity(
  { prefectureKey, cityKey }: { cityKey: string; prefectureKey: string },
  language = 'ja'
) {
  const option = {
    headers: getLanguageHeader(language),
  };
  const res = await httpClient.get<City>(
    `/location-prefectures/${prefectureKey}/location-cities/${cityKey}`,
    option
  );
  const { data } = res;

  return parseCity(data, language);
}

export async function getCitiesByPrefecture({ prefectureId }: { prefectureId: string }) {
  const res = await httpClient.get<Array<City>>(
    `/location-prefectures/${prefectureId}/location-cities`
  );
  const { data } = res;

  return data;
}

export async function getGeoLocation(ip = '', language = 'ja') {
  const option = {
    headers: getLanguageHeader(language),
    params: {
      ip,
    },
  };
  const response = await httpClient.get<Location>('/locations/geolocation', option);

  const location = response.data;
  const { city, prefecture } = location;

  const [cityObj, prefectureObj] = await Promise.all([
    httpClient
      .get<City>(`/location-cities/${city}`, { headers: getLanguageHeader(language) })
      .then((res) => res.data),
    httpClient
      .get<Prefecture>(`/location-prefectures/${prefecture}`, {
        headers: getLanguageHeader(language),
      })
      .then((res) => res.data),
  ]);

  return {
    location,
    city: parseCity(cityObj, language),
    prefecture: parsePrefecture(prefectureObj, language),
  };
}

export function getPrefecture({ prefectureKey }: { prefectureKey: string }, language = 'ja') {
  const option = {
    headers: getLanguageHeader(language),
    params: {
      key: prefectureKey,
    },
  };
  return httpClient.get<Array<Prefecture>>('/location-prefectures', option).then((res) => {
    const prefecture = Array.isArray(res.data) ? res.data[0] : undefined;

    return parsePrefecture(prefecture, language);
  });
}

export async function listCities(
  {
    cityKey,
    jobTypeId,
    prefectureKey,
  }: { cityKey: string; jobTypeId: string; prefectureKey: string },
  language = 'ja'
) {
  const option = {
    headers: getLanguageHeader(language),
    params: {
      cityKey,
      jobType: jobTypeId,
      prefectureKey,
    },
  };

  const url = cityKey
    ? '/location-cities/nearby-requestable-cities'
    : '/location-cities/requestable-cities';

  const res = await httpClient.get<Array<City>>(url, option);
  const { data } = res;

  return data.map((city) => parseCity(city, language));
}

export async function listCityGroups(
  { prefectureKey }: { prefectureKey: string },
  language = 'ja'
) {
  const option = {
    headers: getLanguageHeader(language),
  };

  const url = `/location-prefectures/${prefectureKey}/location-city-groups`;

  const res = await httpClient.get<Array<CityGroup>>(url, option);
  const { data } = res;

  return data.map((cityGroup) => parseCityGroup(cityGroup, language));
}

export async function listPrefectures(
  { jobTypeId, prefectureKey, size }: { jobTypeId: string; prefectureKey: string; size?: number },
  language = 'ja'
) {
  const option = {
    headers: getLanguageHeader(language),
    params: {
      jobType: jobTypeId,
      prefectureKey,
      size,
    },
  };

  const url = prefectureKey
    ? '/location-prefectures/nearby-requestable-prefectures'
    : '/location-prefectures/requestable-prefectures';

  const res = await httpClient.get<Array<Prefecture>>(url, option);
  const { data } = res;

  return data.map((prefecture) => parsePrefecture(prefecture, language));
}

/**
 * Used in direct booking to determine whether a location has a configuration
 * Tokiwabashi is the only supported location hence there not being much here. The idea
 * is that we would eventually extend this to be a service pulling a location configuration
 * from the backend and if it does not exist just use the default jobType configuration
 */
/* eslint-disable @typescript-eslint/require-await */
export const getLocationConfiguration = async (
  locationId?: string | Array<string> | undefined
): Promise<LocationConfiguration | null> => {
  if (!locationId || Array.isArray(locationId)) {
    return null;
  }

  // 2023/04/01 Tokiwabashi promo was removed, so there are no active promotions.
  return null;
};
