export const GOOGLE_OPTIMIZE_PHONE_LOGIN_TEST_ID = '932kIR6xQjK4sPLIBvqLEQ';
export const GOOGLE_OPTIMIZE_PHONE_LOGIN_NON_PROD_TEST_ID = '0xsQ3OyZS-maqQbngofAaA';
export const GOOGLE_OPTIMIZE_PHONE_LOGIN_TEST_ACTIVATION_EVENT = 'activate-phone-login-test';
export const GOOGLE_OPTIMIZE_NO_HEADER_TEST_ID = 'UerQDKkSQJ-rOzFrGVVVbA';
export const GOOGLE_OPTIMIZE_PROGRESS_BAR_PRO_SIGNUP_TEST_ID = 'la04TuXFSzC5FsWrjppx_w';

export const GOOGLE_OPTIMIZE_LOCATION_SELECT_FIELDS_TEST_ID = 'Up9wnA03TLKniw7RPeRL-w';
export const GOOGLE_OPTIMIZE_LOCATION_SELECT_FIELDS_NON_PROD_TEST_ID = 'Z5DKnBpxQM2oQjSpZ0nVQg';
export const GOOGLE_OPTIMIZE_LOCATION_SELECT_FIELDS_TEST_ACTIVATION_EVENT =
  'activate-location-select-fields-test';

export const GOOGLE_OPTIMIZE_CHAT_FLOW_TEST_ID = '-Dpk1E6uT5-rFzne2djPLg';
export const GOOGLE_OPTIMIZE_CHAT_NON_PROD_TEST_ID = '_EbGRzAMQUKwXxS8a4_Q9g';
export const GOOGLE_OPTIMIZE_ZIP_CODE_EXAMPLE_TEST_ID = '51pOlWkfRISIbGzVX8AElA';

export const GOOGLE_OPTIMIZE_MIKITY_EXPERIMENT_ID = 'JJ68DWFYRtqBEPWk9XZQlw'; // GA4
export const GOOGLE_OPTIMIZE_MIKITY_ACTIVATION_EVENT = 'activate-mikitty-request-flow-test'; // GA4

export const GOOGLE_OPTIMIZE_PROGRESS_REACH_COMPLETED_TEST_ID = '5xsUE9yfSOab2rs0sPn_EA'; // GA4
export const GOOGLE_OPTIMIZE_PROGRESS_REACH_COMPLETED_NON_PROD_TEST_ID = '4zkl04XgSui8cScaU8rVMg'; // GA4

export const GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_VOCAL_INSTRUCTOR =
  'NvGndZTNR9GcUyGBgj15wQ';

export const GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_VOCAL_INSTRUCTOR_NON_PROD =
  'x-kIV5YgTvyqW6vzVpJfXw';
export const GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_PIANO_TEACHER =
  'Iuf6wUx7SmKdDZjdPCG0wQ';
export const GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_PIANO_TEACHER_NON_PROD =
  'l5Vy--FmRy6HGrnSWJGbdg';

const GOOGLE_OPTIMIZE_HYBRID_CHAT_FLOW_NON_PROD_TEST_ID = 'nsYZtZT-QaCmyyGosMljaQ';
const GOOGLE_OPTIMIZE_HYBRID_CHAT_FLOW_PROD_TEST_ID = 't3vlyksRSvqpGLFQxvHtrQ';

/**
 * If you are doing AB testing, put the ExperimentID in this array.
 */
export const OPTIMIZE_TEST_ID_LIST: Array<string> = [
  GOOGLE_OPTIMIZE_MIKITY_EXPERIMENT_ID,
  GOOGLE_OPTIMIZE_NO_HEADER_TEST_ID,
  GOOGLE_OPTIMIZE_PROGRESS_BAR_PRO_SIGNUP_TEST_ID,
  GOOGLE_OPTIMIZE_CHAT_FLOW_TEST_ID,
  GOOGLE_OPTIMIZE_ZIP_CODE_EXAMPLE_TEST_ID,
  GOOGLE_OPTIMIZE_LOCATION_SELECT_FIELDS_TEST_ID,
  GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_VOCAL_INSTRUCTOR,
  GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_PIANO_TEACHER,
];

export const experiments = {
  chatFlowHybrid: {
    productionExperimentId: GOOGLE_OPTIMIZE_HYBRID_CHAT_FLOW_PROD_TEST_ID,
    nonProductionExperimentId: GOOGLE_OPTIMIZE_HYBRID_CHAT_FLOW_NON_PROD_TEST_ID,
  },
  chatFlow: {
    productionExperimentId: GOOGLE_OPTIMIZE_CHAT_FLOW_TEST_ID,
    nonProductionExperimentId: GOOGLE_OPTIMIZE_CHAT_NON_PROD_TEST_ID,
  },
  phoneLogin: {
    productionExperimentId: GOOGLE_OPTIMIZE_PHONE_LOGIN_TEST_ID,
    nonProductionExperimentId: GOOGLE_OPTIMIZE_PHONE_LOGIN_NON_PROD_TEST_ID,
  },
  progressReachCompleted: {
    productionExperimentId: GOOGLE_OPTIMIZE_PROGRESS_REACH_COMPLETED_TEST_ID,
    nonProductionExperimentId: GOOGLE_OPTIMIZE_PROGRESS_REACH_COMPLETED_NON_PROD_TEST_ID,
  },
  valuePropBetterFirstQuestionVocalInstructor: {
    productionExperimentId: GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_VOCAL_INSTRUCTOR,
    nonProductionExperimentId:
      GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_VOCAL_INSTRUCTOR_NON_PROD,
  },
  valuePropBetterFirstQuestionPianoTeacher: {
    productionExperimentId: GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_PIANO_TEACHER,
    nonProductionExperimentId:
      GOOGLE_OPTIMIZE_BETTER_FIRST_QUESTION_EXPERIMENT_ID_PIANO_TEACHER_NON_PROD,
  },
};

export const ExperimentEnums = Object.keys(experiments).reduce((acc, key) => {
  acc[key] = key as keyof typeof experiments;
  return acc;
}, {} as Record<string, keyof typeof experiments>);
