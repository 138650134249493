import * as Sentry from '@sentry/nextjs';

import LoadingCache from 'utils/loading-cache';
import HttpClient from 'src/http-client';
import {
  FeatureFlagsResponse,
  GroupingEnabledJobTypesResponse,
  InternalBlogResponse,
  ReviewsQAResponse,
} from './types';

const FEATURE_FLAGS_PATH = '/feature-flags';

const CITY_GROUPING_ENABLED_JOB_TYPES_FEATURE_FLAG_ID = 'cityGroupingEnabledJobTypes';
const REVIEWS_QA_FEATURE_FLAG_ID = 'reviewsQA';
const INTERNAL_BLOG_FULL_RELEASE_FEATURE_FLAG_ID = 'internalBlogFullRelease';

// eslint-disable-next-line no-magic-numbers
const FEATURE_FLAGS_CACHE_TTL = 1000 * 60;

export default class FeatureFlagsService {
  private static instance: FeatureFlagsService;

  private featureFlagsCache: LoadingCache<string, FeatureFlagsResponse>;

  constructor() {
    this.featureFlagsCache = new LoadingCache({
      loader: async (key: string) => FeatureFlagsService.getFeatureFlag(key),
      maxAge: FEATURE_FLAGS_CACHE_TTL,
    });
  }

  /**
   * Returns the singleton instance of FeatureFlagService
   */
  static getInstance() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!FeatureFlagsService.instance) {
      FeatureFlagsService.instance = new FeatureFlagsService();
    }
    return FeatureFlagsService.instance;
  }

  /**
   * Resolves with a list of jobTypeIds that are enabled for the grouping feature
   */
  public async getGroupingEnabledJobTypes() {
    try {
      const cachedPayload = (await this.featureFlagsCache.get(
        CITY_GROUPING_ENABLED_JOB_TYPES_FEATURE_FLAG_ID
      )) as GroupingEnabledJobTypesResponse;
      return cachedPayload.jobTypeIds;
    } catch (error) {
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error(
        `could not get feature flag ${CITY_GROUPING_ENABLED_JOB_TYPES_FEATURE_FLAG_ID}`,
        error
      );
    }
    return [];
  }

  /**
   * Resolves with a boolean indicating whether the Reviews Q&A feature is enabled
   */
  public async isReviewsQAEnabled() {
    try {
      const cachedPayload = (await this.featureFlagsCache.get(
        REVIEWS_QA_FEATURE_FLAG_ID
      )) as ReviewsQAResponse;
      return cachedPayload.flags.enabled;
    } catch (error) {
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error(`could not get feature flag ${REVIEWS_QA_FEATURE_FLAG_ID}`, error);
    }
    return false;
  }

  public async getEnabledInternalBlogPostsByCategoryOne() {
    try {
      const cachedPayload = (await this.featureFlagsCache.get(
        INTERNAL_BLOG_FULL_RELEASE_FEATURE_FLAG_ID
      )) as InternalBlogResponse;
      return new Set(cachedPayload.enabledByC1);
    } catch (error) {
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error(
        `could not get feature flag ${INTERNAL_BLOG_FULL_RELEASE_FEATURE_FLAG_ID}`,
        error
      );
    }
    return new Set();
  }

  /**
   * Gets feature flag object from the API
   *
   * @param key
   * @private
   */
  private static async getFeatureFlag(key: string) {
    const response = await HttpClient.get<FeatureFlagsResponse>(`${FEATURE_FLAGS_PATH}/${key}`);
    return response.data;
  }
}
