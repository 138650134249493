import { createLogic } from 'redux-logic';

import { getItem } from 'utils/localStorage';

import { socketInstanceSelector } from './selectors';

import {
  CLOSE_SOCKET,
  CLOSE_SOCKET_ERROR,
  CLOSE_SOCKET_SUCCESS,
  SETUP_SOCKET,
  SETUP_SOCKET_ERROR,
  SETUP_SOCKET_SUCCESS,
} from './types';

const setupSocketLogic = createLogic({
  async process({ action }) {
    // eslint-disable-next-line id-length
    const { default: io } = await import(/* webpackChunkName: "socket-io" */ 'socket.io-client');
    const { token } = action.payload;

    const socket = io(process.env.API_HOST, {
      path: '/socket.io-client',
      transports: ['websocket', 'polling'],
    });

    socket.emit('authenticate', token);

    socket.on('disconnect', () => {
      // eslint-disable-next-line
      console.log('Socket disconnected');
    });

    socket.on('reconnect', () => {
      // eslint-disable-next-line
      console.log('Socket reconnected');
      socket.emit('authenticate', token);
    });

    return {
      payload: socket,
      type: SETUP_SOCKET_SUCCESS,
    };
  },
  processOptions: {
    failType: SETUP_SOCKET_ERROR,
  },
  transform({ action }, next) {
    const storedAuthData = getItem('ngStorage-authData');
    if (storedAuthData) {
      const authData = JSON.parse(storedAuthData);
      const { token } = authData;
      if (token) {
        return next({
          ...action,
          payload: {
            token,
          },
        });
      }
    }

    return next(action);
  },
  type: SETUP_SOCKET,
});

const closeSocketLogic = createLogic({
  process({ getState }) {
    const socket = socketInstanceSelector(getState());
    // TODO: remove this (unnecessary emitting due to the old API code base)
    socket.emit('unauthenticate');
    socket.close();
  },
  processOptions: {
    failType: CLOSE_SOCKET_ERROR,
    successType: CLOSE_SOCKET_SUCCESS,
  },
  type: CLOSE_SOCKET,
  validate({ action, getState }, allow, reject) {
    const socket = socketInstanceSelector(getState());
    // reject if there's no socket in store
    if (!socket) {
      return reject();
    }

    return allow(action);
  },
});

export default [closeSocketLogic, setupSocketLogic];
