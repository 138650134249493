import { createSelector } from 'reselect';

import { RootState } from 'store/types';

const jobTypesSelector = (state: RootState) => state.jobTypes;

// select only client requestable job types
export const listClientRequestableSelector = createSelector(jobTypesSelector, (jobTypes) =>
  Object.values(jobTypes.list).filter(({ clientRequest }) => clientRequest)
);

export const listProSignUpSelector = createSelector(jobTypesSelector, (jobTypes) =>
  Object.values(jobTypes.list)
);

export const jobTypeListByCategorySelector = createSelector(
  jobTypesSelector,
  (jobTypes) => jobTypes.listByCategory
);

// TODO: Make this work without a cast
const jobTypeIdSelector = (_selector: any, props: any) =>
  (props as { jobTypeId: string }).jobTypeId;

export const jobTypeSelector = createSelector(
  listClientRequestableSelector,
  jobTypeIdSelector,
  (clientRequestableJobTypes, selectedJobTypeId) =>
    Object.values(clientRequestableJobTypes).find(
      ({ id: jobTypeId }) => jobTypeId === selectedJobTypeId
    )
);

export const proSignUpJobTypeSelector = createSelector(
  listProSignUpSelector,
  jobTypeIdSelector,
  (proSignUpJobTypes, selectedJobTypeId) =>
    Object.values(proSignUpJobTypes).find(({ id: jobTypeId }) => jobTypeId === selectedJobTypeId)
);

export const popularJobTypesSelector = (state: RootState) => state.jobTypes.popular;
export const jobTypeDetailSelector = (state: RootState) => state.jobTypes.hasDetails;

export const allRelatedSelector = createSelector(jobTypesSelector, (jobTypes) => jobTypes.related);
export const relatedJobTypesSelector = createSelector(
  allRelatedSelector,
  jobTypeIdSelector,
  (relatedJobTypes, selectedJobTypeId) => relatedJobTypes[selectedJobTypeId]
);

export const serviceOfTheMonthSelector = (state: RootState) => state.jobTypes.serviceOfTheMonth;
export const verticalPageJobTypeSelector = (state: RootState) => state.jobTypes.verticalPageJobType;
