import { NextWebVitalsMetric } from 'next/app';
import { round } from 'lodash';

const CLS_ROUNDING_PRECISION = 4;

interface NetworkInformation {
  downlink: number;
  effectiveType: 'slow-2g' | '2g' | '3g' | '4g';
  rtt: number;
}

export interface Nav extends Navigator {
  connection?: NetworkInformation;
  deviceMemory?: number;
}

interface WebVitalResult {
  CLS?: number;
  FCP?: number;
  FID?: number;
  LCP?: number;
  TTFB?: number;
}

export default function createReporter(url: string) {
  let result: WebVitalResult = {};
  let isSent = false;
  let isCalled = false;

  const sendValues = (): void => {
    if (isSent) return; // data is already sent
    if (!isCalled) return; // no data collected

    isSent = true;

    if (typeof navigator === 'undefined') return;

    const nav: Nav = navigator;
    const effectiveType = nav.connection?.effectiveType;

    const beaconPayload = {
      connection: { effectiveType },
      cpus: nav.hardwareConcurrency,
      memory: nav.deviceMemory,
      url: location.href,
      userAgent: nav.userAgent,
      ...result,
    };

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (navigator.sendBeacon) {
      navigator.sendBeacon(url, JSON.stringify(beaconPayload));
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetch(url, {
      body: JSON.stringify(beaconPayload),
      keepalive: true,
      method: 'POST',
    });
  };

  const mapMetric = (metric: NextWebVitalsMetric) => {
    const isWebVital = ['FCP', 'TTFB', 'LCP', 'CLS', 'FID'].includes(metric.name);
    return {
      [metric.name]: isWebVital
        ? round(metric.value, metric.name === 'CLS' ? CLS_ROUNDING_PRECISION : 0)
        : metric.value,
    };
  };

  const report = (metric: NextWebVitalsMetric) => {
    if (!isCalled) isCalled = true;
    result = { ...result, ...mapMetric(metric) };

    /**
     * Unfortunately, there is no reliable way to send a beacon when the user navigates away from the page or closes the
     * browser. If the LCP metric has been received, go ahead and send the beacon.
     * @see {@link https://bugs.chromium.org/p/chromium/issues/detail?id=554834} for more information.
     */
    if (metric.name === 'LCP') {
      sendValues();
    }
  };

  return report;
}
