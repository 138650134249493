import LoadingCache from 'utils/loading-cache';
import HttpClient from 'src/http-client';
import FeatureFlagsService from '../feature-flags';
import { VerticalPageTypes } from 'constants/vertical-lp';
import type {
  CityGroupingLocationResolver,
  LocationData,
  GroupKeysById,
  GroupsByKey,
  CitiesByKey,
  LocationCity,
  LocationCityGroup,
  LocationPrefecture,
  PrefecturesByKey,
  ResolvedLocation,
} from './types';

const LOCATION_CITIES_PATH = '/location-cities';
const LOCATION_CITY_GROUPS_PATH = '/location-city-groups';
const LOCATION_PREFECTURES_PATH = '/location-prefectures';

const CITY_GROUPS_CACHE_KEY = 'city-groups';

// eslint-disable-next-line no-magic-numbers
const CITY_GROUPS_CACHE_TTL = 1000 * 60 * 5;

export default class CityGroupingService implements CityGroupingLocationResolver {
  private locationDataCache: LoadingCache<string, LocationData>;

  private featureFlagsService: FeatureFlagsService;
  private groupingEnabledJobTypesSet: Set<string> = new Set();

  constructor() {
    this.locationDataCache = new LoadingCache({
      loader: async () => CityGroupingService.getLocationData(),
      maxAge: CITY_GROUPS_CACHE_TTL,
    });

    this.featureFlagsService = FeatureFlagsService.getInstance();
  }

  resolveServiceAreaName(key: string, language: 'en' | 'ja'): Promise<string> {
    return this.getCityGroupNameByKey(key, language);
  }

  /**
   * Determines the requested group and/or city based on the location values from the router and grouping feature
   * configuration
   *
   * @param jobTypeId
   * @param prefectureKey
   * @param groupKeyOrCityKey
   * @param groupedCityKey
   */
  public async resolveLocation(
    jobTypeId: string,
    prefectureKey?: string,
    groupKeyOrCityKey?: string,
    groupedCityKey?: string
  ): Promise<ResolvedLocation> {
    if (!groupKeyOrCityKey || !prefectureKey) {
      const isCityGroupingEnabled = await this.isGroupingEnabledForJobType(jobTypeId);
      // nothing to resolve
      return { isCityGroupingEnabled };
    }

    const [
      isCityGroupingEnabled,
      { cityKeys, groupsByKey, groupKeysById, citiesByKey, prefecturesByKey },
    ] = await Promise.all([
      this.isGroupingEnabledForJobType(jobTypeId),
      this.locationDataCache.get(CITY_GROUPS_CACHE_KEY),
    ]);

    try {
      if (
        CityGroupingService.isValidGrouping({
          citiesByKey,
          groupKeyOrCityKey,
          groupedCityKey,
          groupsByKey,
          prefectureKey,
          prefecturesByKey,
        })
      ) {
        if (isCityGroupingEnabled) {
          return {
            cityKey: groupedCityKey,
            groupKey: groupKeyOrCityKey,
            isCityGroupingEnabled,
          };
        }

        // grouping is not enabled so redirect to prefecture-level
        return {
          redirectTo: 'prefecture',
        };
      }
    } catch (_err) {
      return {
        notFound: true,
      };
    }

    if (isCityGroupingEnabled && cityKeys.has(groupKeyOrCityKey)) {
      // grouping is enabled but a city was provided in the path at the group level. if the city belongs to the given
      // prefecture, redirect to the appropriate group/city page. If the city does not belong to the given prefecture,
      // a 404 error would be appropriate.
      try {
        const resolvedGroupKey = CityGroupingService.getGroupKeyForCityAndPrefecture({
          citiesByKey,
          cityKey: groupKeyOrCityKey,
          groupKeysById,
          prefectureKey,
          prefecturesByKey,
        });

        if (resolvedGroupKey) {
          return {
            cityKey: groupKeyOrCityKey,
            groupKey: resolvedGroupKey,
            isCityGroupingEnabled,
            redirectTo: 'city',
          };
        }

        // city is valid but does not belong to a group
        return {
          cityKey: groupKeyOrCityKey,
          isCityGroupingEnabled,
        };
      } catch (_err) {
        // city does not belong to the given prefecture
        return {
          notFound: true,
        };
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (groupedCityKey && !citiesByKey[groupedCityKey]) {
      // invalid city
      return {
        notFound: true,
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (groupsByKey[groupKeyOrCityKey]) {
      return {
        redirectTo: 'prefecture',
      };
    }

    // group wasn't provided or a city was provided that shouldn't be grouped. assume value provided via the group-level
    // path param is a city and pass it on.
    return {
      cityKey: groupKeyOrCityKey,
      isCityGroupingEnabled,
    };
  }

  /**
   * Resolves with true if grouping is enabled for the given jobTypeId
   *
   * @param jobTypeId
   * @private
   */
  private async isGroupingEnabledForJobType(jobTypeId: string): Promise<boolean> {
    const enabledJobTypes = await this.featureFlagsService.getGroupingEnabledJobTypes();
    return enabledJobTypes.includes(jobTypeId);
  }

  /**
   * Sync version of isGroupingEnabledForJobType
   * @param jobTypeId
   * @returns {boolean}
   */
  public isGroupingEnabledForJobTypeSync(jobTypeId: string): boolean {
    return this.groupingEnabledJobTypesSet.has(jobTypeId);
  }

  /**
   * Hydrates the groupingEnabledJobTypesSet with the enabled job types from the feature flags service
   * Ran on first request, cached for subsequent requests
   *
   * @returns {Promise<void>}
   */
  public async loadEnabledCityGroupingJobTypes(): Promise<void> {
    if (this.groupingEnabledJobTypesSet.size > 0) {
      return;
    }
    const enabledJobTypes = await this.featureFlagsService.getGroupingEnabledJobTypes();
    this.groupingEnabledJobTypesSet = new Set(enabledJobTypes);
  }

  /**
   * Loads groups and cities from the API and resolves with:
   * - set of group keys
   * - map of group keys by city key
   *
   * @private
   */
  private static async getLocationData(): Promise<LocationData> {
    const [{ data: locationCities }, { data: locationCityGroups }, { data: locationPrefectures }] =
      await Promise.all([
        HttpClient.get<Array<LocationCity>>(LOCATION_CITIES_PATH),
        HttpClient.get<Array<LocationCityGroup>>(LOCATION_CITY_GROUPS_PATH),
        HttpClient.get<Array<LocationPrefecture>>(LOCATION_PREFECTURES_PATH),
      ]);

    const citiesByKey = locationCities.reduce<CitiesByKey>((cities, city) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      cities[city.key] = cities[city.key] ?? [];
      cities[city.key].push(city);
      return cities;
    }, {});

    const prefecturesByKey = locationPrefectures.reduce<PrefecturesByKey>(
      (prefectures, prefecture) => {
        prefectures[prefecture.key] = prefecture;
        return prefectures;
      },
      {}
    );

    const groupsByKey = locationCityGroups.reduce<GroupsByKey>((groups, group) => {
      groups[group.cityGroupKey] = group;
      return groups;
    }, {});

    const groupKeysById = locationCityGroups.reduce<GroupKeysById>((groupKeys, group) => {
      groupKeys[group._id] = group.cityGroupKey;
      return groupKeys;
    }, {});

    const groupKeys = new Set(locationCityGroups.map((group) => group.cityGroupKey));
    const cityKeys = new Set(locationCities.map((city) => city.key));

    const groupKeysByCityKey = new Map();

    for (const city of locationCities) {
      if (groupKeysById[city.cityGroup]) {
        groupKeysByCityKey.set(city.key, groupKeysById[city.cityGroup]);
      }
    }

    return {
      citiesByKey,
      cityKeys,
      groupKeys,
      groupKeysByCityKey,
      groupKeysById,
      groups: locationCityGroups,
      groupsByKey,
      prefectures: locationPrefectures,
      prefecturesByKey,
    };
  }

  /**
   * Get city group name based on city group key
   *
   * @param cityGroupKey
   * @param language
   */
  public async getCityGroupNameByKey(
    cityGroupKey: string,
    language?: 'en' | 'ja'
  ): Promise<string> {
    const cityGroup = (await this.locationDataCache.get(CITY_GROUPS_CACHE_KEY)).groups.find(
      ({ cityGroupKey: key }) => key === cityGroupKey
    );
    if (cityGroup) {
      return language === 'en' ? cityGroup.nameEn : cityGroup.nameJa;
    }
    return '';
  }

  private static getGroupKeyForCityAndPrefecture({
    citiesByKey,
    cityKey,
    groupKeysById,
    prefectureKey,
    prefecturesByKey,
  }: {
    citiesByKey: CitiesByKey;
    cityKey: string;
    groupKeysById: GroupKeysById;
    prefectureKey: string;
    prefecturesByKey: PrefecturesByKey;
  }): string | void {
    const cities = citiesByKey[cityKey];
    const prefecture = prefecturesByKey[prefectureKey];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!prefecture || !cities) {
      return;
    }

    for (const city of cities) {
      if (city.prefecture === prefecture._id) {
        return groupKeysById[city.cityGroup];
      }
    }

    throw new Error('City does not belong to the given prefecture');
  }

  private static isValidGrouping({
    citiesByKey,
    groupKeyOrCityKey,
    groupedCityKey,
    groupsByKey,
    prefectureKey,
    prefecturesByKey,
  }: {
    citiesByKey: CitiesByKey;
    groupKeyOrCityKey: string;
    groupedCityKey?: string;
    groupsByKey: GroupsByKey;
    prefectureKey: string;
    prefecturesByKey: PrefecturesByKey;
  }): boolean {
    const prefecture = prefecturesByKey[prefectureKey];
    const group = groupsByKey[groupKeyOrCityKey];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!prefecture || !group) {
      return false;
    }

    // if there's a city key, need to locate the city ID and make sure that city belongs to the requested group, and that the city belongs to the prefecture
    if (groupedCityKey) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const cities = citiesByKey[groupedCityKey] ?? [];
      if (
        cities.some((city) => city.prefecture === prefecture._id && city.cityGroup === group._id)
      ) {
        return true;
      }
    }

    if (group.prefecture === prefecture._id) {
      return true;
    }

    throw new Error('Grouped city does not belong to the given prefecture');
  }

  static isGroupingEnabledForPageType(pageType: string) {
    return (
      pageType === VerticalPageTypes.GROUP_CITY_PAGE || pageType === VerticalPageTypes.GROUP_PAGE
    );
  }

  // is group valid? (needs prefecture and group)
  // is grouping valid? (needs prefecture group and city)

  // types of URLs
  // prefecture only
  // group --> is it a group or old-style city?
  // group/city
}

const cityGroupingService = new CityGroupingService();
export { cityGroupingService };
