import _ from 'lodash';

export const notificationActions = {
  APPLY_REFUNDED: 'applyRefunded',
  APPLY_REFUNDED_DUE_TO_SPY: 'applyRefundedDueToSpy',
  APPLY_REQUEST: 'applyRequest',
  APPROVE_APPLY: 'approveApply',
  CANCEL_JOB: 'cancelJob',
  CANCEL_JOB_OF_SPY_REQUEST: 'cancelJobOfSpyRequest',
  CHANGE_APPLY_COVER_LETTER: 'changeApplyCoverLetter',
  CHANGE_APPLY_QUOTE: 'changeApplyQuote',
  CHANGE_APPLY_QUOTE_COVER_LETTER: 'changeApplyQuoteCoverLetter',
  CLIENT_READ_APPLY: 'clientReadApply',
  CUSTOM: 'custom',
  ID_APPROVED: 'idApproved',
  JOB_COMPLETE: 'jobComplete',
  MISSION_COMPLETE: 'missionComplete',
  NEW_REQUEST: 'newRequest',
  PRO_FEEDBACK: 'proFeedback',
  PRO_PROFILE_VIEWED: 'proProfileViewed',
  PRO_SOLICIT_FEEDBACK: 'proSolicitFeedback',
  RANK_PROMOTED: 'rankPromoted',
  REFEREE_REWARD: 'refereeReward',
  REFERRER_REWARD: 'referrerReward',
  REJECT_APPLY: 'rejectApply',
  REPLY_APPLY: 'replyApply',
  REPLY_JOB: 'replyJob',
  REPLY_REQUEST: 'replyRequest',
  REQUEST_COMPLETE: 'requestComplete',
};

export function getNotificationNavigation(language, notification) {
  let path = '';
  const url = `${language === 'en' ? '/en' : ''}`;
  const applyId = _.get(notification, 'data.applyId');
  const requestId = _.get(notification, 'data.requestId');
  switch (notification.action) {
    case notificationActions.APPLY_REQUEST:
      path = `/dashboard/request/${requestId}/apply/${applyId}?activePage=message`;
      break;
    case notificationActions.REPLY_REQUEST:
    case notificationActions.REQUEST_COMPLETE: // @deprecated - in favor of REPLY_APPLY
    case notificationActions.PRO_SOLICIT_FEEDBACK:
      path = `/request/${requestId}`;
      break;

    case notificationActions.REPLY_APPLY:
      path = `/request/${requestId}/apply/${applyId}?activePage=message`;
      break;

    case notificationActions.CHANGE_APPLY_QUOTE:
    case notificationActions.CHANGE_APPLY_QUOTE_COVER_LETTER:
    case notificationActions.CHANGE_APPLY_COVER_LETTER:
      path = `/apply-detail/${applyId}`;
      break;

    case notificationActions.NEW_REQUEST:
      path = `/request-detail/${requestId}`;
      break;
    case notificationActions.PRO_FEEDBACK:
      path = '/dashboard/pro';
      break;
    case notificationActions.ID_APPROVED:
      // TODO: should navigate to general pro profile page (comment from Angular version)
      path = '/dashboard/pro';
      break;
    case notificationActions.CLIENT_READ_APPLY:
    case notificationActions.APPROVE_APPLY:
    case notificationActions.JOB_COMPLETE:
    case notificationActions.REJECT_APPLY:
    case notificationActions.CANCEL_JOB:
    case notificationActions.CANCEL_JOB_OF_SPY_REQUEST:
    case notificationActions.PRO_PROFILE_VIEWED:
    case notificationActions.APPLY_REFUNDED:
    case notificationActions.APPLY_REFUNDED_DUE_TO_SPY:
      path = `/request-detail/${requestId}`;
      break;
    case notificationActions.REPLY_JOB:
      path = `/request-detail/${requestId}/apply-detail?activePage=message`;
      break;
    case notificationActions.REFERRER_REWARD:
    case notificationActions.REFEREE_REWARD:
      path = '/billing';
      break;
    case notificationActions.MISSION_COMPLETE:
    case notificationActions.RANK_PROMOTED:
      path = '/missions';
      break;
    default:
      break;
  }
  return `${url}${path}`;
}

export function isClientNotification(notification = {}) {
  const clientTypes = [
    notificationActions.APPLY_REQUEST,
    notificationActions.CHANGE_APPLY_COVER_LETTER,
    notificationActions.CHANGE_APPLY_QUOTE,
    notificationActions.CHANGE_APPLY_QUOTE_COVER_LETTER,
    notificationActions.REPLY_APPLY,
    notificationActions.REPLY_REQUEST,
    notificationActions.REQUEST_COMPLETE,
  ];

  return clientTypes.includes(notification.action);
}

export function isProNotification(notification = {}) {
  const proTypes = [
    notificationActions.APPLY_REFUNDED,
    notificationActions.APPLY_REFUNDED_DUE_TO_SPY,
    notificationActions.APPROVE_APPLY,
    notificationActions.CANCEL_JOB,
    notificationActions.CANCEL_JOB_OF_SPY_REQUEST,
    notificationActions.CUSTOM,
    notificationActions.CLIENT_READ_APPLY,
    notificationActions.ID_APPROVED,
    notificationActions.JOB_COMPLETE,
    notificationActions.MISSION_COMPLETE,
    notificationActions.NEW_REQUEST,
    notificationActions.PRO_FEEDBACK,
    notificationActions.PRO_PROFILE_VIEWED,
    notificationActions.RANK_PROMOTED,
    notificationActions.REFEREE_REWARD,
    notificationActions.REFERRER_REWARD,
    notificationActions.REJECT_APPLY,
    notificationActions.REPLY_JOB,
  ];

  return proTypes.includes(notification.action);
}
