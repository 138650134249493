import httpClient, { getLanguageHeader } from 'src/http-client';

// TODO remove data transformation
import { transformJobType } from 'src/utils/data-transform/job-type';
import { JobType } from 'modules/job-types/types';
import type { CanonicalDetailedJobType } from 'src/modules/job-types/types';

/**
 * A transformed version of the job type should not be the canonical "get" function in a JobType module.
 * Eventually we should have a "get" function here that returns the raw data from the API and apply
 * transformations
 *
 * @deprecated
 */
export function get(id: string, language: string, format?: string) {
  const option = {
    headers: getLanguageHeader(language),
    params: { format },
  };
  return httpClient
    .get(`/job-types/${id}`, option)
    .then((response) => transformJobType(response.data));
}

export function getById(id: string, language: string, format?: string) {
  const option = {
    headers: getLanguageHeader(language),
    params: { format },
  };
  return httpClient
    .get(`/job-types/${id}`, option)
    .then((response) => response.data as CanonicalDetailedJobType);
}

export function getWithFormTransform(id: string, language: string, format?: string) {
  return getById(id, language, format).then((response) => transformJobType(response));
}

export function list(query: { proSignUp: boolean } | { clientRequest: boolean }, language: string) {
  const option = {
    headers: getLanguageHeader(language),
    params: query,
  };
  return httpClient.get<Array<JobType>>('/job-types', option).then((response) => {
    const { data } = response;
    return data;
  });
}

export function listByCategoryId(id: string) {
  return httpClient
    .get<Array<JobType>>(`/job-types/get-requestable-by-category/${id}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export function listPopular(
  {
    categoryId,
    cityKey,
    prefectureKey,
    limit,
  }: Partial<{ categoryId: string; cityKey: string; limit: string; prefectureKey: string }>,
  language: string
) {
  const option = {
    headers: getLanguageHeader(language),
    params: { categoryId, cityKey, limit, prefectureKey },
  };

  return httpClient.get<Array<JobType>>('/job-types/popular', option).then((response) => {
    const { data } = response;
    return data;
  });
}

export async function loadRelated(
  {
    jobTypeId,
    prefectureId,
    requestableOnly = false,
  }: { jobTypeId: string; prefectureId?: string; requestableOnly?: boolean },
  language: string
) {
  const option = {
    headers: getLanguageHeader(language),
  };

  let url = `/job-types/${jobTypeId}/related-${requestableOnly ? 'requestable-' : ''}job-types`;

  if (prefectureId) url += `?prefectureKey=${prefectureId}`;

  const res = await httpClient.get<Array<JobType>>(url, option);
  const { data } = res;

  return {
    id: jobTypeId,
    related: data,
  };
}

export async function loadProInfo(id: string, language = 'ja') {
  const config = { headers: getLanguageHeader(language) };

  const [
    {
      data: { name },
    },
    {
      data: { url: avatarUrl },
    },
  ] = await Promise.all([
    httpClient.get<{ name: string }>(`/users/${id}/name`, config),
    httpClient.get<{ url: string }>(`/users/${id}/avatar`, config),
  ]);

  return {
    avatarUrl,
    name,
  };
}

export async function loadJobTypeWithFirstPro(jobTypeId: string, language = 'ja', format?: string) {
  const jobType = await get(jobTypeId, language, format);
  const { landingPagePros = [] } = jobType as {
    landingPagePros?: Array<{
      description: string;
      id: string;
      subtitle: string;
    }>;
  };

  if (landingPagePros.length > 0) {
    // Only fetch first pro for now
    const [{ id: proId }] = landingPagePros;
    try {
      const firstPro = await loadProInfo(proId, language);
      return {
        ...jobType,
        landingPagePros: [{ ...landingPagePros[0], ...firstPro }, ...landingPagePros.slice(1)],
      };
    } catch (_error) {
      // Error getting pro info, ignoring
    }
  }
  return jobType;
}

export function listServiceOfTheMonth() {
  return httpClient
    .get<Array<JobType>>('/job-types/service-of-the-month')
    .then((response) => response.data);
}
