export const LIST = 'zehitomo/job-categories/LIST';
export const LIST_SUCCESS = 'zehitomo/job-categories/LIST_SUCCESS';
export const LIST_ERROR = 'zehitomo/job-categories/LIST_ERROR';

export const GET_DETAIL = 'zehitomo/job-categories/DETAIL';
export const GET_DETAIL_SUCCESS = 'zehitomo/job-categories/DETAIL_SUCCESS';
export const GET_DETAIL_ERROR = 'zehitomo/job-categories/DETAIL_ERROR';

export const GET_CATEGORY_TREE = 'zehitomo/job-categories/GET_CATEGORY_TREE';
export const GET_CATEGORY_TREE_SUCCESS = 'zehitomo/job-categories/GET_CATEGORY_TREE_SUCCESS';
export const GET_CATEGORY_TREE_ERROR = 'zehitomo/job-categories/GET_CATEGORY_TREE_ERROR';

export const GET_ALL_C1_CATEGORY_TREE = 'zehitomo/job-categories/GET_ALL_C1_CATEGORY_TREE';
export const GET_ALL_C1_CATEGORY_TREE_SUCCESS =
  'zehitomo/job-categories/GET_ALL_C1_CATEGORY_TREE_SUCCESS';
export const GET_ALL_C1_CATEGORY_TREE_ERROR =
  'zehitomo/job-categories/GET_ALL_C1_CATEGORY_TREE_ERROR';
