import * as Sentry from '@sentry/nextjs';
import LoadingCache from 'utils/loading-cache';
import HttpClient from 'src/http-client';

import type { JobTypeAlias } from './types';

const JOBTYPE_ALIASES_PATH = '/job-type-alias';

// eslint-disable-next-line no-magic-numbers
const JOBTYPE_ALIASES_CACHE_TTL = 1000 * 60 * 5;

export default class JobTypeAliasesService {
  private static instance: JobTypeAliasesService;

  private jobTypesAliasCache: LoadingCache<string, JobTypeAlias>;

  constructor() {
    this.jobTypesAliasCache = new LoadingCache({
      loader: async (key: string) => JobTypeAliasesService.getJobTypeAlias(key),
      maxAge: JOBTYPE_ALIASES_CACHE_TTL,
    });
  }

  /**
   * Returns the singleton instance of JobTypeAliasesService
   */
  static getInstance() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!JobTypeAliasesService.instance) {
      JobTypeAliasesService.instance = new JobTypeAliasesService();
    }
    return JobTypeAliasesService.instance;
  }

  public async getAlias(jobTypeId: string, withNoCache = false) {
    try {
      if (withNoCache) {
        const result = JobTypeAliasesService.getJobTypeAlias(jobTypeId);
        return result;
      }
      const cachedPayload = this.jobTypesAliasCache.get(jobTypeId);
      return cachedPayload;
    } catch (error) {
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error(`could not get JobtypeAlias ${jobTypeId}`, error);
      return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public async getJobTypeAliases() {
    const result = JobTypeAliasesService.getJobTypeAliases();
    return result;
  }

  /**
   * Gets JobType Alias object from the API
   *
   * @param key
   * @private
   */
  private static async getJobTypeAlias(key: string) {
    const response = await HttpClient.get<JobTypeAlias>(`${JOBTYPE_ALIASES_PATH}/${key}`);
    return response.data;
  }
  /**
   * Gets JobType Aliases objects from the API
   *
   * @param key
   * @private
   */
  private static async getJobTypeAliases() {
    const response = await HttpClient.get<[JobTypeAlias]>(`${JOBTYPE_ALIASES_PATH}`);
    return response.data;
  }
}
