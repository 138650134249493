import httpClient, { getLanguageHeader } from 'src/http-client';

export function list(language = 'ja') {
  const option = {
    headers: getLanguageHeader(language),
  };
  return httpClient.get('/job-categories', option).then((response) => {
    const { data } = response;

    // TODO remove data transformation
    return data;
  });
}

export function getDetail(categoryId, language = 'ja') {
  const option = {
    headers: getLanguageHeader(language),
  };
  return httpClient.get(`/job-categories/${categoryId}`, option).then((response) => response.data);
}

export function getCategoryTree(categoryId) {
  return httpClient
    .get(`/job-categories/${categoryId}/get-all-subcategories-by-parent`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export function getAllC1CategoryTree() {
  return httpClient.get(`/job-categories/get-all-subcategories`).then((response) => {
    const { data } = response;
    return data;
  });
}
