export const GET_CITY = 'zehitomo/locations/GET_CITY';
export const GET_CITY_SUCCESS = 'zehitomo/locations/GET_CITY_SUCCESS';
export const GET_CITY_ERROR = 'zehitomo/locations/GET_CITY_ERROR';

export const GET_GEOLOCATION = 'zehitomo/locations/GET_GEOLOCATION';
export const GET_GEOLOCATION_SUCCESS = 'zehitomo/locations/GET_GEOLOCATION_SUCCESS';
export const GET_GEOLOCATION_ERROR = 'zehitomo/locations/GET_GEOLOCATION_ERROR';

export const GET_PREFECTURE = 'zehitomo/locations/GET_PREFECTURE';
export const GET_PREFECTURE_SUCCESS = 'zehitomo/locations/GET_PREFECTURE_SUCCESS';
export const GET_PREFECTURE_ERROR = 'zehitomo/locations/GET_PREFECTURE_ERROR';

export const LIST_CITIES = 'zehitomo/locations/LIST_CITIES';
export const LIST_CITIES_SUCCESS = 'zehitomo/locations/LIST_CITIES_SUCCESS';
export const LIST_CITIES_ERROR = 'zehitomo/locations/LIST_CITIES_ERROR';

export const LIST_CITY_GROUPS = 'zehitomo/locations/LIST_CITY_GROUPS';
export const LIST_CITY_GROUPS_SUCCESS = 'zehitomo/locations/LIST_CITY_GROUPS_SUCCESS';
export const LIST_CITY_GROUPS_ERROR = 'zehitomo/locations/LIST_CITY_GROUPS_ERROR';

export const LIST_PREFECTURES = 'zehitomo/locations/LIST_PREFECTURES';
export const LIST_PREFECTURES_SUCCESS = 'zehitomo/locations/LIST_PREFECTURES_SUCCESS';
export const LIST_PREFECTURES_ERROR = 'zehitomo/locations/LIST_PREFECTURES_ERROR';

export const RESET_LIST_CITIES = 'zehitomo/locations/RESET_LIST_CITIES';
