/**
 * TODO(micah): Move *GENERIC* cookie functions into this module from the oauth dir
 * to this directory.
 *
 * @module Cookies
 */

import { getCookieByKey, setCookie, deleteCookieByKey } from '../oauth/cookies';

export { getCookieByKey, setCookie, deleteCookieByKey };
export default { getCookieByKey, setCookie, deleteCookieByKey };
