import {
  GET_ALL_C1_CATEGORY_TREE_SUCCESS,
  GET_CATEGORY_TREE_SUCCESS,
  GET_DETAIL_SUCCESS,
  LIST_SUCCESS,
} from './types';

const initialState = {
  c1CategoryTree: [],
  detail: {},
  list: [],
  tags: [],
  tree: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_C1_CATEGORY_TREE_SUCCESS:
      return {
        ...state,
        c1CategoryTree: action.payload,
      };

    case GET_CATEGORY_TREE_SUCCESS:
      return {
        ...state,
        tree: action.payload,
      };

    case GET_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.payload,
      };

    case LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };

    default:
      return state;
  }
}
