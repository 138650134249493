export const removeUndefinedProperties = <T>(object: T): T =>
  Object.fromEntries(Object.entries(object).filter(([, value]) => value !== undefined)) as T;

/**
 * Removes empty object properties from the main object.
 * Returns a new object without modifying the current one.
 * @param {Record<string, any>} obj - The object to clean.
 * @returns {Record<string, any>} The cleaned object.
 */
export const removeEmptySubObjects = (obj: Record<string, any>): Record<string, any> => {
  const newObj: Record<string, any> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (!(typeof obj[key] === 'object' && obj[key] !== null && Object.keys(obj[key]).length === 0)) {
        newObj[key] = obj[key];
      }
    }
  }

  return newObj;
};