import { createLogic } from 'redux-logic';

import { getItem as getLSItem, removeItem as removeLSItem } from 'utils/localStorage';
import { removeItem } from 'utils/webStorage';

import { closeSocket, registerSocketEvent, setupSocket } from 'modules/socket/duck';
import { removeHeaders, setHeaders } from 'src/http-client';

import { clearNotifications } from './actions';
import { timestampSelector } from './selectors';

import {
  AUTH_USER,
  AUTH_USER_ERROR,
  AUTH_USER_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
  LIST_ANNOUNCEMENTS,
  LIST_ANNOUNCEMENTS_ERROR,
  LIST_ANNOUNCEMENTS_SUCCESS,
  LIST_NOTIFICATIONS,
  LIST_NOTIFICATIONS_ERROR,
  LIST_NOTIFICATIONS_SUCCESS,
  MARK_A_SINGLE_ANNOUNCEMENT,
  MARK_A_SINGLE_ANNOUNCEMENT_ERROR,
  MARK_A_SINGLE_ANNOUNCEMENT_SUCCESS,
  MARK_A_SINGLE_NOTIFICATION,
  MARK_A_SINGLE_NOTIFICATION_ERROR,
  MARK_A_SINGLE_NOTIFICATION_SUCCESS,
  MARK_ALL_ANNOUNCEMENTS,
  MARK_ALL_ANNOUNCEMENTS_ERROR,
  MARK_ALL_ANNOUNCEMENTS_SUCCESS,
  MARK_ALL_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_ERROR,
  MARK_ALL_NOTIFICATIONS_SUCCESS,
  SIGN_OUT,
  SIGN_OUT_ERROR,
  SIGN_OUT_SUCCESS,
} from './types';

import {
  auth,
  changePassword,
  listAnnouncements,
  listNotifications,
  markAllAnnouncements,
  markAllNotifications,
  markASingleAnnouncement,
  markASingleNotification,
} from '../service';

import { updateLastOnline } from '../modules/auth/service';

const notificationEvents = [
  {
    action: GET_NOTIFICATION_SUCCESS,
    name: 'userNotifications/new',
  },
  {
    action: MARK_A_SINGLE_NOTIFICATION_SUCCESS,
    name: 'userNotifications/markAsRead',
  },
  {
    action: MARK_ALL_NOTIFICATIONS_SUCCESS,
    name: 'userNotifications/markAllAsRead',
  },
];

const authLogic = createLogic({
  async process({ action }, dispatch, done) {
    const { token, uid } = action.payload;
    let res;
    try {
      res = await auth(uid, token);
    } catch (err) {
      done();
      throw err;
    }
    setHeaders({ token });
    updateLastOnline(uid);
    dispatch({
      payload: res,
      type: AUTH_USER_SUCCESS,
    });
    dispatch(setupSocket());
    dispatch({ type: LIST_NOTIFICATIONS });
    dispatch({ type: LIST_ANNOUNCEMENTS });
    notificationEvents.forEach((event) => dispatch(registerSocketEvent(event)));
    done();
    return Promise.resolve(res);
  },
  processOptions: {
    failType: AUTH_USER_ERROR,
  },
  transform({ action }, next, reject) {
    const storedAuthData = getLSItem('ngStorage-authData');
    if (storedAuthData) {
      const authData = JSON.parse(storedAuthData);
      const { token, uid, expires } = authData;
      const now = new Date().valueOf();

      if (expires && expires < now) {
        return next({
          payload: {},
          type: SIGN_OUT,
        });
      } else if (token && uid) {
        return next({
          ...action,
          payload: {
            token,
            uid,
          },
        });
      }
    }

    // reject if there's no authData in localStorage
    return reject();
  },
  type: AUTH_USER,
});

const changePasswordLogic = createLogic({
  process({ action }) {
    const { id, password, token } = action.payload;
    return changePassword(id, token, password);
  },
  processOptions: {
    failType: CHANGE_PASSWORD_ERROR,
    successType: CHANGE_PASSWORD_SUCCESS,
  },
  type: CHANGE_PASSWORD,
  validate({ action }, allow, reject) {
    // reject if there is no payload or missing one of the params
    if (
      !action.payload ||
      !action.payload.id ||
      !action.payload.password ||
      !action.payload.token
    ) {
      return reject();
    }

    return allow(action);
  },
});

const listNotificationsLogic = createLogic({
  process({ action }) {
    const { userId } = action.payload;
    return listNotifications(userId);
  },
  processOptions: {
    failType: LIST_NOTIFICATIONS_ERROR,
    successType: LIST_NOTIFICATIONS_SUCCESS,
  },
  transform({ action }, next, reject) {
    const storedAuthData = getLSItem('ngStorage-authData');
    if (storedAuthData) {
      const authData = JSON.parse(storedAuthData);
      const { uid } = authData;
      if (uid) {
        return next({
          ...action,
          payload: {
            userId: uid,
          },
        });
      }
    }

    // reject if there's no authData in localStorage or language is not changed
    return reject();
  },
  type: LIST_NOTIFICATIONS,
});

const markAllNotificationsLogic = createLogic({
  process({ action }) {
    return markAllNotifications(action.payload.timestamp);
  },
  processOptions: {
    failType: MARK_ALL_NOTIFICATIONS_ERROR,
    successType: MARK_ALL_NOTIFICATIONS_SUCCESS,
  },
  transform({ action, getState }, next, reject) {
    const timestamp = timestampSelector(getState());
    // reject if can't retrieve latest timestamp
    if (!timestamp) {
      reject();
    }

    return next({
      ...action,
      payload: {
        timestamp,
      },
    });
  },
  type: MARK_ALL_NOTIFICATIONS,
});

const markASingleNotificationLogic = createLogic({
  process({ action }) {
    return markASingleNotification(action.payload.notificationId);
  },
  processOptions: {
    failType: MARK_A_SINGLE_NOTIFICATION_ERROR,
    successType: MARK_A_SINGLE_NOTIFICATION_SUCCESS,
  },
  type: MARK_A_SINGLE_NOTIFICATION,
  validate({ action }, allow, reject) {
    // reject if there's no notificationId provided
    if (!action.payload || !action.payload.notificationId) {
      return reject();
    }

    return allow(action);
  },
});

const listAnnouncementsLogic = createLogic({
  process({ action }) {
    const { userId } = action.payload;
    return listAnnouncements(userId);
  },
  processOptions: {
    failType: LIST_ANNOUNCEMENTS_ERROR,
    successType: LIST_ANNOUNCEMENTS_SUCCESS,
  },
  transform({ action }, next, reject) {
    const storedAuthData = getLSItem('ngStorage-authData');
    if (storedAuthData) {
      const authData = JSON.parse(storedAuthData);
      const { uid } = authData;
      if (uid) {
        return next({
          ...action,
          payload: {
            userId: uid,
          },
        });
      }
    }

    // reject if there's no authData in localStorage or language is not changed
    return reject();
  },
  type: LIST_ANNOUNCEMENTS,
});

const markASingleAnnouncementLogic = createLogic({
  process({ action }) {
    const { userId, announcementId } = action.payload;
    return markASingleAnnouncement(userId, announcementId);
  },
  processOptions: {
    failType: MARK_A_SINGLE_ANNOUNCEMENT_ERROR,
    successType: MARK_A_SINGLE_ANNOUNCEMENT_SUCCESS,
  },
  type: MARK_A_SINGLE_ANNOUNCEMENT,
  validate({ action }, allow, reject) {
    if (!action.payload || !action.payload.userId || !action.payload.announcementId) {
      return reject();
    }

    return allow(action);
  },
});

const markAllAnnouncementsLogic = createLogic({
  process({ action }) {
    return markAllAnnouncements(action.payload.userId);
  },
  processOptions: {
    failType: MARK_ALL_ANNOUNCEMENTS_ERROR,
    successType: MARK_ALL_ANNOUNCEMENTS_SUCCESS,
  },
  transform({ action }, next, reject) {
    const storedAuthData = getLSItem('ngStorage-authData');
    if (storedAuthData) {
      const authData = JSON.parse(storedAuthData);
      const { uid } = authData;
      if (uid) {
        return next({
          ...action,
          payload: {
            userId: uid,
          },
        });
      }
    }
    // reject if there's no authData in localStorage or language is not changed
    return reject();
  },
  type: MARK_ALL_ANNOUNCEMENTS,
});

const signOutLogic = createLogic({
  process(_, dispatch, done) {
    removeLSItem('ngStorage-authData');
    removeItem('ngStorage-utm');
    removeLSItem('proSignUpForm');
    removeLSItem('businessProfileForm');
    // ngStorage-currentUid can be set in z-web now so must clean up ngStorage-currentUid
    // to prevent runtime errors in z-web-angular
    removeLSItem('ngStorage-currentUid');
    removeHeaders(['token']);
    dispatch(closeSocket());
    dispatch(clearNotifications());
    dispatch({
      type: SIGN_OUT_SUCCESS,
    });
    done();
    return Promise.resolve({});
  },
  processOptions: {
    failType: SIGN_OUT_ERROR,
  },
  type: SIGN_OUT,
});

export default [
  authLogic,
  changePasswordLogic,
  listNotificationsLogic,
  markAllNotificationsLogic,
  markASingleNotificationLogic,
  listAnnouncementsLogic,
  markASingleAnnouncementLogic,
  markAllAnnouncementsLogic,
  signOutLogic,
];
