import _ from 'lodash';

import {
  CLOSE_SOCKET_SUCCESS,
  REGISTER_SOCKET_EVENT,
  REGISTER_SOCKET_EVENT_SUCCESS,
  SETUP_SOCKET_SUCCESS,
} from './types';

const initialState = { events: [], instance: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_SOCKET_SUCCESS:
      return { ...initialState };

    case REGISTER_SOCKET_EVENT:
      return { ...state, events: _.union(state.events, [action.payload.event]) };

    case REGISTER_SOCKET_EVENT_SUCCESS:
      return { ...state, events: _.without(state.events, action.payload.event) };

    case SETUP_SOCKET_SUCCESS:
      return { ...state, instance: action.payload };

    default:
      return state;
  }
};
export default reducer;
