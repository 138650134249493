import _ from 'lodash';
import { createSelector } from 'reselect';

import { isClientNotification, isProNotification } from '../utils';
import User from '../user';

const usersSelector = (state) => state.users.common;

export const clientNotificationsSelector = createSelector(usersSelector, (users) =>
  _.sortBy(
    Object.values(users.notifications).filter((notification) => isClientNotification(notification)),
    ['time', 'isRead']
  ).reverse()
);

export const currentRequestUserSelector = createSelector(
  usersSelector,
  (users) => users.currentRequestUser
);

export const currentUserSelector = createSelector(usersSelector, (users) => users.currentUser);

export const currentUserLanguagesSelector = createSelector(currentUserSelector, (user) =>
  _.get(user, 'profile.languages')
);

export const hasUnreadNotificationsSelector = createSelector(usersSelector, (users) =>
  _.some(users.notifications, (notif) => !notif.isRead)
);

export const proNotificationsSelector = createSelector(usersSelector, (users) =>
  _.sortBy(
    Object.values(users.notifications).filter((notification) => isProNotification(notification)),
    ['time', 'isRead']
  ).reverse()
);

export const announcementSelector = createSelector(usersSelector, (users) =>
  Object.values(users.announcements)
);

export const isAuthenticatedSelector = createSelector(
  usersSelector,
  (users) => users.isAuthenticated
);

export const timestampSelector = createSelector(usersSelector, (users) => users.timestamp);
/**
 * Gets the current user as a User object that defines various
 * helper methods.
 */
export const getCurrentUser = createSelector(currentUserSelector, (user) =>
  user ? new User(user) : null
);

export const getIsLoadingAuth = createSelector(
  usersSelector,
  (usersCommon) => usersCommon.isLoadingAuth
);
