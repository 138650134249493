import { createSelector } from 'reselect';

const categoriesSelector = (state) => state.jobCategories;
const categoryIdSelector = (state, props) => props.categoryId;

export const jobCategoriesSelector = createSelector(
  categoriesSelector,
  (categories) => categories.list
);

export const categoryTreeSelector = createSelector(
  categoriesSelector,
  (categories) => categories.tree
);

export const c1CategoryTreeSelector = createSelector(
  categoriesSelector,
  (categories) => categories.c1CategoryTree
);

export const jobCategorySelector = createSelector(
  jobCategoriesSelector,
  categoryIdSelector,
  (categories, id) => categories.find((category) => category.id === id)
);

export const jobCategoryDetailSelector = createSelector(
  categoriesSelector,
  (jobCategories) => jobCategories.detail
);
