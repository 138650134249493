import { getInOneLanguage } from 'src/http-client';
import { languageSelector } from 'modules/common/duck';

import {
  eventsSelector,
  registerSocketEventSuccess,
  socketInstanceSelector,
} from './duck';

export const handleAction = (dispatch, event, language) => data =>
  dispatch({
    type: event.action,
    payload: getInOneLanguage(data, language),
  });

export function eventsSubscriber({ dispatch, getState }) {
  const state = getState();
  const events = eventsSelector(state);
  const instance = socketInstanceSelector(state);
  const language = languageSelector(state);
  if (instance && events.length) {
    const event = events[0];

    instance.on(event.name, handleAction(dispatch, event, language));
    dispatch(registerSocketEventSuccess(event));
  }
}
