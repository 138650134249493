export const NUM_NEARBY_CITIES_PROS_PER_PAGE = 30;
export const NUM_NEARBY_CITIES_PROS_PER_PAGE_OVER_PAGE2 = 20;

export const REVIEW_ITEMS_PER_PAGE = 20;
export const REVIEW_MAX_PAGES_TO_DISPLAY = 5;
export const INITIAL_PAGE_NUMBER = 1;

export const LOCATION_SELECT_FIELDS_JOB_TYPE_LIST = [
  'garage-carport-professional',
  'garbage-disposal',
  'personal-chef',
  'manicurist',
  'sparekeyproduction',
];

export const INTRODUCING_CHARACTER_JOB_TYPE_LIST = [
  'bathroom-sink-reform-specialist',
  'roofconstruction',
  'home-exterior-construction',
  'garage-carport-professional',
  'japanese-to-western-room-reform-specialist',
  'tile-reform',
  'toilet-reform-specialist',
  'wallpaper-wallcloth-reform-specialist',
  'window-remodeling',
  'window-film-application',
  'interior-designer',
  'sound-proofing',
  'constructionpainting',
  'demolitionwork-construction',
  'kitchen-and-bathroom-cleaning',
  'japanese-closet-door-replacement',
  'light-installation',
  'screen-door-replacement',
  'aerobics-instructor',
  'pilates-instructor',
  'stretching-instructor',
  'photography-instructor',
  'health-diet-cooking-instructor',
  'english-teacher',
  'private-tutor',
  'makeup-artist',
  'manicurist',
  'washing-machine-cleaner',
  'ikea-nitori-furniture-assembly',
  'handyman',
  'dog-therapist',
  'tax-accountant',
  'administrative-scrivener',
  'judicial-scrivener',
  'garbage-disposal',
  'industrial-waste-treatment',
  'photographer',
  'food-truck',
  'personal-chef',
  'event-florist',
];

export const BIG_5_JOB_TYPE_LIST = [
  'carpenter',
  'civil-construction-specialist',
  'design-renovation-specialist',
  'home-reform-specialist',
  'interiorfinishingconstruction',
];

export const VerticalPageTypes = {
  JOB_TYPE_PAGE: 'jobType' as const,
  PREFECTURE_PAGE: 'prefecture' as const,
  CITY_PAGE: 'city' as const,
  GROUP_PAGE: 'group' as const,
  MAJOR_CITY_PAGE: 'majorCity' as const,
  GROUP_CITY_PAGE: 'groupCity' as const,
  CATEGORY_PAGE: 'category' as const,
};

export type VerticalPageTypeValues = typeof VerticalPageTypes[keyof typeof VerticalPageTypes];
