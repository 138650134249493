import Analytics, { LogOptions } from './analytics';

/**
 * class to co-locate non dynamic analytics events related to the request form
 */
export default class RequestFormAnalytics {
  private constructor() {
    throw new Error('Not implemented');
  }

  /**
   * Denotes the intent to open the request form. It can be done via a CTA click or some interaction
   * that indirectly opens the RF.
   */
  static startNewRequestIntent(jobTypeId: string, logOptions?: LogOptions) {
    setTimeout(() => {
      Analytics.logGA(
        {
          category: Analytics.makeRequestCategoryString(jobTypeId),
          action: 'startNewRequestIntent',
        },
        logOptions
      );
    }, 0);
  }
}
