import _ from 'lodash';

import {
  CheckboxesField,
  CounterField,
  Field,
  ImageField,
  RadioField,
  Step,
} from 'modules/common/form-builder/form-builder';
import type { DetailedJobTypeField } from 'modules/job-types/types';
import { removeUndefinedForNextJsSerializing } from './next-undefined';

/**
 * single choice -> list radios: same name, diff value + label + imageUrl
 * multiple choice -> list checkbox: diff name + value + label + imageUrl
 */
export function transformJobType(jobType: { fields?: Array<DetailedJobTypeField> }): {
  forms: Array<Step>;
} {
  const transformedJobType = {
    ...jobType,
    forms: transformFields(jobType.fields),
  };
  delete transformedJobType.fields;

  return transformedJobType;
}

function transformFields(fields?: Array<DetailedJobTypeField>): Array<Step> {
  return (
    fields?.map((field) =>
      removeUndefinedForNextJsSerializing({
        description: field.templateOptions.description,
        descriptionVariant: field.templateOptions.descriptionVariant,
        fields: generateFormFields(field),
        hideExpression: field.hideExpression,
        isMatchingCriteria: field.isMatchingCriteria,
        name: field.key,
        showInPricingSummary: field.showInPricingSummary,
        title: field.templateOptions.label,
      })
    ) ?? []
  );
}

const TEMPLATE_OPTIONS_PROPERTIES_TO_OMIT = ['description', 'descriptionVariant', 'label'];

function generateFormFields({
  defaultValue,
  key,
  matchingCriteriaConfig,
  templateOptions,
  type,
}: DetailedJobTypeField): Array<Field<any>> {
  switch (type) {
    case 'duration':
      return [
        {
          ..._.omit(templateOptions, TEMPLATE_OPTIONS_PROPERTIES_TO_OMIT),
          min: 0,
          name: `${key}.value`,
          range: true,
          required: true,
          type: 'number',
        },
        {
          defaultValue: templateOptions.unit,
          name: `${key}.unit`,
          required: true,
          type: 'hidden',
          value: templateOptions.unit,
        },
      ] as Array<Field<number>>;

    case 'timeslots':
      return [
        {
          name: key,
          required: templateOptions.required,
          slotCount: 3,
          type: 'timeslots',
        },
      ] as Array<Field<any>>;

    case 'counters':
      return templateOptions.options?.map(({ hideExpression, key: optionKey, max, min, title }) =>
        removeUndefinedForNextJsSerializing({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
          defaultValue: defaultValue?.[optionKey] ?? 0,
          hideExpression,
          label: title,
          max,
          min,
          name: `${key}.${optionKey}`,
          type: 'counter',
        })
      ) as Array<CounterField>;

    case 'radios':
    case 'radiosWithOther':
      return [
        {
          name: key,
          options: templateOptions.options?.map(
            ({ clientPayingPrice, duration, hideExpression, subtitle, title, value }) =>
              removeUndefinedForNextJsSerializing({
                clientPayingPrice,
                duration,
                hideExpression,
                label: title,
                subtitle,
                value,
              })
          ),
          otherField: templateOptions.otherField
            ? removeUndefinedForNextJsSerializing({
                hideExpression: templateOptions.otherField.hideExpression,
                name: templateOptions.otherField.key,
                trigger: templateOptions.otherField.triggeredValue,
              })
            : null,
          required: true,
          type: 'radio',
        },
      ] as Array<RadioField>;

    case 'checkboxes':
    case 'checkboxesWithOther':
      return [
        removeUndefinedForNextJsSerializing({
          matchingCriteriaConfig: matchingCriteriaConfig?.matching,
          name: key,
          options: templateOptions.options?.map(({ hideExpression, title, value }) =>
            removeUndefinedForNextJsSerializing({
              hideExpression,
              label: title,
              name: value,
              otherField: templateOptions.otherField
                ? removeUndefinedForNextJsSerializing({
                    hideExpression: templateOptions.otherField.hideExpression,
                    name: templateOptions.otherField.key,
                    trigger: templateOptions.otherField.triggeredValue,
                  })
                : null,
              type: 'checkbox',
            })
          ),
          required: templateOptions.required,
          type: 'checkboxes',
        }),
      ] as Array<CheckboxesField>;

    case 'services':
      return [
        {
          name: key,
          options: templateOptions.options?.map(
            ({ key: optionKey, max, min, title, unitPrice }) => ({
              label: title,
              max,
              min,
              name: optionKey,
              unitPrice,
            })
          ),
          required: templateOptions.required,
          type: 'services',
        },
      ] as Array<Field<number>>;

    case 'singleChoiceImages':
    case 'multipleChoiceImages':
      return [
        {
          label: templateOptions.label,
          multiple: type === 'multipleChoiceImages',
          name: key,
          options: templateOptions.options?.map(
            ({ clientPayingPrice, duration, imageUrl, title, value, description }) =>
              removeUndefinedForNextJsSerializing({
                clientPayingPrice,
                description,
                duration,
                imageUrl,
                label: title,
                name: value,
                value,
              })
          ),
          required: type === 'multipleChoiceImages' ? templateOptions.required : true,
          type: getType(type),
        },
      ] as Array<ImageField>;

    case 'textarea':
      return (
        templateOptions.options
          ? []
          : [
              removeUndefinedForNextJsSerializing({
                ..._.omit(templateOptions, TEMPLATE_OPTIONS_PROPERTIES_TO_OMIT),
                address: false,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                defaultValue,
                name: key,
                range: false,
                type: getType(type),
              }),
            ]
      ) as Array<Field<any>>;

    default:
      return (
        templateOptions.options
          ? []
          : [
              removeUndefinedForNextJsSerializing({
                ..._.omit(templateOptions, TEMPLATE_OPTIONS_PROPERTIES_TO_OMIT),
                address: type === 'address',
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                defaultValue,
                name: key,
                range: type === 'duration',
                required: true,
                type: getType(type),
              }),
            ]
      ) as Array<Field<any>>;
  }
}

function getType(type: string): string {
  switch (type) {
    case 'checkboxes':
    case 'checkboxesWithOther':
      return 'checkbox';

    case 'counters':
      return 'counter';

    case 'duration':
      return 'number';

    case 'email':
      return 'text';

    case 'multipleChoiceImages':
    case 'singleChoiceImages':
      return 'images';

    case 'radios':
    case 'radiosWithOther':
      return 'radio';

    default:
      return type;
  }
}
