import {
  GET_CITY_SUCCESS,
  GET_GEOLOCATION_SUCCESS,
  GET_PREFECTURE_SUCCESS,
  LIST_CITIES_SUCCESS,
  LIST_CITY_GROUPS_SUCCESS,
  LIST_PREFECTURES_SUCCESS,
  RESET_LIST_CITIES,
} from './types';

// we can hash by key since we would never load all cities at once
const initialStates = { cities: {}, cityGroups: {}, geoLocation: {}, prefectures: {} };

const reducers = (state = initialStates, action) => {
  switch (action.type) {
    case GET_CITY_SUCCESS:
      return { ...state, cities: { ...state.cities, [action.payload.key]: action.payload } };

    case GET_GEOLOCATION_SUCCESS: {
      const cityKey = action.payload.city.key;
      const prefectureKey = action.payload.prefecture.key;
      return {
        ...state,
        cities: { ...state.cities, [cityKey]: action.payload.city },
        geoLocation: { ...action.payload.location, cityKey, prefectureKey },
        prefectures: { ...state.prefectures, [prefectureKey]: action.payload.prefecture },
      };
    }

    case GET_PREFECTURE_SUCCESS:
      return {
        ...state,
        prefectures: { ...state.prefectures, [action.payload.key]: action.payload },
      };

    case LIST_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.payload.reduce(
          (hash, city) => ({ ...hash, [city.key]: city }),
          state.cities
        ),
      };

    case LIST_CITY_GROUPS_SUCCESS:
      return {
        ...state,
        cityGroups: action.payload.reduce(
          (hash, cityGroup) => ({ ...hash, [cityGroup.key]: cityGroup }),
          state.cityGroups
        ),
      };

    case LIST_PREFECTURES_SUCCESS:
      return {
        ...state,
        prefectures: action.payload.reduce(
          (hash, prefecture) => ({ ...hash, [prefecture.key]: prefecture }),
          state.prefectures
        ),
      };

    case RESET_LIST_CITIES:
      return { ...state, cities: {} };

    default:
      return state;
  }
};
export default reducers;
