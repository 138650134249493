import { createLogic } from 'redux-logic';

import { languageSelector } from 'modules/common/duck';

import { CLAIM_PROFILE, LOAD_PRO, LOAD_PRO_ERROR, LOAD_PRO_SUCCESS } from './types';
import { isValidItpProSelector, proSelector } from './selectors';

import { isAuthenticatedSelector } from '../../../duck';
import { claimItpProfile, loadPro } from '../../../service';

const claimProfileLogic = createLogic({
  type: CLAIM_PROFILE,
  async process({ action, getState }) {
    const state = getState();
    const pro = proSelector(state);

    await claimItpProfile(pro._id, action.payload.email); // eslint-disable-line
  },
  validate({ action, getState }, allow, reject) {
    const state = getState();

    const authenticated = isAuthenticatedSelector(state);
    if (authenticated) {
      return reject();
    }

    const validItp = isValidItpProSelector(state);
    if (!validItp) {
      return reject();
    }

    return allow(action);
  },
});

const loadProLogic = createLogic({
  type: LOAD_PRO,
  processOptions: {
    failType: LOAD_PRO_ERROR,
    successType: LOAD_PRO_SUCCESS,
  },
  process({ action, getState }) {
    const language = languageSelector(getState());
    return loadPro(action.payload.proId, language, action.payload.format);
  },
  validate({ action }, allow, reject) {
    // reject if there is no payload or no proId provided
    if (!action.payload || !action.payload.proId) {
      return reject();
    }

    return allow(action);
  },
});

export default [claimProfileLogic, loadProLogic];
