import type {
  BlogPost,
  LandingPagePro,
  ProBlogPost,
  ProFeedback,
  ProJobCategoriesForSignup,
} from '../marketing-page.types';
import type { PopularRequest } from 'modules/marketing-pages/service';
import type { CaseStudy } from '@z-components/organisms/CaseStudyCardProProfile/types';

export const GET_NEWEST_REVIEWS_BY_JOB_TYPE =
  'zehitomo/marketing-pages/GET_NEWEST_REVIEWS_BY_JOB_TYPE';
export const GET_NEWEST_REVIEWS_BY_JOB_TYPE_ERROR =
  'zehitomo/marketing-pages/GET_NEWEST_REVIEWS_BY_JOB_TYPE_ERROR';
export const GET_NEWEST_REVIEWS_BY_JOB_TYPE_SUCCESS =
  'zehitomo/marketing-pages/GET_NEWEST_REVIEWS_BY_JOB_TYPE_SUCCESS';
export const GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE =
  'zehitomo/marketing-pages/GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE';
export const GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_ERROR =
  'zehitomo/marketing-pages/GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_ERROR';
export const GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_SUCCESS =
  'zehitomo/marketing-pages/GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_SUCCESS';

export const GET_PRO_COUNT = 'zehitomo/marketing-pages/GET_PRO_COUNT';
export const GET_PRO_COUNT_ERROR = 'zehitomo/marketing-pages/GET_PRO_COUNT_ERROR';
export const GET_PRO_COUNT_SUCCESS = 'zehitomo/marketing-pages/GET_PRO_COUNT_SUCCESS';

export const LIST_BLOG_POSTS = 'zehitomo/marketing-pages/LIST_BLOG_POSTS';
export const LIST_BLOG_POSTS_ERROR = 'zehitomo/marketing-pages/LIST_BLOG_POSTS_ERROR';
export const LIST_BLOG_POSTS_SUCCESS = 'zehitomo/marketing-pages/LIST_BLOG_POSTS_SUCCESS';

export const LIST_BLOG_POSTS_BY_CATEGORY = 'zehitomo/marketing-pages/LIST_BLOG_POSTS_BY_CATEGORY';
export const LIST_BLOG_POSTS_BY_CATEGORY_ERROR =
  'zehitomo/marketing-pages/LIST_BLOG_POSTS_BY_CATEGORY_ERROR';
export const LIST_BLOG_POSTS_BY_CATEGORY_SUCCESS =
  'zehitomo/marketing-pages/LIST_BLOG_POSTS_BY_CATEGORY_SUCCESS';

export const LIST_PRO_BLOG_POSTS_BY_JOB_TYPE =
  'zehitomo/marketing-pages/LIST_PRO_BLOG_POSTS_BY_JOB_TYPE';
export const LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_ERROR =
  'zehitomo/marketing-pages/LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_ERROR';
export const LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_SUCCESS =
  'zehitomo/marketing-pages/LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_SUCCESS';

export const LIST_PROS = 'zehitomo/marketing-pages/LIST_PROS';
export const LIST_PROS_ERROR = 'zehitomo/marketing-pages/LIST_PROS_ERROR';
export const LIST_PROS_SUCCESS = 'zehitomo/marketing-pages/LIST_PROS_SUCCESS';

export const LIST_PROS_GROUP = 'zehitomo/marketing-pages/LIST_PROS_GROUP';
export const LIST_PROS_GROUP_ERROR = 'zehitomo/marketing-pages/LIST_PROS_GROUP_ERROR';
export const LIST_PROS_GROUP_SUCCESS = 'zehitomo/marketing-pages/LIST_PROS_GROUP_SUCCESS';

export const LIST_SAMPLE_REQUESTS = 'zehitomo/marketing-pages/LIST_SAMPLE_REQUESTS';
export const LIST_SAMPLE_REQUESTS_ERROR = 'zehitomo/marketing-pages/LIST_SAMPLE_REQUESTS_ERROR';
export const LIST_SAMPLE_REQUESTS_SUCCESS = 'zehitomo/marketing-pages/LIST_SAMPLE_REQUESTS_SUCCESS';

export const GET_POPULAR_PRICING_ITEMS = 'zehitomo/marketing-pages/GET_POPULAR_PRICING_ITEMS';
export const GET_POPULAR_PRICING_ITEMS_ERROR =
  'zehitomo/marketing-pages/GET_POPULAR_PRICING_ITEMS_ERROR';
export const GET_POPULAR_PRICING_ITEMS_SUCCESS =
  'zehitomo/marketing-pages/GET_POPULAR_PRICING_ITEMS_SUCCESS';

export const EXPAND_PRICING_ITEMS = 'zehitomo/marketing-pages/EXPAND_PRICING_ITEMS';

export const GET_CASE_STUDIES = 'zehitomo/marketing-pages/GET_CASE_STUDIES';
export const GET_CASE_STUDIES_ERROR = 'zehitomo/marketing-pages/GET_CASE_STUDIES_ERROR';
export const GET_CASE_STUDIES_SUCCESS = 'zehitomo/marketing-pages/GET_CASE_STUDIES_SUCCESS';

export const GET_CASE_STUDIES_BY_USER_ID = 'zehitomo/marketing-pages/GET_CASE_STUDIES_BY_USER_ID';
export const GET_CASE_STUDIES_BY_USER_ID_ERROR =
  'zehitomo/marketing-pages/GET_CASE_STUDIES_BY_USER_ID_ERROR';
export const GET_CASE_STUDIES_BY_USER_ID_SUCCESS =
  'zehitomo/marketing-pages/GET_CASE_STUDIES_BY_USER_ID_SUCCESS';

export const GET_ALL_CASE_STUDIES_BY_USER_ID =
  'zehitomo/marketing-pages/GET_ALL_CASE_STUDIES_BY_USER_ID';
export const GET_ALL_CASE_STUDIES_BY_USER_ID_ERROR =
  'zehitomo/marketing-pages/GET_ALL_CASE_STUDIES_BY_USER_ID_ERROR';
export const GET_ALL_CASE_STUDIES_BY_USER_ID_SUCCESS =
  'zehitomo/marketing-pages/GET_ALL_CASE_STUDIES_BY_USER_ID_SUCCESS';

export const GET_POPULAR_REQUESTS = 'zehitomo/marketing-pages/GET_POPULAR_REQUESTS';
export const GET_POPULAR_REQUESTS_ERROR = 'zehitomo/marketing-pages/GET_POPULAR_REQUESTS_ERROR';
export const GET_POPULAR_REQUESTS_SUCCESS = 'zehitomo/marketing-pages/GET_POPULAR_REQUESTS_SUCCESS';

export const ADD_FILTER = 'zehitomo/marketing-pages/ADD_FILTER';
export const REMOVE_FILTER = 'zehitomo/marketing-pages/REMOVE_FILTER';
export const INITIALIZE_FILTERS = 'zehitomo/marketing-pages/INITIALIZE_FILTERS';
export const REMOVE_MATCHING_CRITERIA_FILTER =
  'zehitomo/marketing-pages/REMOVE_MATCHING_CRITERIA_FILTER';
export const ADD_MATCHING_CRITERIA_FILTER = 'zehitomo/marketing-pages/ADD_MATCHING_CRITERIA_FILTER';
export const UPDATE_TIME_FILTER = 'zehitomo/marketing-pages/UPDATE_TIME_FILTER';
export const UPDATE_DAY_FILTER = 'zehitomo/marketing-pages/UPDATE_DAY_FILTER';
export const GET_PRO_CATEGORIES_FOR_SIGN_UP_SUCCESS =
  'zehitomo/marketing-pages/GET_PRO_CATEGORIES_FOR_SIGN_UP_SUCCESS';
export const GET_PRO_CATEGORIES_FOR_SIGN_UP =
  'zehitomo/marketing-pages/GET_PRO_CATEGORIES_FOR_SIGN_UP';
export const SET_VERTICAL_PAGE_TYPE = 'zehitomo/marketing-pages/SET_VERTICAL_PAGE_TYPE';

export const FILTER_TYPES = {
  // TODO: This should probably be renamed to DayTime and possibly split to Day and Time
  // but in the current UI they are evaluated via a single submission ui so we keep them together
  // when interacting with their values from handlers. In redux DateTime is split into day and time.
  DateTime: 'DateTime' as const,
  day: 'day' as const,
  matchingCriteria: 'matchingCriteria' as const,
  priceRange: 'priceRange' as const,
  time: 'time' as const,
};

// there are dynamic filters e.g. matching criteria trainingGoal.hobby
// there are also static filters e.g. day, time (currently combined in DateTime)
export const STATIC_FILTER_IDS = {
  DateTime: 'DateTime',
};

export interface FilterState {
  day?: string;
  matchingCriteria: {
    [matchingCriteriaKey: string]: Array<string> | undefined;
  };
  priceRange?: Array<string>;
  time?: string;
}

export interface State {
  blogPosts: Array<BlogPost>;
  caseStudies: Array<CaseStudy>;
  caseStudiesAll: Array<CaseStudy>;
  caseStudiesFetchParameters: string;
  caseStudiesPageSize: number;
  day: string;
  filters: FilterState;
  isPopularPricingItemsExpanded: boolean;
  newestReviews: Array<ProFeedback>;
  popularPricingItems: Array<{
    aggregatedPriceRange: { max: number; min: number };
    description: string;
    isRecommended: boolean;
    key: string;
  }>;
  popularRequests: Array<PopularRequest>;
  popularRequestsFetchParameters: string;
  pricingMenuItemKey: string;
  proBlogPosts: Array<ProBlogPost>;
  proCount: number;
  proCountGroup: number;
  proFetchParameters: string;
  proJobCategoriesForSignUp: ProJobCategoriesForSignup;
  proReviewStatistics: { ratingValue: number; reviewCount: number } | null;
  pros: Array<LandingPagePro>;
  prosGroup: Array<LandingPagePro>;
  sampleRequests: Array<{
    _id: string;
    address: string;
    budgetRange?: string;
    detail?: string;
    jobTypeName: string;
  }>;
  time: string;
  totalCaseStudies: number;
  verticalPageType:
    | 'unknown'
    | 'jobType'
    | 'prefecture'
    | 'city'
    | 'group'
    | 'groupCity'
    | 'majorCity';
}

export interface ProFeedbackByJobType {
  feedbackCountsByRating: {
    rate1: 0;
    rate2: 0;
    rate3: 0;
    rate4: 0;
    rate5: 0;
    totalRate: 0; // sum of all the rating
  };
  pageMax: 0;
  rating: 0; // average rating
  reviews: Array<ProFeedback>;
}
