import { createLogic } from 'redux-logic';

import { languageSelector } from 'modules/common/duck';

import * as Actions from './actions';
import {
  GET_NEWEST_REVIEWS_BY_JOB_TYPE,
  GET_NEWEST_REVIEWS_BY_JOB_TYPE_ERROR,
  GET_NEWEST_REVIEWS_BY_JOB_TYPE_SUCCESS,
  GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE,
  GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_ERROR,
  GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_SUCCESS,
  GET_PRO_COUNT,
  GET_PRO_COUNT_ERROR,
  GET_PRO_COUNT_SUCCESS,
  LIST_BLOG_POSTS,
  LIST_BLOG_POSTS_ERROR,
  LIST_BLOG_POSTS_SUCCESS,
  LIST_PROS,
  LIST_PROS_ERROR,
  LIST_PROS_SUCCESS,
  LIST_PROS_GROUP,
  LIST_PROS_GROUP_ERROR,
  LIST_PROS_GROUP_SUCCESS,
  LIST_SAMPLE_REQUESTS,
  LIST_SAMPLE_REQUESTS_ERROR,
  LIST_SAMPLE_REQUESTS_SUCCESS,
  LIST_BLOG_POSTS_BY_CATEGORY,
  LIST_BLOG_POSTS_BY_CATEGORY_SUCCESS,
  LIST_BLOG_POSTS_BY_CATEGORY_ERROR,
  LIST_PRO_BLOG_POSTS_BY_JOB_TYPE,
  LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_SUCCESS,
  LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_ERROR,
  GET_POPULAR_PRICING_ITEMS,
  GET_POPULAR_PRICING_ITEMS_ERROR,
  GET_POPULAR_PRICING_ITEMS_SUCCESS,
  GET_CASE_STUDIES,
  GET_CASE_STUDIES_ERROR,
  GET_CASE_STUDIES_SUCCESS,
  GET_CASE_STUDIES_BY_USER_ID,
  GET_CASE_STUDIES_BY_USER_ID_ERROR,
  GET_CASE_STUDIES_BY_USER_ID_SUCCESS,
  GET_ALL_CASE_STUDIES_BY_USER_ID,
  GET_ALL_CASE_STUDIES_BY_USER_ID_ERROR,
  GET_ALL_CASE_STUDIES_BY_USER_ID_SUCCESS,
  GET_POPULAR_REQUESTS,
  GET_POPULAR_REQUESTS_ERROR,
  GET_POPULAR_REQUESTS_SUCCESS,
  GET_PRO_CATEGORIES_FOR_SIGN_UP_SUCCESS,
  GET_PRO_CATEGORIES_FOR_SIGN_UP,
} from './types';

import {
  getPopularPricingItems,
  getNewestReviewsByJobType,
  getNewestReviewsByJobTypeAndPrefecture,
  getProCount,
  listBlogPosts,
  listPros,
  listSampleRequests,
  listBlogPostsByCategoryId,
  listProBlogPostsByJobTypeId,
  getCaseStudies,
  getPopularRequests,
  getProJobCategoriesForSignUpTree,
  getCaseStudiesByUserId,
} from '../service';

import type { RootState } from 'store/types';
import { caseStudiesPageSizeSelector } from './selectors';

const getNewestReviewsByJobTypeLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.getNewestReviewsByJobType>['payload']
>({
  process({ action }) {
    const { jobTypeId } = action.payload;
    return getNewestReviewsByJobType({
      jobTypeId,
    });
  },
  processOptions: {
    failType: GET_NEWEST_REVIEWS_BY_JOB_TYPE_ERROR,
    successType: GET_NEWEST_REVIEWS_BY_JOB_TYPE_SUCCESS,
  },
  type: GET_NEWEST_REVIEWS_BY_JOB_TYPE,
  validate({ action }, allow, reject) {
    if (!action.payload.jobTypeId) {
      return reject({ type: GET_NEWEST_REVIEWS_BY_JOB_TYPE_ERROR });
    }
    return allow(action);
  },
});

const getNewestReviewsByJobTypeAndPrefectureLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.getNewestReviewsByJobTypeAndPrefecture>['payload']
>({
  process({ action }) {
    const { jobTypeId, prefecture } = action.payload;
    return getNewestReviewsByJobTypeAndPrefecture({
      jobTypeId,
      prefecture,
    });
  },
  processOptions: {
    failType: GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_ERROR,
    successType: GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_SUCCESS,
  },
  type: GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE,
  validate({ action }, allow, reject) {
    if (!action.payload.jobTypeId) {
      return reject({ type: GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_ERROR });
    }
    if (!action.payload.prefecture) {
      return reject({ type: GET_NEWEST_REVIEWS_BY_JOB_TYPE_AND_PREFECTURE_ERROR });
    }
    return allow(action);
  },
});

const getProCountLogic = createLogic<RootState, ReturnType<typeof Actions.getProCount>['payload']>({
  process({ action }) {
    const { cityKey, jobTypeId, language, prefectureKey } = action.payload;
    return getProCount({
      cityKey,
      jobTypeId,
      language,
      prefectureKey,
    });
  },
  processOptions: {
    failType: GET_PRO_COUNT_ERROR,
    successType: GET_PRO_COUNT_SUCCESS,
  },
  type: GET_PRO_COUNT,
  validate({ action }, allow, reject) {
    if (!action.payload.jobTypeId) {
      return reject({ type: GET_PRO_COUNT_ERROR });
    }
    return allow(action);
  },
});

const listBlogPostsLogic = createLogic<RootState>({
  process({ getState }) {
    const language = languageSelector(getState());
    return listBlogPosts(language);
  },
  processOptions: {
    failType: LIST_BLOG_POSTS_ERROR,
    successType: LIST_BLOG_POSTS_SUCCESS,
  },
  type: LIST_BLOG_POSTS,
});

const listBlogPostsByCategoryLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.listBlogPostsByCategory>['payload']
>({
  process({ action }) {
    const { id } = action.payload;
    return listBlogPostsByCategoryId(id);
  },
  processOptions: {
    failType: LIST_BLOG_POSTS_BY_CATEGORY_ERROR,
    successType: LIST_BLOG_POSTS_BY_CATEGORY_SUCCESS,
  },
  type: LIST_BLOG_POSTS_BY_CATEGORY,
  validate({ action }, allow, reject) {
    if (!action.payload.id) {
      return reject({ type: LIST_BLOG_POSTS_BY_CATEGORY_ERROR });
    }
    return allow(action);
  },
});

const listProBlogPostsByJobTypeLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.listProBlogPostsByJobType>['payload']
>({
  process({ action }) {
    const { jobTypeId } = action.payload;
    return listProBlogPostsByJobTypeId(jobTypeId);
  },
  processOptions: {
    failType: LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_ERROR,
    successType: LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_SUCCESS,
  },
  type: LIST_PRO_BLOG_POSTS_BY_JOB_TYPE,
  validate({ action }, allow, reject) {
    if (!action.payload.jobTypeId) {
      return reject({ type: LIST_PRO_BLOG_POSTS_BY_JOB_TYPE_ERROR });
    }
    return allow(action);
  },
});

const listProsLogic = createLogic<RootState, ReturnType<typeof Actions.listPros>['payload']>({
  process({ action }) {
    const {
      cityGroup,
      cityKey,
      day,
      jobTypeId,
      language,
      matchingCriteria,
      page,
      prefectureKey,
      priceRange,
      pricingMenuItemKey,
      size,
      skip,
      time,
    } = action.payload;
    return listPros({
      cityGroup,
      cityKey,
      day,
      jobTypeId,
      language,
      matchingCriteria,
      page,
      prefectureKey,
      priceRange,
      pricingMenuItemKey,
      size,
      skip,
      time,
    });
  },
  processOptions: {
    failType: LIST_PROS_ERROR,
    successType: LIST_PROS_SUCCESS,
  },
  type: LIST_PROS,
});

const listProsGroupLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.listProsGroup>['payload']
>({
  process({ action }) {
    const {
      cityGroup,
      day,
      excludeCities,
      jobTypeId,
      language,
      matchingCriteria,
      page,
      prefectureKey,
      priceRange,
      pricingMenuItemKey,
      size,
      skip,
      time,
    } = action.payload;
    return listPros({
      cityGroup,
      day,
      excludeCities,
      jobTypeId,
      language,
      matchingCriteria,
      page,
      prefectureKey,
      priceRange,
      pricingMenuItemKey,
      size,
      skip,
      time,
    });
  },
  processOptions: {
    failType: LIST_PROS_GROUP_ERROR,
    successType: LIST_PROS_GROUP_SUCCESS,
  },
  type: LIST_PROS_GROUP,
});

const listSampleRequestsLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.listSampleRequests>['payload']
>({
  process({ action }) {
    const { cityId, jobTypeId, language, limit, prefectureId } = action.payload;
    return listSampleRequests({
      cityId,
      jobTypeId,
      language,
      limit,
      prefectureId,
    });
  },
  processOptions: {
    failType: LIST_SAMPLE_REQUESTS_ERROR,
    successType: LIST_SAMPLE_REQUESTS_SUCCESS,
  },
  type: LIST_SAMPLE_REQUESTS,
});

const getPopularPricingItemsLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.getPopularPricingItems>['payload']
>({
  process({ action }) {
    return getPopularPricingItems(action.payload);
  },
  processOptions: {
    failType: GET_POPULAR_PRICING_ITEMS_ERROR,
    successType: GET_POPULAR_PRICING_ITEMS_SUCCESS,
  },
  type: GET_POPULAR_PRICING_ITEMS,
  validate({ action }, allow, reject) {
    if (action.payload.jobTypeId) {
      allow(action);
    } else {
      reject({ type: GET_POPULAR_PRICING_ITEMS_ERROR });
    }
  },
});

const getCaseStudiesLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.getCaseStudies>['payload']
>({
  process({ action, getState }) {
    const { currentPage, jobTypeId, prefectureKey, cityGroupKey, cityKey } = action.payload;
    const pageSize = caseStudiesPageSizeSelector(getState());
    const language = languageSelector(getState());
    return getCaseStudies({
      city: cityKey,
      cityGroup: cityGroupKey,
      currentPage,
      jobTypeId,
      language,
      pageSize,
      prefecture: prefectureKey,
    });
  },
  processOptions: {
    failType: GET_CASE_STUDIES_ERROR,
    successType: GET_CASE_STUDIES_SUCCESS,
  },
  type: GET_CASE_STUDIES,
});

const getCaseStudiesByUserIdLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.getCaseStudiesByUserId>['payload']
>({
  process({ action, getState }) {
    const { userId, jobTypeId } = action.payload;
    const pageSize = caseStudiesPageSizeSelector(getState());
    return getCaseStudiesByUserId({
      jobTypeId,
      pageSize,
      userId,
    });
  },
  processOptions: {
    failType: GET_CASE_STUDIES_BY_USER_ID_ERROR,
    successType: GET_CASE_STUDIES_BY_USER_ID_SUCCESS,
  },
  type: GET_CASE_STUDIES_BY_USER_ID,
});

const getAllCaseStudiesByUserIdLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.getCaseStudiesByUserId>['payload']
>({
  process({ action }) {
    const { userId, jobTypeId } = action.payload;
    return getCaseStudiesByUserId({
      jobTypeId,
      pageSize: 999,
      userId,
    });
  },
  processOptions: {
    failType: GET_ALL_CASE_STUDIES_BY_USER_ID_ERROR,
    successType: GET_ALL_CASE_STUDIES_BY_USER_ID_SUCCESS,
  },
  type: GET_ALL_CASE_STUDIES_BY_USER_ID,
});

const getPopularRequestsLogic = createLogic<
  RootState,
  ReturnType<typeof Actions.getPopularRequests>['payload']
>({
  process({ action }) {
    const { jobTypeId, cityKey, prefectureKey } = action.payload;
    return getPopularRequests({
      cityKey,
      jobTypeId,
      prefectureKey,
    });
  },
  processOptions: {
    failType: GET_POPULAR_REQUESTS_ERROR,
    successType: GET_POPULAR_REQUESTS_SUCCESS,
  },
  type: GET_POPULAR_REQUESTS,
});

const getProJobCategoriesForSignUp = createLogic<
  RootState,
  ReturnType<typeof Actions.getProJobCategoriesForSignUp>
>({
  process() {
    return getProJobCategoriesForSignUpTree();
  },
  processOptions: {
    successType: GET_PRO_CATEGORIES_FOR_SIGN_UP_SUCCESS,
  },
  type: GET_PRO_CATEGORIES_FOR_SIGN_UP,
});

export default [
  getNewestReviewsByJobTypeLogic,
  getNewestReviewsByJobTypeAndPrefectureLogic,
  getPopularPricingItemsLogic,
  getProCountLogic,
  listBlogPostsLogic,
  listBlogPostsByCategoryLogic,
  listProBlogPostsByJobTypeLogic,
  listProsLogic,
  listProsGroupLogic,
  listSampleRequestsLogic,
  getCaseStudiesLogic,
  getCaseStudiesByUserIdLogic,
  getAllCaseStudiesByUserIdLogic,
  getPopularRequestsLogic,
  getProJobCategoriesForSignUp,
];
