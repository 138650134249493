import { User } from 'modules/users/types';

// The following is not a URL or any specific action, but just a constant action.

export const AUTH_USER = 'zehitomo/users/AUTH_USER';
export const AUTH_USER_ERROR = 'zehitomo/users/AUTH_USER_ERROR';
export const AUTH_USER_SUCCESS = 'zehitomo/users/AUTH_USER_SUCCESS';

export const CHANGE_PASSWORD = 'zehitomo/users/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'zehitomo/users/CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'zehitomo/users/CHANGE_PASSWORD_SUCCESS';

export const EMAIL_EXISTING = 'zehitomo/users/EMAIL_EXISTING';

export const LOGIN_VALUE_EXISTING = 'zehitomo/users/LOGIN_VALUE_EXISTING';

export const CLEAR_NOTIFICATIONS = 'zehitomo/users/CLEAR_NOTIFICATIONS';

export const GET_NOTIFICATION_SUCCESS = 'zehitomo/users/GET_NOTIFICATION_SUCCESS';

export const LIST_NOTIFICATIONS = 'zehitomo/users/LIST_NOTIFICATIONS';
export const LIST_NOTIFICATIONS_ERROR = 'zehitomo/users/LIST_NOTIFICATIONS_ERROR';
export const LIST_NOTIFICATIONS_SUCCESS = 'zehitomo/users/LIST_NOTIFICATIONS_SUCCESS';

export const MARK_A_SINGLE_NOTIFICATION = 'zehitomo/users/MARK_A_SINGLE_NOTIFICATION';
export const MARK_A_SINGLE_NOTIFICATION_ERROR = 'zehitomo/users/MARK_A_SINGLE_NOTIFICATION_ERROR';
export const MARK_A_SINGLE_NOTIFICATION_SUCCESS =
  'zehitomo/users/MARK_A_SINGLE_NOTIFICATION_SUCCESS';

export const MARK_ALL_NOTIFICATIONS = 'zehitomo/users/MARK_ALL_NOTIFICATIONS';
export const MARK_ALL_NOTIFICATIONS_ERROR = 'zehitomo/users/MARK_ALL_NOTIFICATIONS_ERROR';
export const MARK_ALL_NOTIFICATIONS_SUCCESS = 'zehitomo/users/MARK_ALL_NOTIFICATIONS_SUCCESS';

export const LIST_ANNOUNCEMENTS = 'zehitomo/users/LIST_ANNOUNCEMENTS';
export const LIST_ANNOUNCEMENTS_ERROR = 'zehitomo/users/LIST_ANNOUNCEMENTS_ERROR';
export const LIST_ANNOUNCEMENTS_SUCCESS = 'zehitomo/users/LIST_ANNOUNCEMENTS_SUCCESS';

export const MARK_A_SINGLE_ANNOUNCEMENT = 'zehitomo/users/MARK_A_SINGLE_ANNOUNCEMENT';
export const MARK_A_SINGLE_ANNOUNCEMENT_ERROR = 'zehitomo/users/MARK_A_SINGLE_ANNOUNCEMENT_ERROR';
export const MARK_A_SINGLE_ANNOUNCEMENT_SUCCESS =
  'zehitomo/users/MARK_A_SINGLE_ANNOUNCEMENT_SUCCESS';

export const MARK_ALL_ANNOUNCEMENTS = 'zehitomo/users/MARK_ALL_ANNOUNCEMENTS';
export const MARK_ALL_ANNOUNCEMENTS_ERROR = 'zehitomo/users/MARK_ALL_ANNOUNCEMENTS_ERROR';
export const MARK_ALL_ANNOUNCEMENTS_SUCCESS = 'zehitomo/users/ANNOUNCEMENTS_SUCCESS';

export const SIGN_OUT = 'zehitomo/users/SIGN_OUT';
export const SIGN_OUT_ERROR = 'zehitomo/users/SIGN_OUT_ERROR';
export const SIGN_OUT_SUCCESS = 'zehitomo/users/SIGN_OUT_SUCCESS';

export interface State {
  auth: {
    user: Record<string, unknown>;
  };
  common: {
    announcements: Record<string, unknown>;
    currentRequestUser: Record<string, unknown>;
    currentUser: User;
    isAuthenticated: boolean;
    isCredentialExisting: boolean;
    loadedAnnouncements: boolean;
    loadedNotifications: boolean;
    notifications: Record<string, unknown>;
    timestamp: number;
  };
  profiles: {
    pro: Record<string, unknown>;
  };
}
