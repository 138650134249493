import { logic as categoriesLogic } from 'modules/job-categories/duck';
import { logic as marketingPagesLogic } from 'modules/marketing-pages/duck';
import { logic as jobTypesLogic } from 'modules/job-types/duck';
import { logic as locationsLogic } from 'modules/locations/duck';
import { logic as requestsLogic } from 'modules/requests/duck';
import { logic as socketLogic } from 'modules/socket/duck';
import { logic as translationsLogic } from 'modules/translations/duck';
import { logic as usersLogic } from 'modules/users/duck';

export default [
  ...categoriesLogic,
  ...socketLogic,
  ...jobTypesLogic,
  ...locationsLogic,
  ...marketingPagesLogic,
  ...requestsLogic,
  ...translationsLogic,
  ...usersLogic,
];
