import { createSelector } from 'reselect';

import { jobTypeSelector } from 'modules/job-types/duck';
import type { RootState } from 'store/types';

const marketingPagesSelector = (state: RootState) => state.marketingPages;

export const blogPostsSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.blogPosts
);

export const proBlogPostsSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.proBlogPosts
);

// from ZWR-82
export const proCountSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.proCount
);

export const proCountGroupSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.proCountGroup
);

export const prosSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.pros
);

export const prosGroupSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.prosGroup
);

export const currentJobTypeNameSelector = createSelector(
  jobTypeSelector,
  (jobType) => jobType?.translations?.requestType
);

export const sampleRequestsSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.sampleRequests
);

export const proReviewStatisticsSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.proReviewStatistics
);

const formSelector = (state: RootState) => state.form;

export const formZipcodeFormSelector = createSelector(formSelector, (form) => form.zipcodeForm);

export const pricingMenuItemKeySelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.pricingMenuItemKey
);
export const pricingMenuItemDescriptionSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) =>
    marketingPages.popularPricingItems.find(
      (item) => marketingPages.pricingMenuItemKey === item.key
    )?.description
);

export const popularPricingItemsSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.popularPricingItems
);

export const popularPricingItemsExpandedSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.isPopularPricingItemsExpanded
);

export const caseStudiesTotalCountSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.totalCaseStudies
);

export const caseStudiesSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.caseStudies
);

export const caseStudiesAllSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.caseStudiesAll
);

export const caseStudiesPageSizeSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.caseStudiesPageSize
);

export const popularRequestsSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.popularRequests
);

export const daySelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.day
);

export const timeSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.time
);

export const getProJobCategoriesForSignUpSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.proJobCategoriesForSignUp
);

export const verticalPageTypeSelector = createSelector(
  marketingPagesSelector,
  (marketingPages) => marketingPages.verticalPageType
);
