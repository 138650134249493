import { GetServerSidePropsResult } from 'next';
import { AnyAction, Dispatch, Store } from 'redux';
import { LogicMiddleware } from 'redux-logic';

import { getDetail as getJobTypeDetail, jobTypeSelector } from 'modules/job-types/duck';
import { RootState } from 'store/types';

import type { ResolvedLocation } from '../services/city-grouping/types';

export interface Filters {
  day?: string;
  matchingCriteriaFilters?: Array<{ [key: string]: string }>;
  priceRange?: string;
  time?: string;
}

const REDIRECT_CATEGORIES = {
  'design-and-web': 'business',
  online: 'learning',
  personal: 'lifestyle',
  teaching: 'learning',
  wellness: 'learning',
} as Record<string, string>;

export const C1_CATEGORIES = ['home', 'learning', 'lifestyle', 'business', 'events'];

export const CATEGORY_WITH_ONLY_ONE_SUB_CATEGORY = 'events';

export async function checkAndRedirectIfNeeded<P>({
  categories: [category1, category2, category3],
  dispatch,
  jobTypeId,
  logicMiddleware,
  prefectureKey,
  resolvedLocation,
  store,
  url,
}: {
  categories: Array<string>;
  dispatch: Dispatch<AnyAction>;
  jobTypeId?: string;
  logicMiddleware: LogicMiddleware;
  prefectureKey?: string;
  resolvedLocation?: ResolvedLocation;
  store: Store<RootState>;
  url: string;
}): Promise<GetServerSidePropsResult<P> | undefined> {
  // FIXME Workaround for redirects not working in next.config
  if (new RegExp(/^\/(?:en\/)?recruiting(?:\?.*)?$/).test(url)) {
    return {
      redirect: {
        destination: 'https://sites.google.com/view/zehitomo-recruiting-jp',
        permanent: true,
      },
    };
  }

  // FIXME: Same above
  if (new RegExp(/^\/(?:en\/)?contact(?:\?.*)?$/).test(url)) {
    return {
      redirect: {
        destination: 'https://support.zehitomo.com/hc/ja/requests/new',
        permanent: true,
      },
    };
  }

  if (new RegExp(/^\/(?:en\/)?support(?:\?.*)?$/).test(url)) {
    return {
      redirect: {
        destination: 'https://support.zehitomo.com/hc/ja',
        permanent: true,
      },
    };
  }

  if (new RegExp(/^\/(?:en\/)?terms(?:\?.*)?$/).test(url)) {
    return {
      redirect: {
        destination: '/static/pdf/terms-of-use-clients-20220707.pdf',
        permanent: true,
      },
    };
  }

  if (new RegExp(/^\/(?:en\/)?terms\/pro(?:\?.*)?$/).test(url)) {
    return {
      redirect: {
        destination: '/static/pdf/terms-of-use-pros-20240801.pdf',
        permanent: true,
      },
    };
  }

  if (new RegExp(/^\/(?:en\/)?privacy(?:\?.*)?$/).test(url)) {
    return {
      redirect: {
        destination: '/static/pdf/privacy-policy-20231027.pdf',
        permanent: true,
      },
    };
  }

  if (
    category1 === 'category' &&
    category2 &&
    [...Object.keys(REDIRECT_CATEGORIES), ...C1_CATEGORIES].includes(category2)
  ) {
    return {
      redirect: {
        destination: `/${
          Object.keys(REDIRECT_CATEGORIES).includes(category2)
            ? REDIRECT_CATEGORIES[category2]
            : category2
        }`,
        permanent: true,
      },
    };
  }

  if (!C1_CATEGORIES.includes(category1)) {
    const category1AsJobTypeId = category1;
    const category2AsPrefectureKey = category2;
    const category3AsCityKey = category3;

    dispatch(getJobTypeDetail(category1AsJobTypeId));
    await logicMiddleware.whenComplete();
    const jobType = jobTypeSelector(store.getState(), { jobTypeId: category1AsJobTypeId });
    if (!jobType) {
      return { notFound: true };
    }

    let destination = '/';
    destination += [
      jobType.categories,
      category1AsJobTypeId,
      category2AsPrefectureKey,
      category3AsCityKey,
    ]
      .filter(Boolean)
      .flat()
      .join('/');
    const splitPath = url.split('?');
    if (splitPath.length > 1) destination += `?${splitPath[1]}`;

    return { redirect: { destination, permanent: true } };
  }

  if (resolvedLocation?.redirectTo === 'city') {
    return {
      redirect: {
        destination: [
          '',
          category1,
          category2,
          category3,
          jobTypeId,
          prefectureKey,
          resolvedLocation.groupKey,
          resolvedLocation.cityKey,
        ].join('/'),
        permanent: true,
      },
    };
  }

  if (resolvedLocation?.redirectTo === 'prefecture') {
    return {
      redirect: {
        destination: ['', category1, category2, category3, jobTypeId, prefectureKey].join('/'),
        permanent: true,
      },
    };
  }

  return;
}

export function getProLink({
  backlinkId,
  customProfileUrl,
  jobTypeId,
  language = 'ja',
  proId,
  shortId,
  slug,
}: {
  backlinkId?: string;
  customProfileUrl?: string;
  jobTypeId?: string;
  language: 'en' | 'ja';
  proId: string;
  shortId?: string;
  slug?: string;
}) {
  return `${language === 'en' ? '/en' : ''}/profile/${
    slug ?? customProfileUrl ?? shortId ?? proId
  }/pro${jobTypeId ? `/${jobTypeId}` : ''}${backlinkId ? `?backlinkId=${backlinkId}` : ''}`;
}

export const generateMatchingCriteriaFilterQueries = (
  matchingCriteriaFilters: Array<{ [key: string]: string }>
) => {
  if (!matchingCriteriaFilters.length) return '';

  return matchingCriteriaFilters
    .map((filter) => `${Object.keys(filter)[0]}.${filter[Object.keys(filter)[0]]}`)
    .join(',');
};
