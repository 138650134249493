/* eslint-disable sort-keys */
/* eslint-disable camelcase */
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Array<Record<string, any>> | undefined;
  }
}

const STORAGE_KEY_DATA_LAYER = 'ngStorage-dataLayer';

import { setItem, getAndParseItem } from 'utils/sessionStorage';

function addDataLayer(parameters: Record<string, string | number | boolean>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/prefer-nullish-coalescing
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(parameters);
}

/** @deprecated use trackingEvent from now on. delete after transition to GA4 is complete */
export const trackingEventForGA4 = (action = '', category = '', label = '', parameters = {}) => {
  if (!action || !category) return;

  const eventParams = {
    ua_event_action: action,
    ua_event_category: category,
    ua_event_label: label,
    ...parameters,
  };

  // eslint-disable-next-line no-console
  console.log(
    `Logging event for category ${category} and action ${action}${
      label ? ` with label ${label}. it send to GA4` : ''
    }`,
    parameters
  );

  addDataLayer({
    event: action,
    ...eventParams,
  });
};

function trackingEvent(
  eventName: string,
  parameters: Record<string, string | number | boolean>,
  options: { defer?: boolean } = { defer: false }
) {
  if (!eventName) return;

  if (options.defer) {
    // eslint-disable-next-line no-console
    console.log(`[Deferred] Logging event for eventName ${eventName} it send to GA4`, parameters);

    setTimeout(() => {
      addDataLayer({
        event: eventName,
        ...parameters,
      });
    }, 0);
    return;
  }

  // eslint-disable-next-line no-console
  console.log(`Logging event for eventName ${eventName} it send to GA4`, parameters);

  addDataLayer({
    event: eventName,
    ...parameters,
  });
}

export function setDataLayerVariables(
  variables: Record<string, string | number | boolean> = {},
  isStoreStorage = true
) {
  // eslint-disable-next-line no-console
  console.log(`Logging add dataLayer variables`, variables);

  addDataLayer(variables);
  if (isStoreStorage) {
    const vars = getAndParseItem<Record<string, string | number | boolean>>(STORAGE_KEY_DATA_LAYER);
    setItem(STORAGE_KEY_DATA_LAYER, { ...vars, ...variables });
  }
}

export function loadDataLayerVariables() {
  const variables =
    getAndParseItem<Record<string, string | number | boolean>>(STORAGE_KEY_DATA_LAYER);
  if (variables) {
    addDataLayer(variables);
  }
}

export const GA4EventRequestCreation = {
  blogViewed: 'blogViewed',
  proProfileViewed: 'proProfileViewed',
  cd_requestResultViewed: 'cd_requestResultViewed',
  findYourNeighborProsClicked: 'findYourNeighborProsClicked',
  startNewRequestClicked: 'startNewRequestClicked',
  requestChatViewed: 'requestChatViewed',
  requestIntroViewed: 'requestIntroViewed',
  requestIntroClicked: 'requestIntroClicked',
  q1Viewed: 'q1Viewed',
  q2Viewed: 'q2Viewed',
  q3Viewed: 'q3Viewed',
  q4Viewed: 'q4Viewed',
  q5Viewed: 'q5Viewed',
  q6Viewed: 'q6Viewed',
  q7Viewed: 'q7Viewed',
  q8Viewed: 'q8Viewed',
  q9Viewed: 'q9Viewed',
  addressViewed: 'addressViewed',
  addressClicked: 'addressClicked',
  matchingAnimationViewed: 'matchingAnimationViewed',
  howToRegisterViewed: 'howToRegisterViewed',
  sharingStatusChanged: 'sharingStatusChanged',
  emailRouteClicked: 'emailRouteClicked',
  mobilePhoneRouteClicked: 'mobilePhoneRouteClicked',
  mobilePhoneNumberClicked: 'mobilePhoneNumberClicked',
  smsCodeViewed: 'smsCodeViewed',
  smsCodeClicked: 'smsCodeClicked',
  smsCodeResendClicked: 'smsCodeResendClicked',
  smsNameViewed: 'smsNameViewed',
  smsNameClicked: 'smsNameClicked',
  passwordViewed: 'passwordViewed',
  passwordClicked: 'passwordClicked',
  emailViewed: 'emailViewed',
  emailClicked: 'emailClicked',
  nameViewed: 'nameViewed',
  nameClicked: 'nameClicked',
  phoneNumberViewed: 'phoneNumberViewed',
  phoneNumberClicked: 'phoneNumberClicked',
  summaryViewed: 'summaryViewed',
  summaryClicked: 'summaryClicked',
  userAuthViewed: 'userAuthViewed',
  userAuthPasswordClicked: 'userAuthPasswordClicked',
  userAuthClicked: 'userAuthClicked',
  userAuthSuccess: 'userAuthSuccess',
  smsUserAuthViewed: 'smsUserAuthViewed',
  smsUserAuthPasswordClicked: 'smsUserAuthPasswordClicked',
  smsUserAuthClicked: 'smsUserAuthClicked',
  smsUserAuthSuccess: 'smsUserAuthSuccess',
  smsSummaryViewed: 'smsSummaryViewed',
  smsSummaryClicked: 'smsSummaryClicked',
  socialClicked: 'socialClicked',
  socialEmailConfirmationViewed: 'socialEmailConfirmationViewed',
  socialEmailConfirmationClicked: 'socialEmailConfirmationClicked',
  socialUserAuthViewed: 'socialUserAuthViewed',
  socialUserAuthPasswordClicked: 'socialUserAuthPasswordClicked',
  socialUserAuthClicked: 'socialUserAuthClicked',
  socialUserAuthSuccess: 'socialUserAuthSuccess',
  socialLinkageCreated: 'socialLinkageCreated',
  socialPhoneNumberViewed: 'socialPhoneNumberViewed',
  socialPhoneNumberClicked: 'socialPhoneNumberClicked',
  newRequestSubmitted: 'newRequestSubmitted',
  quitRequestClicked: 'quitRequestClicked',
  quitRequestContinued: 'quitRequestContinued',
  quitRequestConfirmed: 'quitRequestConfirmed',
  quitRequestReasonSent: 'quitRequestReasonSent',
  newRequestCreated: 'newRequestCreated',
  loginStep1Passed: 'loginStep1Passed',
} as const;

export type GA4EventRequestCreation =
  typeof GA4EventRequestCreation[keyof typeof GA4EventRequestCreation];

export function trackingEventForRequestCreation(
  eventName: GA4EventRequestCreation | '',
  parameters: {
    C1?: string;
    C2?: string;
    existingUser?: boolean;
    jobTypeId?: string;
    socialType?: string;
    whereFrom?: string;
    whereFromForCancel?: string;
    whereFromForCreataion?: string;
    whereFromForOpenRequestForm?: string;
  } = {}
) {
  if (eventName) {
    trackingEvent(eventName, parameters, { defer: true });
  }
}

export function trackingEventForServiceListClick(categories: {
  C1: string;
  C2?: string;
  jobTypeId?: string;
}) {
  const eventName = 'servicesListClicked';
  const parameters = {
    // eslint-disable-next-line id-length
    C1: categories.C1,
    // eslint-disable-next-line id-length
    C2: categories.C2 ?? '',
    jobTypeId: categories.jobTypeId ?? '',
  };
  setDataLayerVariables(parameters);
  trackingEvent(eventName, parameters);
}
export function trackingEventForServiceListView() {
  const eventName = 'servicesListViewed';
  const parameters = {};

  setDataLayerVariables(parameters);
  trackingEvent(eventName, parameters);
}

export const GA4EventDirectBooking = {
  db_lpViewed: 'db_lpViewed',
  db_startBookingClicked: 'db_startBookingClicked',
  db_locationIdViewed: 'db_locationIdViewed',
  db_locationIdClicked: 'db_locationIdClicked',
  db_coursesViewed: 'db_coursesViewed',
  db_coursesClicked: 'db_coursesClicked',
  db_listOptionsViewed: 'db_listOptionsViewed',
  db_listOptionsClicked: 'db_listOptionsClicked',
  db_dateTimeViewed: 'db_dateTimeViewed',
  db_dateTimeViewed_unavailable: 'db_dateTimeViewed_unavailable',
  db_dateTimeClicked_back: 'db_dateTimeClicked_back',
  db_dateTimeClicked_form: 'db_dateTimeClicked_form',
  db_dateTimeClicked: 'db_dateTimeClicked',
  db_residenceAddressViewed: 'db_residenceAddressViewed',
  db_residenceAddressClicked: 'db_residenceAddressClicked',
  db_pricingSummaryViewed: 'db_pricingSummaryViewed',
  db_pricingSummaryClicked: 'db_pricingSummaryClicked',
  db_personalInfoViewed: 'db_personalInfoViewed',
  db_personalInfoClicked: 'db_personalInfoClicked',
  db_personalInfoLoginClicked: 'db_personalInfoLoginClicked',
  db_loginViewed: 'db_loginViewed',
  db_loginForgetPasswordClicked: 'db_loginForgetPasswordClicked',
  db_loginClicked: 'db_loginClicked',
  db_personalInfoViewed_afterLogin: 'db_personalInfoViewed_afterLogin',
  db_personalInfoClicked_afterLogin: 'db_personalInfoClicked_afterLogin',
  db_paymentViewed: 'db_paymentViewed',
  db_paymentClicked: 'db_paymentClicked',
  db_bookingCompleted: 'db_bookingCompleted',
  db_bookingCompletedClicked: 'db_bookingCompletedClicked',
  db_dashboardFirstViewed: 'db_dashboardFirstViewed',
} as const;

export type GA4EventDirectBooking =
  typeof GA4EventDirectBooking[keyof typeof GA4EventDirectBooking];

export function trackingEventForDirectBooking(
  eventName: GA4EventDirectBooking | '',
  parameters: {
    db_locationId?: string;
    whereFrom?: string;
  } = {}
) {
  trackingEvent(eventName, parameters);
}

export const GA4EventProRegistration = {
  lp_topViewed: 'lp_topViewed',
  lp_c1Viewed: 'lp_c1Viewed',
  lp_c2Viewed: 'lp_c2Viewed',
  lp_c3Viewed: 'lp_c3Viewed',
  lp_verticalViewed: 'lp_verticalViewed',
  blogViewed: 'blogViewed',
  proProfileViewed: 'proProfileViewed',
  cd_myRequestsViewed: 'cd_myRequestsViewed',
  pro_goProRegistration: 'pro_goProRegistration',
  lp_proViewed: 'lp_proViewed',
  pro_startRegistrationClicked: 'pro_startRegistrationClicked',
  pro_socialClicked: 'pro_socialClicked',
  pro_socialEmailVerifyViewed: 'pro_socialEmailVerifyViewed',
  pro_socialEmailVerifyClicked: 'pro_socialEmailVerifyClicked',
  pro_socialUserAuthViewed: 'pro_socialUserAuthViewed',
  pro_socialUserAuthPasswordLink: 'pro_socialUserAuthPasswordLink',
  pro_socialUserAuthClicked: 'pro_socialUserAuthClicked',
  pro_socialUserAuthSuccess: 'pro_socialUserAuthSuccess',
  pro_selectJobTypeViewed: 'pro_selectJobTypeViewed',
  pro_selectJobTypeSearch: 'pro_selectJobTypeSearch',
  pro_selectJobTypeClicked: 'pro_selectJobTypeClicked',
  pro_relatedJobTypeViewed: 'pro_relatedJobTypeViewed',
  pro_relatedJobTypeAction: 'pro_relatedJobTypeAction',
  pro_relatedJobTypeClicked: 'pro_relatedJobTypeClicked',
  pro_nameViewed: 'pro_nameViewed',
  pro_nameClicked: 'pro_nameClicked',
  pro_addressViewed: 'pro_addressViewed',
  pro_addressClicked: 'pro_addressClicked',
  pro_emailViewed: 'pro_emailViewed',
  pro_emailClicked: 'pro_emailClicked',
  pro_phoneNumberViewed: 'pro_phoneNumberViewed',
  pro_phoneNumberClicked: 'pro_phoneNumberClicked',
  pro_passwordViewed: 'pro_passwordViewed',
  pro_passwordClicked: 'pro_passwordClicked',
  pro_referralViewed: 'pro_referralViewed',
  pro_referralClicked: 'pro_referralClicked',
  pro_basicInfoErrorViewed: 'pro_basicInfoErrorViewed',
  pro_basicInfoErrorLogin: 'pro_basicInfoErrorLogin',
  pro_basicInfoErrorCTA: 'pro_basicInfoErrorCTA',
  pro_quitRegistrationClicked: 'pro_quitRegistrationClicked',
  pro_quitRegistrationContinued: 'pro_quitRegistrationContinued',
  pro_quitRegistrationConfirmed: 'pro_quitRegistrationConfirmed',
  pro_registrationCompleted: 'pro_registrationCompleted',
  pro_registrationCompletedClicked: 'pro_registrationCompletedClicked',
  pro_quitRegistrationDocumentsClicked: 'pro_quitRegistrationDocumentsClicked',
  pro_businessInfoViewed: 'pro_businessInfoViewed',
  pro_businessInfoClicked: 'pro_businessInfoClicked',
  pro_avatarUploadViewed: 'pro_avatarUploadViewed',
  pro_avatarUploadClicked: 'pro_avatarUploadClicked',
  pro_avatarCroppingClicked: 'pro_avatarCroppingClicked',
  pro_avatarChangeClicked: 'pro_avatarChangeClicked',
  pro_avatarCompleteClicked: 'pro_avatarCompleteClicked',
  pro_profileCompleteViewed: 'pro_profileCompleteViewed',
  pro_profileCompleteClicked: 'pro_profileCompleteClicked',
  pro_goSupportBookingClicked: 'pro_goSupportBookingClicked',
  pro_avatarSkipClicked: 'pro_avatarSkipClicked',
} as const;

export type GA4EventProRegistration =
  typeof GA4EventProRegistration[keyof typeof GA4EventProRegistration];

export function trackingEventForProRegistration(
  eventName: GA4EventProRegistration,
  parameters: {
    C1?: string;
    C2?: string;
    existingUser?: boolean;
    jobTypeId?: string;
    proSignUpCurrentStep?: string;
    proSignUpTrees?: string;
    socialType?: string;
    whereFrom?: string;
  } = {}
) {
  trackingEvent(eventName, parameters);
}

export const GA4EventProDashboard = {
  pd_headerBellClicked: 'pd_headerBellClicked',
  pd_headerBellNoticeLinkClicked: 'pd_headerBellNoticeLinkClicked',
  pd_headerBellNewsTabClicked: 'pd_headerBellNewsTabClicked',
  pd_headerBellNewsLinkClicked: 'pd_headerBellNewsLinkClicked',
  pd_headerBellAllReadClicked: 'pd_headerBellAllReadClicked',
} as const;

export type GA4EventProDashboard = typeof GA4EventProDashboard[keyof typeof GA4EventProDashboard];

/**
 * Please refer to this link for the parameters.
 * https://zehitomo.atlassian.net/wiki/spaces/PM/pages/691240961/40.+Pro+Dashboard+related+WIP
 * */
export function trackingEventForProDashboard(
  eventName: GA4EventProDashboard,
  parameters: {
    noticeContent?: string;
    status?: 'withBadge' | 'noBadge';
    whereFrom?: string;
  }
) {
  trackingEvent(eventName, parameters);
}

export const GA4EventWebVital = {
  webVital_CLS: 'webVital_CLS',
  webVital_FCP: 'webVital_FCP',
  webVital_FID: 'webVital_FID',
  webVital_LCP: 'webVital_LCP',
  webVital_TTFB: 'webVital_TTFB',
} as const;

export type GA4EventWebVital = typeof GA4EventWebVital[keyof typeof GA4EventWebVital];

export function trackingEventForWebVital(
  eventName: GA4EventWebVital,
  parameters: {
    effectiveType?: string;
    traceId?: string;
    value?: number;
  } = {}
) {
  trackingEvent(eventName, parameters);
}

export const GA4EventProCard = {
  proCardClicked: 'proCardClicked',
} as const;

export type GA4EventProCard = typeof GA4EventProCard[keyof typeof GA4EventProCard];

export function trackingEventForProCard(
  eventName: GA4EventProCard,
  parameters: {
    element?: 'avatar' | 'displayName' | 'viewProfileButton' | 'header';
    jobTypeId?: string;
  } = {}
) {
  trackingEvent(eventName, parameters);
}
