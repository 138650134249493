import _ from 'lodash';

import { CityGroup, TownArea } from 'src/modules/locations/types';

export interface Location {
  key: string;
  name: string;
  nameRome: string;
}

export interface City extends Location {
  prefecture: string;
}

export function parseCity(city: City, language: string) {
  let { name } = city;
  if (language === 'en') {
    name = city.nameRome
      .split(' ')
      .map((part, index, arr) => {
        if (index === arr.length - 1) {
          return `-${part.toLowerCase()}`;
        }
        return ` ${part[0].toUpperCase()}${part.slice(1).toLowerCase()}`;
      })
      .join('')
      .trim();
  }
  return {
    ...city,
    name,
  };
}

export function parseCityGroup(
  { nameEn = undefined, nameJa, cityGroupKey }: CityGroup,
  language: string
) {
  let name = nameJa;
  const nameRoma = cityGroupKey.split('-').join(' ').trim();
  if (language === 'en') {
    name = nameEn ?? nameRoma;
  }
  return {
    key: cityGroupKey,
    name,
  };
}

export function parsePrefecture(prefecture: Partial<Location> = {}, language: string) {
  let { name } = prefecture;
  if (language === 'en' && prefecture.nameRome) {
    name = prefecture.nameRome
      .split(' ')
      .map((part, index, arr) => {
        if (index > 0 && index === arr.length - 1) {
          return '';
        }

        return _.capitalize(part);
      })
      .join('')
      .trim();
  }

  return {
    ...prefecture,
    name: name ?? '',
  };
}

export function parseTownArea(townArea: TownArea, language: string) {
  let { name } = townArea;
  if (language === 'en' && townArea.nameRome) {
    name = townArea.nameRome
      .split(' ')
      .map((part, index, arr) => {
        if (index > 0 && index === arr.length - 1) {
          return '';
        }

        return _.capitalize(part);
      })
      .join('')
      .trim();
  }

  return {
    ...townArea,
    name,
  };
}
