const { GOOGLE_RECAPTCHA_V3_KEY } = process.env;

declare global {
  interface Window {
    grecaptcha?: ReCaptchaInstance;
  }

  interface Array<T> {
    findLastIndex(
      predicate: (value: T, index: number, obj: Array<T>) => unknown,
      thisArg?: any
    ): number
  }
}

interface ReCaptchaInstance {
  execute: (sitekey: string, options: ReCaptchaExecuteOptions) => Promise<string>;
}

interface ReCaptchaExecuteOptions {
  action: string;
}

export function execute(action: string): Promise<string | null> {
  if (GOOGLE_RECAPTCHA_V3_KEY && window.grecaptcha) {
    try {
      return window.grecaptcha.execute(GOOGLE_RECAPTCHA_V3_KEY, { action });
      // eslint-disable-next-line no-empty
    } catch (_error) {}
  }

  return Promise.resolve(null);
}
