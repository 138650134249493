import {
  AUTH_USER,
  CHANGE_PASSWORD,
  CLEAR_NOTIFICATIONS,
  LOGIN_VALUE_EXISTING,
  GET_NOTIFICATION_SUCCESS,
  LIST_ANNOUNCEMENTS,
  LIST_NOTIFICATIONS,
  MARK_A_SINGLE_ANNOUNCEMENT,
  MARK_A_SINGLE_NOTIFICATION,
  MARK_A_SINGLE_NOTIFICATION_SUCCESS,
  MARK_ALL_ANNOUNCEMENTS,
  MARK_ALL_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_SUCCESS,
  SIGN_OUT,
} from './types';

export function auth() {
  return {
    type: AUTH_USER,
  };
}

export function changePassword(id, token, password) {
  return {
    type: CHANGE_PASSWORD,
    payload: {
      id,
      password,
      token,
    },
  };
}

export function clearNotifications() {
  return {
    type: CLEAR_NOTIFICATIONS,
  };
}

export function getNotificationSuccess({ notification } = {}) {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload: notification,
  };
}

export function listNotifications() {
  return {
    type: LIST_NOTIFICATIONS,
  };
}

export function markAllNotifications() {
  return {
    type: MARK_ALL_NOTIFICATIONS,
  };
}

export function markAllNotificationsSuccess({ timestamp } = {}) {
  return {
    type: MARK_ALL_NOTIFICATIONS_SUCCESS,
    payload: {
      timestamp,
    },
  };
}

export function markASingleNotification({ notificationId } = {}) {
  return {
    type: MARK_A_SINGLE_NOTIFICATION,
    payload: {
      notificationId,
    },
  };
}

export function markASingleNotificationSuccess({ notification } = {}) {
  return {
    type: MARK_A_SINGLE_NOTIFICATION_SUCCESS,
    payload: notification,
  };
}

export function listAnnouncements() {
  return {
    type: LIST_ANNOUNCEMENTS,
  };
}

export function markASingleAnnouncement({ userId, announcementId } = {}) {
  return {
    type: MARK_A_SINGLE_ANNOUNCEMENT,
    payload: {
      userId,
      announcementId,
    },
  };
}

export function markAllAnnouncements() {
  return {
    type: MARK_ALL_ANNOUNCEMENTS,
  };
}

export function signOut() {
  return {
    type: SIGN_OUT,
  };
}

export function credentialExisting(isCredentialExisting) {
  return {
    type: LOGIN_VALUE_EXISTING,
    payload: {
      isCredentialExisting,
    },
  };
}
