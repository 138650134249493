import { createLogicMiddleware } from 'redux-logic';
import { applyMiddleware, createStore, combineReducers, AnyAction, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import commonReducer from 'modules/common/duck';
import jobCategoryReducer from 'modules/job-categories/duck';
import jobTypeReducer from 'modules/job-types/duck';
import layoutReducer from 'modules/layout/duck';
import locationsReducer from 'modules/locations/duck';
import marketingPagesReducer, {
  initialState as marketingPagesInitialState,
} from 'modules/marketing-pages/duck';
import translationsReducer from 'modules/translations/duck';
import usersReducer from 'modules/users/duck';
import requestsReducer from 'modules/requests/duck';
import socketReducer from 'modules/socket/duck';
import { eventsSubscriber } from 'modules/socket/utils';

import allLogics from './logic';
import { RootState, SET_CACHED_STATES } from './types';

// @ts-expect-error Not everything is typed
const combinedReducers = combineReducers<RootState>({
  common: commonReducer,
  form: formReducer,
  jobCategories: jobCategoryReducer,
  jobTypes: jobTypeReducer,
  layout: layoutReducer,
  locations: locationsReducer,
  marketingPages: marketingPagesReducer,
  requests: requestsReducer,
  socket: socketReducer,
  translations: translationsReducer,
  users: usersReducer,
});

const getBaseFetchParameters = (fetchParameters: string) => {
  const indexOfQueryParameters = fetchParameters.indexOf('?');
  if (indexOfQueryParameters === -1) {
    return fetchParameters;
  }
  return fetchParameters.substring(0, indexOfQueryParameters);
};

export const reducer = (state: RootState | undefined, action: AnyAction) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

  /**
   * https://github.com/kirill-konshin/next-redux-wrapper#state-reconciliation-during-hydration
   */
  switch (action.type) {
    case HYDRATE: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const nextState: RootState = {
        ...state, // use previous state
        ...action.payload, // apply delta from hydration
      };

      if (state?.users.common.currentUser) {
        nextState.users.common.currentUser = state.users.common.currentUser;
        nextState.users.common.isAuthenticated = state.users.common.isAuthenticated;
      }

      const isMarketingSliceAtInitialState = state?.marketingPages === marketingPagesInitialState;

      // filters specific hydration handling
      // first hydrate is where marketing slice is equal to the initial state and represents
      // initialState -> SSR prop hydration
      if (!isMarketingSliceAtInitialState && state) {
        const currentProFetchParameters = getBaseFetchParameters(
          state.marketingPages.proFetchParameters
        );
        const nextProFetchParameters = getBaseFetchParameters(
          nextState.marketingPages.proFetchParameters
        );

        // There is another hydration action when using CSR
        // SSR props and clientside props need to be diffed.
        // When changing filters the base fetch parameters are the same, only the query differs.
        // persist the values in state as they represent the selection made on the clientside.
        if (currentProFetchParameters === nextProFetchParameters) {
          nextState.marketingPages.proFetchParameters = state.marketingPages.proFetchParameters;
          nextState.marketingPages.filters = state.marketingPages.filters;
          nextState.marketingPages.pros = state.marketingPages.pros;
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return nextState;
    }
    case SET_CACHED_STATES: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        ...action.payload.cachedStates,
      };
    }
    default:
      return combinedReducers(state, action);
  }
};

const initStore = (initialState?: RootState) => {
  // @ts-expect-error Most logics are not typed
  const logicMiddleware = createLogicMiddleware(allLogics, {});
  const enhancers = composeWithDevTools(applyMiddleware(thunk, logicMiddleware));
  const store = createStore(
    reducer,
    // @ts-expect-error Not everything is typed
    initialState,
    enhancers
  );

  // @ts-expect-error Setting custom property on store
  store.logicMiddleware = logicMiddleware;
  store.subscribe(() => eventsSubscriber(store));
  return store;
};

const makeStore = () => initStore();

export const wrapper = createWrapper<Store<RootState>>(makeStore);

export type { RootState };
export default initStore;
