import uuid from 'uuid';

import { generateNonce, isAuthData, isSocialAccessData, setAuthenticationData } from './oauth';
import {
  LOCAL_STORAGE_KEYS,
  AUTH_COOKIE,
  NONCE_COOKIE_KEY,
  Cookies,
  SOCIAL_ACCESS_COOKIE,
} from './constants';
import { CookieOptions, AuthData, SocialAccessData } from './types';

export const makeHostCookiePart = (url: string) => {
  const { host } = new URL(url);
  if (host.includes('localhost')) {
    return 'Domain=localhost';
  }

  // remove any subdomain so that the cookies look like .zehitomo.xxx
  return `Domain=.${host.replace(/^[^.]+\./g, '')}`;
};

export const makeAuthDataCookie = (authData: AuthData, url: string) => {
  const hostPart = makeHostCookiePart(url);
  return `${AUTH_COOKIE}=${JSON.stringify(authData)}; Expires=${
    authData.expires
  }; ${hostPart}; Path=/; SameSite=Strict; Secure;`;
};

export const makeSocialAccessCookie = (tokenData: SocialAccessData, url: string) => {
  const hostPart = makeHostCookiePart(url);
  // Is default cookie expiration fine?
  return `${SOCIAL_ACCESS_COOKIE}=${JSON.stringify(
    tokenData
  )}; Expires=${defaultCookieExpiration()}; ${hostPart}; Path=/; SameSite=Strict; Secure`;
};

export const readSocialAccessCookie = () => {
  if (
    typeof window === 'undefined' ||
    typeof document === 'undefined' ||
    typeof localStorage === 'undefined'
  ) {
    return null;
  }
  const socialCookie = getSocialAccessCookie(document.cookie);
  let parsedCookie: unknown;
  try {
    parsedCookie = JSON.parse(socialCookie ?? '');
  } catch (error) {
    return null;
  }
  if (isSocialAccessData(parsedCookie)) return parsedCookie;
  return null;
};

export const getCookieByKey = (cookies: string, key: string) => {
  const splitCookies = cookies.split(';');
  for (const cookie of splitCookies) {
    const trimmedCookie = cookie.trim();
    const cookiePrefixToFind = `${key}=`;
    if (trimmedCookie.startsWith(cookiePrefixToFind)) {
      const nonce = trimmedCookie.substring(cookiePrefixToFind.length);
      return nonce;
    }
  }
  return null;
};

const defaultCookieExpiration = () => {
  const expirationDate = new Date();
  // eslint-disable-next-line no-magic-numbers
  const oneDayInMS = 1000 * 60 * 60 * 24;
  expirationDate.setTime(expirationDate.getTime() + oneDayInMS);
  return expirationDate.toUTCString();
};
export const setCookie = (
  { key, value }: { key: string; value: string },
  {
    url,
    expiresUTCString = defaultCookieExpiration(),
    path = '/',
    sameSite = 'Strict',
  }: CookieOptions
) => {
  if (typeof document === 'undefined') {
    return;
  }
  const hostPart = makeHostCookiePart(url);
  document.cookie = `${key}=${value}; Expires=${expiresUTCString}; ${hostPart}; path=${path}; SameSite=${sameSite}; secure`;
};

export const deleteCookieByKey = (key: string, { url, path = '/' }: CookieOptions) => {
  if (typeof document === 'undefined') {
    return;
  }

  const hostPart = makeHostCookiePart(url);
  document.cookie = `${key}=; Max-Age=-1; path=${path}; ${hostPart}`;
};

export const consumeAuthCookie = (options: CookieOptions) => {
  if (
    typeof window === 'undefined' ||
    typeof document === 'undefined' ||
    typeof localStorage === 'undefined'
  ) {
    return false;
  }
  const authCookie = getAuthDataCookie(document.cookie);
  let parsedCookie: unknown;
  try {
    parsedCookie = JSON.parse(authCookie ?? '');
  } catch (error) {
    return false;
  }
  if (!isAuthData(parsedCookie)) {
    return false;
  }
  let localStorageAuthData: unknown;
  try {
    localStorageAuthData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_DATA) ?? '');
  } catch (error) {
    // do nothing. we can try to set
  }
  // LS is fresher than the authCookie. Clean up the cookie so we avoid any weird downstream issues
  // associated with which auth we should use. We prefer LS because it is the primary auth
  // storage mechanism
  deleteAuthDataCookie(options);
  if (isAuthData(localStorageAuthData) && localStorageAuthData.expires > parsedCookie.expires) {
    return true;
  }
  setAuthenticationData(parsedCookie);
  return true;
};

export const makeOAuthCookieNonce = ({
  nonceName = NONCE_COOKIE_KEY,
  nonce = generateNonce(),
  url,
  path = '/',
}: CookieOptions) => {
  const expirationDate = new Date();
  // eslint-disable-next-line no-magic-numbers
  const fifteenMinutesInMS = 1000 * 60 * 15;
  expirationDate.setTime(expirationDate.getTime() + fifteenMinutesInMS);
  const hostPart = makeHostCookiePart(url);
  const cookieString = `${nonceName}=${nonce}; Expires=${expirationDate.toUTCString()}; ${hostPart}; path=${path}; SameSite=lax; secure`;
  return cookieString;
};

const getAuthDataCookie = (cookies: string) => getCookieByKey(cookies, AUTH_COOKIE);
const getSocialAccessCookie = (cookies: string) => getCookieByKey(cookies, SOCIAL_ACCESS_COOKIE);
const deleteAuthDataCookie = (options: CookieOptions) => deleteCookieByKey(AUTH_COOKIE, options);
export const getOAuthCookieNonce = (cookies: string) => getCookieByKey(cookies, NONCE_COOKIE_KEY);
export const deleteOAuthCookieNonce = (options: CookieOptions) =>
  deleteCookieByKey(NONCE_COOKIE_KEY, options);
export const getSessionIdCookie = (cookies: string) =>
  getCookieByKey(cookies, Cookies.sessionIdCookie);
export const deleteSessionIdCookie = (options: CookieOptions) =>
  deleteCookieByKey(Cookies.sessionIdCookie, options);
export const getAnonymousIdCookie = (cookies: string) =>
  getCookieByKey(cookies, Cookies.anonymousIdCookie);
export const deleteAnonymousIdCookie = (options: CookieOptions) =>
  deleteCookieByKey(Cookies.sessionIdCookie, options);
export const makeSessionIdCookie = (options: CookieOptions) => {
  setCookie({ key: Cookies.sessionIdCookie, value: options.value ?? uuid() }, options);
};
export const makeAnonymousIdCookie = (options: CookieOptions) => {
  setCookie({ key: Cookies.anonymousIdCookie, value: options.value ?? uuid() }, options);
};
