import { captureException } from '@sentry/nextjs';
import { useEffect, useState } from 'react';
import { getConvertWindowProperty, isConvertLoaded } from 'utils/convert';
import { waitForWindowProperty } from 'utils/window-utils';

enum ConvertLoadingState {
  failed = 'failed',
  initialized = 'initialized',
  loading = 'loading',
}

/**
 * Initializes Convert for use with any experiment
 * Instead of calling this directly, consider using {@link useConvertExperiment}
 * @returns {Object}
 * @property {boolean} isLoading Whether or not Convert is being loaded
 * @property {boolean} isInitialized Whether or not Convert successfully loaded
 */
export default function useConvert() {
  const [loadingState, setLoadingState] = useState(
    isConvertLoaded() ? ConvertLoadingState.initialized : ConvertLoadingState.loading
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (!intervalId && !isConvertLoaded()) {
      const { promise: waitForConvertPromise, intervalId: activeIntervalId } =
        waitForWindowProperty(getConvertWindowProperty());
      intervalId = activeIntervalId;
      waitForConvertPromise
        .then(() => {
          setLoadingState(ConvertLoadingState.initialized);
        })
        .catch((error) => {
          setLoadingState(ConvertLoadingState.failed);
          captureException(error);
        });
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return {
    isLoading: loadingState === ConvertLoadingState.loading,
    isInitialized: loadingState === ConvertLoadingState.initialized,
  };
}
