import { SIGN_OUT_SUCCESS } from 'modules/users/duck';

import { REGISTER_CLIENT_SUCCESS, REGISTER_PRO_SUCCESS, UPLOAD_AVATAR_SUCCESS } from './types';

export const initialState = { user: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_CLIENT_SUCCESS:
    case REGISTER_PRO_SUCCESS:
      return { ...state, user: action.payload.user };

    case SIGN_OUT_SUCCESS:
      return { ...initialState };

    case UPLOAD_AVATAR_SUCCESS: {
      const { data } = action.payload;
      return data ? { ...state, user: { ...state.user, ...data } } : state;
    }
    default:
      return state;
  }
};
export default reducer;
