import { Context as ContextEnum, LOCAL_STORAGE_KEYS } from './constants';
import { getOAuthCookieNonce } from './cookies';
import type { AuthData, Context, MakeOAuth2StateOptions, SocialAccessData } from './types';
import { IncomingMessage } from 'http';

export const generateNonce = () => {
  if (typeof window === 'undefined') {
    return '';
  }
  const randomValueStrategy =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    typeof window.crypto?.getRandomValues === 'undefined' ? 'math' : 'crypto';
  switch (randomValueStrategy) {
    case 'math': {
      // eslint-disable-next-line no-magic-numbers
      return (
        // eslint-disable-next-line no-magic-numbers
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      );
    }
    case 'crypto': {
      const randomValues = window.crypto.getRandomValues(new Int16Array(1));
      return String(Math.abs(randomValues[0]));
    }
  }
};

export const UNKNOWN_STATE = 'unknown_state';
export const makeOAuthState = ({
  context,
  provider,
  nonce = '',
  fingerprint = '',
}: MakeOAuth2StateOptions) => {
  switch (context) {
    case 'pro-registration':
    case 'request-form':
    case 'sign-in':
    case 'client-registration': {
      return `${context}_${provider}_${nonce}_${fingerprint}`;
    }
    default: {
      return UNKNOWN_STATE;
    }
  }
};

export const parseOAuthState = (state: string) => {
  const decodedState = decodeURIComponent(state);
  const [context = ContextEnum.unknownContext, provider = '', nonce = '', fingerprint = ''] =
    decodedState.split('_');
  return { context: context as Context, provider, nonce, fingerprint };
};

export const isAuthData = (obj: unknown): obj is AuthData =>
  obj !== null &&
  typeof obj === 'object' &&
  typeof (obj as AuthData).expires === 'number' &&
  typeof (obj as AuthData).token === 'string' &&
  typeof (obj as AuthData).uid === 'string';

export const isOAuthStateAndCookieValid = (
  state: string,
  cookies: string
): { detail: { action: string; errorCodes: string } | undefined; result: boolean } => {
  const nonceFromCookie = getOAuthCookieNonce(cookies);
  const { nonce: nonceFromState } = parseOAuthState(state);

  let detail;

  if (!nonceFromCookie) {
    detail = {
      action: 'OAuthLogin_NonceCookieNotFound',
      errorCodes: 'NONCE_COOKIE_NOT_FOUND',
    };
  }

  if (nonceFromCookie && nonceFromCookie !== nonceFromState) {
    detail = {
      action: 'OAuthLogin_NonceMismatchError',
      errorCodes: 'NONCE_MISMATCH_ERROR',
    };
  }

  const result = nonceFromCookie === nonceFromState;

  return {
    detail,
    result,
  };
};

export const checkAndLogIfOauthStateAndFingerprintNotMatch = (
  state: string,
  fingerprint: string
): { action: string; errorCodes: string } | undefined => {
  const { fingerprint: fingerprintFromState } = parseOAuthState(state);

  if (!fingerprintFromState) {
    return {
      action: 'OAuthLogin_BrowserFingerprintNotFound',
      errorCodes: 'BROWSER_FINGERPRINT_NOT_FOUND',
    };
  }

  if (fingerprintFromState !== fingerprint) {
    return {
      action: 'OAuthLogin_BrowserMismatch',
      errorCodes: 'BROWSER_MISMATCH_ERROR',
    };
  }

  return undefined;
};

export const setAuthenticationData = (authData: AuthData) => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  localStorage.setItem(LOCAL_STORAGE_KEYS.AUTH_DATA, JSON.stringify(authData));
  localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, JSON.stringify(authData.uid));
  localStorage.setItem(LOCAL_STORAGE_KEYS.FAILED_ATTEMPTS, '0');
};

export const isSocialAccessData = (obj: unknown): obj is SocialAccessData =>
  obj !== null &&
  typeof obj === 'object' &&
  typeof (obj as SocialAccessData).email === 'string' &&
  typeof (obj as SocialAccessData).socialAccessToken === 'string' &&
  typeof (obj as SocialAccessData).provider === 'string' &&
  typeof (obj as SocialAccessData).context === 'string' &&
  typeof (obj as SocialAccessData).conversationId === 'string';

export type SocialRequestMeta = {
  hasVisitedSocialField?: boolean;
  isNewUser?: boolean;
  jobTypeCategories?: Array<string>;
  jobTypeId: string;
  shouldTrackSocialEvents?: boolean;
  socialRequestId: string | null;
  testVariant?: string | null;
};
export const setSocialRequestMetaData = (metadata: SocialRequestMeta) => {
  if (typeof localStorage === 'undefined') {
    return;
  }
  localStorage.setItem(LOCAL_STORAGE_KEYS.SOCIAL_REQUEST_META, JSON.stringify(metadata));
};

export const getFingerprint = (req: IncomingMessage | null) => {
  const userAgent = getUserAgent(req);
  if (userAgent) {
    return md5(userAgent);
  }

  return 'unknown-fingerprint';
};

const getUserAgent = (req: IncomingMessage | null) => {
  let userAgent = undefined;

  if (req?.headers) {
    userAgent = req.headers['user-agent'];
  }

  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined' && !userAgent) {
    userAgent = window.navigator.userAgent || '';
  }

  return userAgent;
};

/*
 * Including a basic example here to ensure we have a consistent way of
 * hashing between server side rendering and client side rendering.
 *
 * Also this avoids including additional libraries. We don't need this
 * to be secure. We just need to it generate a hash as fast as possible while
 * also not caring about collisions.
 */
function md5(input: string): string {
  // eslint-disable-next-line id-length,no-magic-numbers
  const T: Array<number> = new Array(64)
    .fill(0)
    // eslint-disable-next-line no-magic-numbers
    .map((_, i) => Math.floor(4294967296 * Math.abs(Math.sin(i + 1))));

  // eslint-disable-next-line id-length
  function F(x: number, y: number, z: number): number {
    return (x & y) | (~x & z);
  }

  // eslint-disable-next-line id-length
  function G(x: number, y: number, z: number): number {
    return (x & z) | (y & ~z);
  }

  // eslint-disable-next-line id-length
  function H(x: number, y: number, z: number): number {
    return x ^ y ^ z;
  }

  // eslint-disable-next-line id-length
  function I(x: number, y: number, z: number): number {
    return y ^ (x | ~z);
  }

  function leftRotate(value: number, shift: number): number {
    // eslint-disable-next-line no-magic-numbers
    return (value << shift) | (value >>> (32 - shift));
  }

  function pad(input: Uint8Array): Uint8Array {
    const originalLength = input.length;
    // eslint-disable-next-line no-magic-numbers
    const requiredLength = (originalLength + 8) & ~0x3f;
    // eslint-disable-next-line no-magic-numbers
    const padding = new Uint8Array(requiredLength + 64 - originalLength);
    padding[0] = 0x80;
    // eslint-disable-next-line no-magic-numbers
    new DataView(padding.buffer).setUint32(padding.length - 4, originalLength * 8, true);
    const result = new Uint8Array(originalLength + padding.length);
    result.set(input);
    result.set(padding, originalLength);
    return result;
  }

  const message = pad(new TextEncoder().encode(input));
  // eslint-disable-next-line no-magic-numbers,id-length
  let a0 = 0x67452301;
  // eslint-disable-next-line no-magic-numbers,id-length
  let b0 = 0xefcdab89;
  // eslint-disable-next-line no-magic-numbers,id-length
  let c0 = 0x98badcfe;
  // eslint-disable-next-line no-magic-numbers,id-length
  let d0 = 0x10325476;

  // eslint-disable-next-line no-magic-numbers
  for (let i = 0; i < message.length; i += 64) {
    // eslint-disable-next-line no-magic-numbers
    const view = new DataView(message.buffer, i, 64);
    // eslint-disable-next-line id-length
    let [a, b, c, d] = [a0, b0, c0, d0];

    // eslint-disable-next-line no-magic-numbers,id-length
    for (let j = 0; j < 64; j++) {
      // eslint-disable-next-line id-length
      let f: number;
      // eslint-disable-next-line id-length
      let g: number;
      // eslint-disable-next-line no-magic-numbers
      if (j < 16) {
        // eslint-disable-next-line new-cap
        f = F(b, c, d);
        g = j;
        // eslint-disable-next-line no-magic-numbers
      } else if (j < 32) {
        // eslint-disable-next-line new-cap
        f = G(b, c, d);
        // eslint-disable-next-line no-magic-numbers
        g = (5 * j + 1) & 0x0f;
        // eslint-disable-next-line no-magic-numbers
      } else if (j < 48) {
        // eslint-disable-next-line new-cap
        f = H(b, c, d);
        // eslint-disable-next-line no-magic-numbers
        g = (3 * j + 5) & 0x0f;
      } else {
        // eslint-disable-next-line new-cap
        f = I(b, c, d);
        // eslint-disable-next-line no-magic-numbers
        g = (7 * j) & 0x0f;
      }

      const temp = d;
      d = c;
      c = b;
      b =
        (b +
          leftRotate(
            // eslint-disable-next-line no-magic-numbers
            (a + f + T[j] + view.getUint32(g * 4, true)) >>> 0,
            // eslint-disable-next-line no-magic-numbers
            [7, 12, 17, 22, 5, 9, 14, 20, 4, 11, 16, 23, 6, 10, 15, 21][(j & 3) | ((j >> 2) & 4)]
          )) >>>
        0;
      a = temp;
    }

    // eslint-disable-next-line id-length
    [a0, b0, c0, d0] = [(a + a0) >>> 0, (b + b0) >>> 0, (c + c0) >>> 0, (d + d0) >>> 0];
  }

  return (
    Array.from(new Uint32Array([a0, b0, c0, d0]))
      // eslint-disable-next-line no-magic-numbers
      .map((v) => v.toString(16).padStart(8, '0'))
      .join('')
  );
}
