import { createSelector } from 'reselect';

const socketSelector = state => state.socket;

export const eventsSelector = createSelector(
  socketSelector,
  socket => socket.events,
);

export const socketInstanceSelector = createSelector(
  socketSelector,
  socket => socket.instance,
);
