import _ from 'lodash';
import { createLogic } from 'redux-logic';

import { languageSelector } from 'modules/common/duck';
import { jobCategoryDetailSelector } from './selectors';

import {
  GET_ALL_C1_CATEGORY_TREE,
  GET_ALL_C1_CATEGORY_TREE_SUCCESS,
  GET_ALL_C1_CATEGORY_TREE_ERROR,
  GET_CATEGORY_TREE,
  GET_CATEGORY_TREE_ERROR,
  GET_CATEGORY_TREE_SUCCESS,
  GET_DETAIL,
  GET_DETAIL_ERROR,
  GET_DETAIL_SUCCESS,
  LIST,
  LIST_ERROR,
  LIST_SUCCESS,
} from './types';

import { getAllC1CategoryTree, getCategoryTree, getDetail, list } from '../service';

const getDetailLogic = createLogic({
  type: GET_DETAIL,
  processOptions: {
    failType: GET_DETAIL_ERROR,
    successType: GET_DETAIL_SUCCESS,
  },
  process({ action, getState }) {
    const language = languageSelector(getState());
    return getDetail(action.payload.id, language);
  },
  validate({ action, getState }, allow, reject) {
    // reject if payload id is not provided
    if (!action.payload || !action.payload.id) {
      return reject();
    }

    const state = getState();

    // allow only if detail is not loaded
    const currentJobTypeDetail = jobCategoryDetailSelector(state);
    if (currentJobTypeDetail.id !== action.payload.id) {
      return allow(action);
    }

    return reject();
  },
});

const getAllC1CategoryTreeLogic = createLogic({
  type: GET_ALL_C1_CATEGORY_TREE,
  processOptions: {
    failType: GET_ALL_C1_CATEGORY_TREE_ERROR,
    successType: GET_ALL_C1_CATEGORY_TREE_SUCCESS,
  },
  validate({ action }, allow) {
    return allow(action);
  },
  process() {
    return getAllC1CategoryTree();
  },
});

const getCategoryTreeLogic = createLogic({
  type: GET_CATEGORY_TREE,
  processOptions: {
    failType: GET_CATEGORY_TREE_ERROR,
    successType: GET_CATEGORY_TREE_SUCCESS,
  },
  validate({ action }, allow, reject) {
    if (!action.payload || !action.payload.id) {
      return reject();
    }
    return allow(action);
  },
  process({ action }) {
    return getCategoryTree(action.payload.id);
  },
});

const listCategoriesLogic = createLogic({
  type: LIST,
  processOptions: {
    failType: LIST_ERROR,
    successType: LIST_SUCCESS,
  },
  validate({ getState, action }, allow, reject) {
    const state = getState();

    // only load if list is not loaded
    if (_.isEmpty(state.jobCategories.list)) {
      return allow(action);
    }

    return reject();
  },
  process({ getState }) {
    const language = languageSelector(getState());
    return list(language);
  },
});

export default [
  getAllC1CategoryTreeLogic,
  getCategoryTreeLogic,
  getDetailLogic,
  listCategoriesLogic,
];
