/* eslint-disable no-restricted-properties */
/* eslint-disable no-implicit-coercion */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable sort-keys */
/* eslint-disable no-console */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-var-requires */
import http from 'http';

import _ from 'lodash';
import axios from 'axios';

import { getPersistentTracingIdentifiers } from './utils/telemetry';

let apiCacheGet = null;
let apiCacheSet = null;
if (typeof window === 'undefined') {
  apiCacheGet = require('./z-api-cache').get;
  apiCacheSet = require('./z-api-cache').set;
}

// TODO remove this
export function getInOneLanguage(obj, language) {
  if (!_.isObject(obj) && !_.isArray(obj)) {
    return obj;
  }

  if (_.isArray(obj)) {
    return _.map(obj, (res) => getInOneLanguage(res, language));
  }

  return _.mapValues(obj, (v, key) => {
    if (_.isObject(v)) {
      if (key === 'languages') {
        return v;
      }
      if (!_.isUndefined(v[language])) {
        return v[language];
      }
      if (!_.isUndefined(v.en) || !_.isUndefined(v.ja)) {
        return '';
      }
      return getInOneLanguage(v, language);
    }

    return v;
  });
}

/** @see {@link https://github.com/vercel/next.js/issues/5354#issuecomment-520305040} */
const apiHost =
  typeof window === 'undefined'
    ? process.env.API_HOST_SERVER_TO_SERVER ?? process.env.API_HOST
    : process.env.API_HOST;

const instance = axios.create(getInstanceOptions());

if (process.env.API_CACHE_ENABLED === 'true' && typeof window === 'undefined')
  instance.interceptors.request.use(
    async (request) => {
      /* console.log('request => ', request.url,
    'headers => ', request.headers,
    'language => ', getLanguageHeaderAsString(request.headers)); */

      if (request.method === 'get') {
        const lang = getLanguageHeaderAsString(request.headers);
        const cacheResult = await apiCacheGet(
          request.url,
          request.params,
          getLanguageHeaderAsString(request.headers)
        );
        if (cacheResult && cacheResult.cached === true) {
          request.data = cacheResult.data;
          request.adapter = () =>
            Promise.resolve({
              data: cacheResult.data,
              status: 200,
              statusText: ''.concat(cacheResult.statusText, '--CACHE'),
              headers: cacheResult.headers,
              config: request,
              request,
            });
        }
      }
      return request;
    },
    (error) => Promise.reject(error)
  );

if (typeof window !== 'undefined') {
  instance.interceptors.request.use(
    (config) => {
      instance.defaults.headers.common = {
        ...instance.defaults.headers.common,
        ..._.omitBy(getPersistentTracingIdentifiers(), _.isNil),
      };
      return config;
    },
    (error) => Promise.reject(error)
  );
}

instance.interceptors.response.use((response) => {
  const language = getLanguageHeaderAsString(response.config.headers);
  // HACK: avoid losing pro profile's description. This should be done on API instead
  if (response.config.url.match(/\/pros\//) || response.config.url.match(/\/itp-users\//)) {
    const description = _.get(response.data, 'proProfile.description');
    if (description) {
      const otherLang = language === 'en' ? 'ja' : 'en';
      _.set(
        response.data,
        'proProfile.description',
        description[language] || description[otherLang]
      );
    }
  }
  response.data = getInOneLanguage(response.data, language);

  if (!(response.config.method === 'get' && response.status === 200)) {
    return response;
  }

  if (response.statusText.endsWith('--CACHE')) {
    response.statusText = response.statusText.substring(0, response.statusText.length - 7);
    return response;
  }

  if (typeof window === 'undefined') {
    apiCacheSet(
      response.config.url,
      response.config.params,
      language,
      response.data,
      response.headers,
      response.statusText
    );
  }

  return response;
});

export function removeHeaders(headers = []) {
  instance.defaults.headers.common = _.omit(instance.defaults.headers.common, headers);
}

export function setHeaders({ language, fingerprint, token } = {}) {
  const configs = _.omitBy(
    {
      'x-ui-language': language,
      'x-device': fingerprint,
      'x-access-token': token,
    },
    _.isUndefined
  );
  instance.defaults.headers.common = {
    ...instance.defaults.headers.common,
    ...configs,
  };
}

export function getLanguageHeader(language) {
  return {
    'x-ui-language': language,
  };
}

export function getHeaders(...headers) {
  return _.pick(instance.defaults.headers.common, headers);
}

function getInstanceOptions() {
  const options = {
    baseURL: `${apiHost}/api`,
    transformResponse: [
      (data) => {
        let parsedData;
        try {
          parsedData = JSON.parse(data);
        } catch (err) {
          // eslint-disable-line
        }
        return !!data && !parsedData ? data : parsedData;
      },
    ],
  };

  if (typeof window === 'undefined') options.httpAgent = new http.Agent({ keepAlive: true });

  return options;
}

function getLanguageHeaderAsString(headers) {
  if (!headers) return '';

  if (headers['x-ui-language']) {
    return headers['x-ui-language'];
  }

  if (headers.common?.['x-ui-language']) return headers.common['x-ui-language'];

  return '';
}

export default instance;
