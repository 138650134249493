import * as Sentry from '@sentry/nextjs';

interface RequestData {
  clientId: string;
  jobTypeFieldsVersion: number;
  jobTypeId: string;
  request: Record<string, unknown> | undefined;
}

class SentryHelper {
  private static sanitizeRequest(request: Record<string, unknown> = {}): Record<string, unknown> {
    const sanitizeList = new Set([
      'email',
      'name',
      'phoneNumber',
      'additionalText',
      'placeDescription',
      'address',
      'age',
      'gender',
      'password',
      'credential',
      'loginPhoneNumber',
      'phoneNumberVerificationToken',
    ]);
    const sanitizedRequest = {
      ...request,
    };
    for (const key of Object.keys(sanitizedRequest)) {
      if (sanitizeList.has(key) || key.toLowerCase().includes('text')) {
        sanitizedRequest[key] = 'sanitized';
      }
    }
    return sanitizedRequest;
  }

  static captureRequestException(
    error: Error | string,
    { jobTypeFieldsVersion, jobTypeId, clientId, request }: RequestData
  ) {
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Fatal);
      Sentry.setContext('request_context', {
        jobTypeFieldsVersion,
        jobTypeId,
        clientId,
        request: SentryHelper.sanitizeRequest(request),
      });
      Sentry.setTag('api', '/request');
      Sentry.captureException(error);
    });
  }
}

export default SentryHelper;
