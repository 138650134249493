import _ from 'lodash';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { createSelector } from 'reselect';

import { RootState } from 'store/types';
import { jobTypeSelector } from 'modules/job-types/duck';
import { translationSelector } from 'modules/translations/duck';

dayjs.extend(duration);

export const jobTypeTranslationSelector = createSelector(
  jobTypeSelector,
  translationSelector,
  (jobType, translation) => {
    const jobName = jobType?.translations?.requestType;
    return _.mapValues(translation, (val) =>
      typeof val === 'string' ? val.replace('%proType%', jobName ?? '') : val
    );
  }
);

const newRequestSelector = (state: RootState) => state.requests;

export const createdRequestSelector = createSelector(
  newRequestSelector,
  (requests) => requests.createdRequest
);

export const expirationDurationSelector = createSelector(
  createdRequestSelector,
  (createdRequest) => {
    if (!createdRequest) {
      return 0;
    }

    const createdTime = dayjs(createdRequest.createdTime);
    const expirationTime = dayjs(createdRequest.expirationTime);
    const durationTime = dayjs.duration(expirationTime.diff(createdTime));
    const result = Math.floor(durationTime.asHours());
    return result;
  }
);

export const newRequestModalSelector = (state: RootState) => state.requests.newRequestModal;

export const selectedProIdSelector = (state: RootState) =>
  state.requests.newRequestModal?.selectedProId;
