import { ConvertExperimentEnvironment, Environment } from './types.d';

export enum Experiment {
  BiggerNextRequestButton = 'BIGGER_NEXT_REQUEST_BUTTON',
  BlogValueProposition = 'BLOG_VALUE_PROPOSITION',
  BookThisPro = 'BOOK_THIS_PRO',
  BookThisProServiceProfile = 'BOOK_THIS_PRO_SERVICE_PROFILE',
  ChatFlow = 'CHAT_FLOW',
  ChatFlowHomeReformGeneral = 'CHAT_FLOW_HOME_REFORM_GENERAL',
  DigitalGiftCampaign = 'DIGITAL_GIFT_CAMPAIGN',
  DisplayTiming = 'DISPLAY_TIMING',
  MikittyVertical = 'MIKITTY_VERTICAL',
  PhoneLogin = 'PHONE_LOGIN',
  PhoneRequired = 'PHONE_REQUIRED',
  PricingValueProposition = 'PRICING_VALUE_PROPOSITION',
  Progress100BeforeEmail = 'PROGRESS_100_BEFORE_EMAIL',
  RequiredAdditionalText = 'REQUIRED_ADDITIONAL_TEXT',
  SecureDataUsageValueProposition = 'SECURE_DATA_USAGE_VALUE_PROPOSITION',
}

export enum PhoneLoginVariants {
  original = 'original',
  phoneLogin = 'phoneLogin',
}

export enum PhoneRequiredVariants {
  original = 'original',
  phoneRequired = 'phoneRequired',
}

export enum ChatFlowVariants {
  chat = 'chat',
  hybridChat = 'hybridChat',
  original = 'original',
}

export enum PricingValuePropositionVariants {
  interstitialStepPricing = 'interstitialStepPricing',
  landingPagePricing = 'landingPagePricing',
  lpAndInterstitialStepValuePricing = 'lpAndInterstitialStepValuePricing',
  original = 'original',
}

export enum BlogCTAValuePropositionVariants {
  prod4477Original = 'prod4477Original',
  prod4477ValueProposition = 'prod4477ValueProposition',
  prod4477ValuePropositionAddressInput = 'prod4477ValuePropositionAddressInput',
}

export enum SecureDataUsageValuePropositionVariants {
  secureDataValuePropOriginal = 'secureDataValuePropOriginal',
  secureDataValuePropVariant = 'secureDataValuePropVariant',
}

export enum DisplayTimingVariants {
  displayTimingOriginal = 'displayTimingOriginal',
  displayTimingVariant = 'displayTimingVariant',
}

export enum MikittyVerticalVariants {
  mikitty = 'mikitty',
  original = 'original',
}

export enum BiggerNextRequestButtonVariants {
  BiggerNextRequestButtonOriginal = 'BiggerNextRequestButtonOriginal',
  BiggerNextRequestButtonVariant = 'BiggerNextRequestButtonVariant',
}

export enum Progress100BeforeEmailVariants {
  original = 'original',
  progress100BeforeEmail = 'progress100BeforeEmail',
}

export enum RequiredAdditionalTextVariants {
  requiredAdditionalTextOriginal = 'requiredAdditionalTextOriginal',
  requiredAdditionalTextVariant = 'requiredAdditionalTextVariant',
}

export enum DigitalGiftCampaignVariants {
  displayGiftCampaign = 'displayGiftCampaign',
  original = 'original',
  readOnlyIntroStepCampaign = 'readOnlyIntroStepCampaign',
}

export enum BookThisProVariants {
  bookThisPro = 'bookThisPro',
  original = 'original',
}

export enum BookThisProServiceProfileVariants {
  bookThisPro = 'bookThisPro',
  original = 'original',
}

export type VariantsMap = {
  [Experiment.PhoneLogin]: typeof PhoneLoginVariants;
  [Experiment.ChatFlowHomeReformGeneral]: typeof ChatFlowVariants;
  [Experiment.PricingValueProposition]: typeof PricingValuePropositionVariants;
  [Experiment.PhoneRequired]: typeof PhoneRequiredVariants;
  [Experiment.BlogValueProposition]: typeof BlogCTAValuePropositionVariants;
  [Experiment.SecureDataUsageValueProposition]: typeof SecureDataUsageValuePropositionVariants;
  [Experiment.ChatFlow]: typeof ChatFlowVariants;
  [Experiment.DisplayTiming]: typeof DisplayTimingVariants;
  [Experiment.MikittyVertical]: typeof MikittyVerticalVariants;
  [Experiment.BiggerNextRequestButton]: typeof BiggerNextRequestButtonVariants;
  [Experiment.Progress100BeforeEmail]: typeof Progress100BeforeEmailVariants;
  [Experiment.RequiredAdditionalText]: typeof RequiredAdditionalTextVariants;
  [Experiment.DigitalGiftCampaign]: typeof DigitalGiftCampaignVariants;
  [Experiment.BookThisPro]: typeof BookThisProVariants;
  [Experiment.BookThisProServiceProfile]: typeof BookThisProServiceProfileVariants;
};

export const CONFIGS: {
  [E in Experiment]: {
    [env in Environment]: ConvertExperimentEnvironment;
  };
} = {
  [Experiment.PhoneLogin]: {
    [Environment.PreProd]: {
      experimentId: '100414264',
      variants: {
        original: '100440353',
        phoneLogin: '100440354',
      },
    },
    [Environment.Prod]: {
      experimentId: '100414265',
      variants: {
        original: '100440355',
        phoneLogin: '100440356',
      },
    },
  },
  [Experiment.PricingValueProposition]: {
    [Environment.PreProd]: {
      experimentId: '100410330',
      variants: {
        original: '100431069',
        landingPagePricing: '100431070',
        interstitialStepPricing: '100431071',
        lpAndInterstitialStepValuePricing: '100431075',
      },
    },
    [Environment.Prod]: {
      experimentId: '100410612',
      variants: {
        original: '100431705',
        landingPagePricing: '100431706',
        interstitialStepPricing: '100431709',
        lpAndInterstitialStepValuePricing: '100431710',
      },
    },
  },
  [Experiment.PhoneRequired]: {
    [Environment.PreProd]: {
      experimentId: '100410933',
      variants: {
        original: '100432464',
        phoneRequired: '100432465',
      },
    },
    [Environment.Prod]: {
      experimentId: '100410934',
      variants: {
        original: '100432466',
        phoneRequired: '100432467',
      },
    },
  },
  [Experiment.BlogValueProposition]: {
    [Environment.PreProd]: {
      experimentId: '100412017',
      variants: {
        prod4477Original: '100434851',
        prod4477ValuePropositionAddressInput: '100434852',
        prod4477ValueProposition: '100434854',
      },
    },
    [Environment.Prod]: {
      experimentId: '100412666',
      variants: {
        prod4477Original: '100436505',
        prod4477ValuePropositionAddressInput: '100436506',
        prod4477ValueProposition: '100436507',
      },
    },
  },
  [Experiment.RequiredAdditionalText]: {
    [Environment.PreProd]: {
      experimentId: '100498459',
      variants: {
        requiredAdditionalTextOriginal: '1004236239',
        requiredAdditionalTextVariant: '1004236240',
      },
    },
    [Environment.Prod]: {
      experimentId: '100498466',
      variants: {
        requiredAdditionalTextOriginal: '1004236255',
        requiredAdditionalTextVariant: '1004236256',
      },
    },
  },
  [Experiment.SecureDataUsageValueProposition]: {
    [Environment.PreProd]: {
      experimentId: '100414279',
      variants: {
        secureDataValuePropOriginal: '100440392',
        secureDataValuePropVariant: '100440393',
      },
    },
    [Environment.Prod]: {
      experimentId: '100414281',
      variants: {
        secureDataValuePropOriginal: '100440396',
        secureDataValuePropVariant: '100440397',
      },
    },
  },
  [Experiment.ChatFlow]: {
    [Environment.PreProd]: {
      experimentId: '100414505',
      variants: {
        original: '100440873',
        chat: '100440874',
        hybridChat: '100440876',
      },
    },
    [Environment.Prod]: {
      experimentId: '100414506',
      variants: {
        original: '100440878',
        chat: '100440879',
        hybridChat: '100440880',
      },
    },
  },
  [Experiment.MikittyVertical]: {
    [Environment.PreProd]: {
      experimentId: '100414287',
      variants: {
        mikitty: '100440410',
        original: '100440409',
      },
    },
    [Environment.Prod]: {
      experimentId: '100414518',
      variants: {
        mikitty: '100440915',
        original: '100440914',
      },
    },
  },
  [Experiment.DisplayTiming]: {
    [Environment.PreProd]: {
      experimentId: '100413619',
      variants: {
        displayTimingOriginal: '100438629',
        displayTimingVariant: '100438630',
      },
    },
    [Environment.Prod]: {
      experimentId: '100414698',
      variants: {
        displayTimingOriginal: '100441306',
        displayTimingVariant: '100441307',
      },
    },
  },
  [Experiment.BiggerNextRequestButton]: {
    [Environment.PreProd]: {
      experimentId: '100419794',
      variants: {
        BiggerNextRequestButtonOriginal: '100453026',
        BiggerNextRequestButtonVariant: '100453027',
      },
    },
    [Environment.Prod]: {
      experimentId: '100419328',
      variants: {
        BiggerNextRequestButtonOriginal: '100452019',
        BiggerNextRequestButtonVariant: '100452020',
      },
    },
  },
  [Experiment.ChatFlowHomeReformGeneral]: {
    [Environment.PreProd]: {
      experimentId: '100430663',
      variants: {
        original: '100478960',
        chat: '100478961',
      },
    },
    [Environment.Prod]: {
      experimentId: '100430665',
      variants: {
        original: '100478964',
        chat: '100478965',
      },
    },
  },
  [Experiment.Progress100BeforeEmail]: {
    [Environment.PreProd]: {
      experimentId: '100436106',
      variants: {
        original: '100491537',
        progress100BeforeEmail: '100491538',
      },
    },
    [Environment.Prod]: {
      experimentId: '100436107',
      variants: {
        original: '100491539',
        progress100BeforeEmail: '100491540',
      },
    },
  },
  [Experiment.DigitalGiftCampaign]: {
    [Environment.PreProd]: {
      experimentId: '100499641',
      variants: {
        original: '1004239125',
        displayGiftCampaign: '1004239126',
        seeMoreRequestIntro: '1004246128',
      },
    },
    [Environment.Prod]: {
      experimentId: '100499645',
      variants: {
        original: '1004239134',
        displayGiftCampaign: '1004239135',
        seeMoreRequestIntro: '1004246853',
      },
    },
  },
  [Experiment.BookThisPro]: {
    [Environment.PreProd]: {
      experimentId: '1004104994',
      variants: {
        original: '1004251274',
        bookThisPro: '1004251275',
      },
    },
    [Environment.Prod]: {
      experimentId: '1004104995',
      variants: {
        original: '1004251276',
        bookThisPro: '1004251277',
      },
    },
  },
  [Experiment.BookThisProServiceProfile]: {
    [Environment.PreProd]: {
      experimentId: '1004106263',
      variants: {
        original: '1004254101',
        bookThisPro: '1004254102',
      },
    },
    [Environment.Prod]: {
      experimentId: '1004106264',
      variants: {
        original: '1004254103',
        bookThisPro: '1004254104',
      },
    },
  },
};

export enum Goal {
  additionalTextPassed = 'additionalTextPassed',
  loginStep1Passed = 'loginStep1Passed',
  matchingAnimationViewed = 'matchingAnimationViewed',
  nameClicked = 'nameClicked',
  newRequestCreated = 'newRequestCreated',
  newRequestSubmitted = 'newRequestSubmitted',
  phoneNumberClicked = 'phoneNumberClicked',
  startNewRequestClicked = 'startNewRequestClicked',
}

export const GOAL_CONFIGS: { [env in Environment]: { [goal in Goal]: string } } = {
  [Environment.PreProd]: {
    [Goal.loginStep1Passed]: '10049114',
    [Goal.matchingAnimationViewed]: '100412809',
    [Goal.nameClicked]: '100412415',
    [Goal.newRequestCreated]: '10049115',
    [Goal.newRequestSubmitted]: '100412414',
    [Goal.phoneNumberClicked]: '100412419',
    [Goal.startNewRequestClicked]: '10049465',
    [Goal.additionalTextPassed]: '100464651',
  },
  [Environment.Prod]: {
    [Goal.loginStep1Passed]: '10049116',
    [Goal.matchingAnimationViewed]: '100412810',
    [Goal.nameClicked]: '100412416',
    [Goal.newRequestCreated]: '10049117',
    [Goal.newRequestSubmitted]: '100412417',
    [Goal.phoneNumberClicked]: '100412418',
    [Goal.startNewRequestClicked]: '10049470',
    [Goal.additionalTextPassed]: '100464652',
  },
};
