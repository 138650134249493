// Request properties don't have a detailed type yet
/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
import _ from 'lodash';

import httpClient, { getLanguageHeader } from 'src/http-client';
import { getItem } from 'utils/webStorage';
import { getSessionContext, removeSessionContext } from 'utils/session-context';
import type { AxiosError } from 'axios';

import { setItem } from 'utils/localStorage';
import { execute as executeRecaptcha } from '../../services/recaptcha';
import {
  makeContextHeader,
  makeRequestFormClientSourceString,
  makeSourceHeader,
} from 'utils/custom-headers';
import type { Context } from '@z-components/utils/oauth';
import { removeUndefinedProperties } from 'utils/data-transform/object';
import Cookies from '@z-components/utils/cookies';
import { REQUEST_FORM_COOKIE, SEPARATE_REQUEST_FORM_PATH } from 'constants/request-form';
import RequestFormAnalytics from 'utils/analytics/request-form';
import { deprecatedGetAliasProperties } from 'src/services/job-type-alias/helpers';

export function getUtm() {
  const utm = getItem('ngStorage-utm');
  if (utm) {
    try {
      return JSON.parse(utm) as Record<string, string>;
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
}

export async function createRequest({
  captchaResponse,
  clientId,
  context,
  isDirectBooking,
  jobTypeFieldsVersion,
  jobTypeId,
  originJobTypeId,
  language,
  request,
  mode,
  selectedProId,
}: {
  captchaResponse?: string;
  clientId?: string;
  context?: Context;
  isDirectBooking: boolean;
  jobTypeFieldsVersion?: number;
  jobTypeId: string;
  language: string;
  mode?: string;
  originJobTypeId?: string;
  selectedProId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: Record<string, any>;
}) {
  const storageAuthData = getItem('ngStorage-authData') as string;

  const { token } = JSON.parse(storageAuthData) as { token: string };

  const requestFieldsToOmit = [
    'email',
    'password',
    'name',
    'phoneNumber',
    'phoneNumberShareable',
    'lineSocialLogin',
    'credential',
    'loginPhoneNumber',
    'phoneNumberVerificationToken',
  ];
  if (!isDirectBooking) requestFieldsToOmit.push('placeDescription');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formattedRequest = _.omit(request, requestFieldsToOmit) as Record<string, any>;
  if (formattedRequest.dateTime?.timeRange) {
    const { timeRange } = request.dateTime;
    formattedRequest.dateTime.timeRange = { end: timeRange[1], start: timeRange[0] };
    formattedRequest.dateTime.type = formattedRequest.dateTime.type || 'timeRange';
  }

  const sessionContext = getSessionContext();

  const postData = {
    client: clientId,
    clientLanguages: [language],
    fields: formattedRequest,
    jobTypeFieldsVersion,
    originProType: originJobTypeId,
    proType: jobTypeId,
    selectedProId,
    sent: false,
    sessionContext,
    status: '',
    utm: getUtm(),
  };

  const headers: { [name: string]: string } = {
    'x-access-token': token,
    'x-client': 'z-web',
    ...makeContextHeader(context),
    ...makeSourceHeader(makeRequestFormClientSourceString({ mode })),
  };

  if (captchaResponse) {
    headers['recaptcha-response'] = captchaResponse;
  }

  const recaptchaV3Token = await executeRecaptcha('submitRequestForm');

  if (recaptchaV3Token) {
    headers['recaptcha-v3-token'] = recaptchaV3Token;
  }

  const { data: createdRequest } = await httpClient.post<{
    _id: string;
    bookingId: string;
    fields: { address: unknown };
  }>('/requests', postData, {
    headers,
  });

  removeSessionContext();

  return { createdRequest, token, uid: clientId };
}

export function loadProCount(
  {
    cityKey,
    jobTypeId,
    prefectureKey,
    zipCode,
  }: { cityKey: string; jobTypeId: string; prefectureKey: string; zipCode: string },
  language: string
) {
  const option = {
    headers: getLanguageHeader(language),
    params: { cityKey, prefectureKey, zipCode },
  };

  return (
    httpClient
      .get(`/job-types/${jobTypeId}/pro-count`, option)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .then((response) => response.data)
  );
}

export function getDuplicateRequest(currentUser: { id: string } | null, jobTypeId: string) {
  if (!currentUser) {
    return null;
  }
  const { id: uid } = currentUser;
  return (
    httpClient
      .get(`/users/${uid}/has-duplicate-request/${jobTypeId}`)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .then((response) => response.data)
  );
}

export interface PublicRequestItem {
  key: string;
  label: string;
  type: string;
  value: Array<LocalizedStrings> | string | RequestDateTime;
}
export interface PublicRequest {
  categories?: Array<string>;
  city?: string;
  fields: Array<PublicRequestItem>;
  id: string;
  jobType: string;
  jobTypeId: string;
  prefecture?: string;
}
interface PublicRequestError {
  error: number;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPublicDetailsError = (obj: any): obj is PublicRequestError =>
  obj !== null && typeof obj === 'object' && 'error' in obj;
export async function getPublicRequestDetails({
  requestId,
}: {
  requestId: string;
}): Promise<PublicRequest | PublicRequestError> {
  try {
    const { data: publicRequest } = await httpClient.get<PublicRequest>(
      `/requests/${requestId}/public`
    );
    return publicRequest;
  } catch (error) {
    const axiosError = error as AxiosError;
    // eslint-disable-next-line no-magic-numbers
    return { error: axiosError.response?.status ?? 500 };
  }
}

import { AnyAction, Dispatch } from 'redux';
import {
  setNewRequestModalContext,
  setNewRequestModalPerfTimings,
  setNewRequestModalProperties,
} from 'modules/requests/duck';
import { setShowingRequestModal } from 'modules/layout/duck';
import { triggerGoal, Goal } from 'utils/convert';

export function openNewRequestModal({
  dispatch,
  properties,
  context,
}: {
  context?: Parameters<typeof setNewRequestModalContext>[0];
  dispatch: Dispatch<AnyAction>;
  properties: Parameters<typeof setNewRequestModalProperties>[0];
}) {
  triggerGoal(Goal.startNewRequestClicked);
  const launchedAt = Date.now();
  const modalProperties = { ...properties };
  const isSeparateRequestFormTestVariant = checkIsSeparateRequestFormTestVariant();
  let apiCallCompletedAt;

  if (properties?.jobTypeId) {
    RequestFormAnalytics.startNewRequestIntent(properties.jobTypeId);
    if (isSeparateRequestFormTestVariant && typeof window !== 'undefined') {
      // short circuits to separate page
      return handleSeparatePageNavigation(properties.jobTypeId, {
        requestFormContext: {
          address: properties.initialValues?.address,
          city: context?.city,
          cityGroupName: context?.cityGroupName,
          prefecture: context?.prefecture,
        },
        // Technically the spread operator should be enough here
        // but have left the existing properties as is and
        // using spread for the remaining
        requestFormInitialValues: {
          address: properties.initialValues?.address,
          age: properties.initialValues?.age,
          propertyType: properties.initialValues?.propertyType,
          ...properties.initialValues,
        },
      });
    }

    // TODO If and when the value of jobtype-alias changes,
    //  or when it is time to separate the Request form,
    //  use the cache to call out the jobTypeAliasesService
    // jobTypeAliases = await jobTypeAliasesService.getJobTypeAliases();
    const jobTypeAliases = [
      { alias: 'home-reform-general', jobTypeId: 'carpenter' },
      { alias: 'home-reform-general', jobTypeId: 'civil-construction-specialist' },
      { alias: 'home-reform-general', jobTypeId: 'design-renovation-specialist' },
      { alias: 'home-reform-general', jobTypeId: 'home-reform-specialist' },
      { alias: 'home-reform-general', jobTypeId: 'interiorfinishingconstruction' },
    ];

    apiCallCompletedAt = Date.now();

    deprecatedGetAliasProperties(properties.jobTypeId, jobTypeAliases, modalProperties);
  }

  if (context) {
    dispatch(setNewRequestModalContext(context));
  }
  dispatch(setNewRequestModalPerfTimings({ apiCallCompletedAt, launchedAt }));
  dispatch(setNewRequestModalProperties(modalProperties));
  dispatch(setShowingRequestModal(true));
}

function checkIsSeparateRequestFormTestVariant(): string | null {
  // @ts-expect-error access is safe
  if (window.google_optimize === undefined) {
    return null;
  }
  // @ts-expect-error call is safe
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return (window.google_optimize.get('jW_7GNtzRWalcvcRkFiMpQ') as string) === '1';
}

export function handleSeparatePageNavigation(
  jobTypeId: string,
  { requestFormContext, requestFormInitialValues }: RequestFormCookie
) {
  const rfObject = {
    requestFormContext: removeUndefinedProperties(requestFormContext),
    requestFormInitialValues: removeUndefinedProperties(requestFormInitialValues),
  };
  const stringifiedRFObject = JSON.stringify(rfObject);
  setItem(REQUEST_FORM_COOKIE, stringifiedRFObject);
  Cookies.setCookie(
    {
      key: REQUEST_FORM_COOKIE,
      value: encodeURIComponent(stringifiedRFObject),
    },
    { url: window.location.href }
  );
  window.location.href = `${SEPARATE_REQUEST_FORM_PATH}/${jobTypeId}`;
}
