import type {
  ResolvedLocation,
  CityGroupingLocationResolver,
} from 'src/services/city-grouping/types';

import { captureMessage } from '@sentry/nextjs';

import data from './data.json';

type MajorCity = {
  cityGroupChildren?: Array<string>;
  cityKeys: Array<string>;
  key: string;
  name: LocalizedStrings;
  overlappingCityGroupKeys?: Array<string>;
  prefectureKey: string;
};

/**
 * Need something that can allow MajorCityService to answer whether a vertical is grouping enabled
 */
interface VerticalGroupingStateResolver {
  isVerticalCityGroupEnabled(jobTypeId: string): boolean;
}

class MajorCityService implements CityGroupingLocationResolver {
  private data: Array<MajorCity>;
  private cityToMajorCityMap: Map<string, MajorCity>;
  public verticalGroupingStateResolver?: VerticalGroupingStateResolver;

  constructor(verticalGroupingResolver?: VerticalGroupingStateResolver) {
    this.data = data;
    this.cityToMajorCityMap = new Map<string, MajorCity>();
    this.initializeCityToMajorCityMap();
    this.verticalGroupingStateResolver = verticalGroupingResolver;
  }

  resolveServiceAreaName(key: string, language: 'en' | 'ja'): Promise<string> {
    const majorCity = this.getMajorCityFromKey(key);
    if (!majorCity) {
      return Promise.resolve('');
    }
    const value = majorCity.name[language];
    return Promise.resolve(value ?? '');
  }

  /**
   * Initializes the vertical state grouping resolver to a hard coded value that represents
   * whether vertical grouping is enabled.
   *
   * @param isGroupingEnabled
   * @returns {void}
   */
  initializeVerticalGroupingStateResolverIfRequired(
    checkIsGroupingEnabled?: (jobTypeId: string) => boolean
  ) {
    if (this.verticalGroupingStateResolver) {
      return;
    }
    if (checkIsGroupingEnabled === undefined) {
      captureMessage('isGroupingEnabled is undefined. Had to default. Unexpected.');
    }
    this.verticalGroupingStateResolver = {
      isVerticalCityGroupEnabled: checkIsGroupingEnabled ?? (() => false),
    };
  }

  /**
   * Clientside grouping state should be precomputed as a prop and passed to the client.
   * @param isGroupingEnabled {boolean | undefined}
   * @returns {void}
   */
  initializeVerticalGroupingStateResolverClientside(isGroupingEnabled?: boolean) {
    const input = isGroupingEnabled === undefined ? undefined : () => isGroupingEnabled;
    this.initializeVerticalGroupingStateResolverIfRequired(input);
  }

  /**
   * Invertedness comes from a cityGroup being a child of a major city.
   * These should be exceptions
   *
   * @param cityGroupKey
   */
  isCityGroupInverted(cityGroupKey?: string) {
    if (!cityGroupKey) {
      return false;
    }
    return this.data.some((majorCity) => majorCity.cityGroupChildren?.includes(cityGroupKey));
  }

  /**
   * Only majorCities with cityGroupChildren are considered "parents" of cityGroups.
   * For the majority of cases majorCity and cityGroup should be considered siblings.
   *
   * EX Scenario:
   * yokohama-shi is a majorCity with cityGroupChildren including
   *  - yokohama-chuushinbu
   * Here yokohama-chuushinbu is a part of yokohama-shi thus yokohama-shi is the parent.
   * Another way of describing this relationship is that yokohama-chuushinbu is an "inverted cityGroup"
   *
   * @param cityGroupKey
   */
  getMajorCityParentFromCityGroupKey(cityGroupKey?: string): MajorCity | undefined {
    if (!cityGroupKey) {
      return undefined;
    }
    return this.data.find((majorCity) => majorCity.cityGroupChildren?.includes(cityGroupKey));
  }

  /**
   * Resolves the majorCity as a cityGroup.
   * If this is called and there is no majorCityKey
   *
   * @param jobTypeId
   * @param prefectureKey
   * @param _groupKeyOrCityKey
   * @param _groupedCityKey
   * @param majorCityKey
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/require-await
  async resolveLocation(
    jobTypeId: string,
    prefectureKey?: string,
    _groupKeyOrCityKey?: string,
    _groupedCityKey?: string,
    majorCityKey?: string
  ): Promise<ResolvedLocation> {
    const majorCity = this.getMajorCityFromKey(majorCityKey);
    if (!majorCity) {
      return { redirectTo: 'prefecture' };
    }

    // when the major city prefecture does not match the input prefecture
    // we are dealing with an invalid location so this could arguably be a redirect up or 404
    // 404 for now as the resource of major city does not exist on the path likely fetched
    if (majorCity.prefectureKey !== prefectureKey) {
      return {
        notFound: true,
      };
    }
    // when on a grouped vertical AND there is overlap then there should be an existing city group
    // that serves the intended purpose of a majorCity so this page should not exist in order
    // to avoid duplicate content
    if (this.doesMajorCityOverlapWithCityGroup(majorCity.key, jobTypeId)) {
      return {
        notFound: true,
      };
    }
    if (!this.verticalGroupingStateResolver) {
      this.initializeVerticalGroupingStateResolverIfRequired();
    }

    return {
      cityKey: undefined,
      groupKey: majorCity.key,
      isCityGroupingEnabled:
        this.verticalGroupingStateResolver?.isVerticalCityGroupEnabled(jobTypeId),
    };
  }

  /**
   * Does the majorCity overlap with ANY cityGroups
   *
   * @param majorCityKey {string}
   * @param jobTypeId {string}
   * @returns {boolean}
   */
  doesMajorCityOverlapWithCityGroup(majorCityKey: string, jobTypeId: string) {
    const majorCity = this.getMajorCityFromKey(majorCityKey);
    if (!majorCity) {
      return false;
    }
    if (!this.verticalGroupingStateResolver) {
      this.initializeVerticalGroupingStateResolverIfRequired();
    }
    return (
      (majorCity.overlappingCityGroupKeys?.length ?? 0) > 0 &&
      this.verticalGroupingStateResolver?.isVerticalCityGroupEnabled(jobTypeId)
    );
  }

  /**
   * Determine whether a city is a child of a majorCity that overlaps
   *
   * @param cityGroupKey
   * @returns {boolean}
   */
  isCityChildOfOverlappingCityGroup(cityKey?: string): boolean {
    if (!cityKey) {
      return false;
    }

    const parent = this.data.find((majorCity) => majorCity.cityKeys.includes(cityKey));
    return Boolean(parent?.overlappingCityGroupKeys?.length);
  }

  getMajorCityFromKey(majorCityKey?: string): MajorCity | undefined {
    return this.data.find((majorCity) => majorCity.key === majorCityKey);
  }
  /**
   * Get the major city for the city if it exists.
   *
   * @param cityKey
   */
  getMajorCityFromCityKey(cityKey?: string): MajorCity | undefined {
    if (!cityKey) {
      return undefined;
    }
    return this.cityToMajorCityMap.get(cityKey);
  }

  /**
   * Get major city by prefecture
   *
   * @param prefectureKey
   */
  getMajorCityFromPrefectureKey(prefectureKey: string): MajorCity | undefined {
    return this.data.find((majorCity) => majorCity.prefectureKey === prefectureKey);
  }

  /**
   * Get major city that is the parent of the city and throw if it does not exist.
   *
   * @param cityKey
   * @returns
   */
  getMajorCityFromCityKeyOrThrow(cityKey?: string): MajorCity {
    const majorCity = this.getMajorCityFromCityKey(cityKey);
    if (!majorCity) {
      throw new Error(`Major city not found for city key: ${cityKey ?? 'undefined'}`);
    }
    return majorCity;
  }

  /**
   * Get a major city from a city group key.
   *
   * @param cityGroupKey
   * @returns
   */
  getMajorCityFromCityGroupKey(cityGroupKey?: string): MajorCity | undefined {
    if (!cityGroupKey) {
      return undefined;
    }
    return this.getMajorCityFromKey(cityGroupKey);
  }

  /**
   * Gets a majorCity from either a cityGroupKey that actually represents a majorCity
   * Or gets a majorCity based on the special case of a majorCity being a parent of a cityGroup
   *
   * @param cityGroupKey
   * @returns
   */
  getMajorCityOrMajorCityParentFromCityGroupKey(cityGroupKey?: string): MajorCity | undefined {
    return (
      this.getMajorCityFromCityGroupKey(cityGroupKey) ??
      this.getMajorCityParentFromCityGroupKey(cityGroupKey)
    );
  }
  /**
   * Extend a key of a major city to a major city object.
   * Throws if the major city does not exist as the expectation is the key is for a major city
   *
   * @param majorCityKey
   * @returns
   */
  extendMajorCityFromMajorCityKey(majorCityKey: string): MajorCity {
    const majorCity = this.data.find((majorCityModel) => majorCityModel.key === majorCityKey);
    if (!majorCity) {
      throw new Error(`Major city not found for city key: ${majorCityKey}`);
    }
    return majorCity;
  }

  /**
   * Invert relationship of city to major city to major city to city for easier lookups
   */
  private initializeCityToMajorCityMap() {
    this.data.forEach((majorCity) => {
      majorCity.cityKeys.forEach((cityKey) => {
        this.cityToMajorCityMap.set(cityKey, majorCity);
      });
    });
  }

  isChildOfMajorCity(cityKey: string): boolean {
    return this.cityToMajorCityMap.has(cityKey);
  }

  isCityGroupMajorCity(cityGroupKey?: string): boolean {
    return this.getMajorCityFromCityGroupKey(cityGroupKey) !== undefined;
  }

  /**
   * From some set of input components determine what parts of a URL should be generated for a major city page.
   * Does not generate the URL itself.
   * Nor parts related to subsequent parts of the URL.
   *
   * @param components
   * @param checkIsVerticalOnCityGroup
   * @param param2 - TODO: Delete once URL strategy is decided
   * @returns {Array<{ key: string; name: string }> | null}
   */
  getPartsForMajorCity(
    components: {
      city?: {
        key: string;
        name: string;
      };
      cityGroup?: {
        key: string;
        name: string;
      };
      prefecture?: {
        key: string;
        name: string;
      };
    },
    isGroupedVertical: () => boolean
  ) {
    const { prefecture, cityGroup, city } = components;
    // major cities parts are excluded when the vertical is grouped and there is overlap which
    // means that there is an existing city group that serves the purpose of the major city
    if (this.isCityChildOfOverlappingCityGroup(city?.key) && isGroupedVertical()) {
      return null;
    }
    // being flexible with the input here as it's not decided whether z-web will treat major cities as city groups
    // or whether they will be distinct entities, so check whether the cityGroup is actually a major city
    const majorCityFromCityGroup = this.getMajorCityOrMajorCityParentFromCityGroupKey(
      cityGroup?.key
    );

    const majorCityFromGroupOrMajorCityKey = majorCityFromCityGroup;
    const isCityChildOfMajorCity = city && this.isChildOfMajorCity(city.key);
    // if the city is a child of a major city we need to fetch the major city to insert a link back up
    // to the major city page which may not follow the same URL structure as the current URL
    const actualMajorCity = isCityChildOfMajorCity
      ? this.getMajorCityFromCityKey(city?.key)
      : majorCityFromGroupOrMajorCityKey;

    // two conditions for short circuiting
    // 1. if there is no major city to link to
    // 2. there is a city but the city is not a child of the major city so no part should be inserted
    if (!actualMajorCity || (city && !isCityChildOfMajorCity)) {
      return null;
    }

    const parts: Array<{ key: string; name: string }> = [];
    if (prefecture) {
      parts.push(prefecture);
    }

    const majorCityInComponentFormat = {
      key: actualMajorCity.key,
      name: actualMajorCity.name.ja ?? '',
    };
    const isVerticalCityGrouped = isGroupedVertical();
    if (!isVerticalCityGrouped) {
      // when the vertical isn't grouped (decided by the feature flag for cityGroupings)
      // the majorCity part is terminal
      parts.push(majorCityInComponentFormat);
      return parts;
    }

    // /{prefecture}/{majorCity}
    parts.push(majorCityInComponentFormat);

    return parts;
  }

  setVerticalGroupingStateResolver(
    func: VerticalGroupingStateResolver['isVerticalCityGroupEnabled']
  ) {
    this.verticalGroupingStateResolver = {
      isVerticalCityGroupEnabled: func,
    };
  }
}

const MajorCityServiceInstance = new MajorCityService();

export { MajorCityServiceInstance as MajorCityService };
