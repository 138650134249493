/**
 * WIP User class
 * Add more properties as needed
 * Add more helper methods as needed
 * Consumed via getCurrentUser selector
 */
import type { User as UserType } from 'modules/users/types';
import { fullNameJapanese } from 'modules/common/form-builder/validators';
export default class User {
  // NOTE: these are currently marked private to prevent direct access. The intent is to centralize
  // logic associated with user here not on logic added adhoc on publicly exposed properties
  private readonly id: string;
  private readonly proProfile?: Record<string, any>;
  private readonly profileComplete?: boolean;
  private readonly email?: string;
  private readonly proRegistrationStep?: string;
  private readonly requestCount?: number;
  private readonly profile?: UserType['profile'];
  private readonly phoneNumber?: string;
  private readonly phoneNumberShareable?: boolean;
  constructor(user: UserType) {
    this.id = user.id;
    this.proProfile = user.proProfile;
    this.profileComplete = user.profileComplete;
    this.profile = user.profile;
    this.email = user.email;
    this.proRegistrationStep = user.proRegistrationStep;
    this.requestCount = user.requestCount;
    this.phoneNumber = user.phoneNumber;
    this.phoneNumberShareable = user.phoneNumberShareable;
  }

  public getId() {
    return this.id;
  }

  public isPro() {
    return this.proProfile !== undefined;
  }

  public isClientOnly() {
    return !this.isPro();
  }

  public isProfileComplete() {
    return this.profileComplete;
  }

  public getEmail() {
    return this.email;
  }

  public getFullNameKanji() {
    return {
      last: this.profile?.fullNameKanji?.last ?? '',
      first: this.profile?.fullNameKanji?.first ?? '',
    };
  }

  public getPhoneNumber() {
    return this.phoneNumber;
  }

  public getPhoneNumberShareable() {
    return this.phoneNumberShareable;
  }

  public hasDroppedSocialRegistration() {
    return this.proRegistrationStep === 'socialAccountLinked' && !this.requestCount;
  }

  public static transformFullName(
    firstName: string | undefined,
    lastName: string | undefined,
    options: { nameProperty: 'fullNameKanji' | 'fullNameFurigana' | 'fullNameEnglish' }
  ) {
    const transformKey = options.nameProperty;
    const nameObject = { first: firstName ?? '', last: lastName ?? '' };
    return { [transformKey]: nameObject };
  }

  public static hasMinimumClientName(
    client: { profile?: { fullNameKanji?: { last?: string } } } | UserType | undefined
  ) {
    if (typeof client?.profile?.fullNameKanji?.last !== 'string') return false;
    const clientLastName = client.profile.fullNameKanji.last;
    return clientLastName.length >= 1 && !Array.isArray(fullNameJapanese(clientLastName));
  }
}
