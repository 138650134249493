import type { VerticalPageJobType } from 'modules/job-types/types';
import type { UpdateFiltersParams } from 'modules/marketing-pages/marketing-page.types';

export enum PillType {
  DayTime,
  Dropdown,
  Toggle,
}

export interface DropdownListItem {
  id: string;
  isItemSelected: boolean;
  key: string;
  label: string;
  onListValueSelected?: (itemId: string) => void;
  value: string;
}

export interface ScrollerItemData {
  dropdownList?: Array<DropdownListItem>;
  iconPosition?: 'left' | 'right';
  id: string;
  isToggleSelected?: boolean;
  key: string;
  link?: boolean;
  matchingCriteriaKey?: string;
  matchingCriteriaOptionValue?: string;
  onToggleValueChanged?: (scrollerItemData: ScrollerItemData) => void;
  pillIcon?: JSX.Element;
  pillText: string;
  type: PillType;
  value: string;
}

export enum DropdownItemTypes {
  Unselect = 'unselect',
}

export interface PillFiltersProps {
  jobType: VerticalPageJobType;
  onPillFilterChange: (filterUpdateParams: UpdateFiltersParams) => void;
  onPillFilterClick: (key: string, value: Array<string>) => void;
  pillFiltersData: Array<ScrollerItemData>;
}
