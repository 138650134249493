import _ from 'lodash';
import * as types from './types';

export const initialState = {
  // map to check if detail of job type with that id has been loaded
  hasDetails: {},
  list: {},
  listByCategory: {},
  popular: [],
  related: {},
  serviceOfTheMonth: [],
  verticalPageJobType: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line no-restricted-properties
        list: _.reduce(
          action.payload,
          (hash, jobType) => ({ ...hash, [jobType.id]: { ...state.list[jobType.id], ...jobType } }),
          state.list
        ),
      };

    case types.LIST_POPULAR_SUCCESS:
      return { ...state, popular: action.payload };

    case types.LIST_BY_CATEGORY_SUCCESS:
      return { ...state, listByCategory: action.payload };

    case types.GET_DETAIL_SUCCESS:
      return {
        ...state,
        hasDetails: { ...state.hasDetails, [action.payload.id]: true },
        list: {
          ...state.list,
          [action.payload.id]: { ...state.list[action.payload.id], ...action.payload },
        },
      };

    case types.LOAD_RELATED_SUCCESS:
      return {
        ...state,
        related: { ...state.related, [action.payload.id]: action.payload.related },
      };

    case types.LIST_SERVICE_OF_THE_MONTH_SUCCESS:
      return {
        ...state,
        serviceOfTheMonth: action.payload.serviceOfTheMonth,
      };
    case types.SET_VERTICAL_PAGE_JOB_TYPE: {
      return {
        ...state,
        verticalPageJobType: action.payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;
