import { getItem, removeItem, setItem } from './localStorage';

const CONTEXT_DATA_STORAGE_KEY = 'session-context';

interface SessionContext {
  landingUrl: string;
  pageName: string;
  requestCreatedFromUrl?: string;
}

export function initialize(landingUrl: string, pageName: string) {
  if (!getSessionContext()) {
    setSessionContext({ landingUrl, pageName });
  }
}

export function getSessionContext(): SessionContext | null {
  const sessionContextJson = getItem(CONTEXT_DATA_STORAGE_KEY);

  if (!sessionContextJson) return null;

  try {
    const sessionContext = <SessionContext>JSON.parse(sessionContextJson);
    return {
      ...sessionContext,
      requestCreatedFromUrl: document.location.href
    };
  } catch (_err) {
    // ignore
  }

  return null;
}

export function setSessionContext(ctx: SessionContext) {
  setItem(CONTEXT_DATA_STORAGE_KEY, JSON.stringify(ctx));
}

export function removeSessionContext() {
  removeItem(CONTEXT_DATA_STORAGE_KEY);
}
