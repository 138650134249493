import * as Sentry from '@sentry/nextjs';

export function getItem(key: string): string | null {
  try {
    return sessionStorage.getItem(key);
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
}

export function getAndParseItem<T>(key: string): T | null {
  try {
    const item = getItem(key);
    if (item === null) {
      return null;
    }

    const parsedItem = JSON.parse(item) as T;
    return parsedItem;
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
}

export function removeItem(key: string): void {
  try {
    sessionStorage.removeItem(key);
  } catch (err) {
    Sentry.captureException(err);
  }
}

export function setItem(key: string, value: string | Record<string, unknown>): boolean {
  if (process.env.NODE_ENV === 'development') {
    if (key.includes('ngStorage') && typeof value === 'string') {
      try {
        JSON.parse(value);
      } catch (err) {
        throw new Error('if use ngStorage prefix, value is need json or number or boolean');
      }
    }
  }

  try {
    const valueToSet = typeof value === 'string' ? value : JSON.stringify(value);
    sessionStorage.setItem(key, valueToSet);
    return true;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
}

export const locationSelectFieldSSKey = 'showLocationSelectFields';
export const addressFormSSKey = 'addressFormInput';
export const propertyTypeSSKey = 'propertyTypeFormInput';
export const ageFormSSKey = 'ageFormInput';
export const corporateindividualFormSSKey = 'corporateindividualFormInput';
export const initialResponseFormSSKey = 'initialResponseFormInput';
export const isClientModeSSKey = 'ngStorage-isClientMode';
export const shouldShowMikitySSKey = 'showMikity';
