import axios from 'axios';
import _ from 'lodash';
import { captureException } from '@sentry/nextjs';

import httpClient, { getInOneLanguage, setHeaders } from 'src/http-client';
import { parseCity, parsePrefecture } from 'utils/data-transform/location';
import { setItem as setLSItem } from 'utils/localStorage';
import { getItem as getSSItem, removeItem as removeSSItem } from 'utils/sessionStorage';
import { getItem, removeItem } from 'utils/webStorage';
import { formatLocationTitle } from 'src/modules/marketing-pages/utils';
import JobTypeAliasesService from 'src/services/job-type-alias';
import { StatusCodes } from 'http-status-codes';
import { makeRequestFormClientSourceString, makeSourceHeader } from 'utils/custom-headers';

const HEADER_ACCOUNT_REGISTERED_BY = 'account-registered-by';

function createBasicInfo(values, userId) {
  return httpClient.post(`/users/${userId}/basic-info`, values).then((response) => response.data);
}

function createProProfile(proProfile, userId) {
  return httpClient
    .post(`/users/${userId}/pro-profile`, { proProfile })
    .then((response) => response.data);
}

function getAvailableCities(prefectureId) {
  return httpClient
    .get('/location-cities', { params: { prefecture: prefectureId } })
    .then((res) => res.data);
}

function createAvailableLocations(selectedCityIds, selectedJobTypes) {
  return httpClient
    .post(`/available-locations/create`, { selectedCityIds, selectedJobTypes })
    .then((response) => response.data);
}

function getJobType(id) {
  return httpClient
    .get(`/job-types/${id}`)
    .then((response) => response.data)
    .catch(() => null);
}

function getPrivateProfile(userId) {
  return httpClient.get(`/private-profiles/${userId}`).then((response) => response.data);
}

function getUser(userId) {
  return httpClient.get(`/users/${userId}`).then((response) => response.data);
}

function getUtm() {
  const utm = getItem('ngStorage-utm');
  if (utm) {
    try {
      return JSON.parse(utm);
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
}

function getHttpReferrer() {
  return getSSItem('httpReferrer');
}

export function updateLastOnline(userId) {
  return httpClient.put(`/users/${userId}/last-online`).then((res) => res.data);
}

export function updateUser(user, userId) {
  // NOTE: reason of using axios
  // PUT in httpClient will be intercepted and the value will be rewritten to the current language only.
  // As a result, the `tilte` stored in the DB was rewritten, causing the loss of `en` and `ja`.
  const baseURL = `${process.env.API_HOST}/api`;
  const headers = httpClient.defaults.headers.common;
  return axios
    .put(`${baseURL}/users/${userId}`, user, { headers })
    .then((response) => response.data);
}

export async function createBusinessProfile(values, userId) {
  const { profile, proProfile } = values;

  try {
    await createProProfile(proProfile, userId);
    await updateUser({ proProfile, proRegistrationStep: 'avatar', profile }, userId);
    const [privateProfile, user] = await Promise.all([getPrivateProfile(userId), getUser(userId)]);
    return { ...user, privateProfile };
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return Promise.resolve();
  }
}

export async function getLocation(zipCode, language = 'en', checkOnly = false) {
  const locations = await httpClient
    .get('/locations', { params: { zipCode } })
    .then((response) => response.data);
  const [location] = locations;
  if (!location) {
    return undefined;
  }
  if (checkOnly) {
    return true;
  }
  const baseURL = `${process.env.API_HOST}/api`;
  const cityRes = await axios.get(`${baseURL}/location-cities/${location.city}`);
  const cityEn = parseCity(getInOneLanguage(cityRes.data, 'en'), 'en');
  const cityJa = parseCity(getInOneLanguage(cityRes.data, 'ja'), 'ja');
  const city = language === 'en' ? cityEn : cityJa;

  const prefectureRes = await axios.get(`${baseURL}/location-prefectures/${city.prefecture}`);
  const prefectureEn = parsePrefecture(getInOneLanguage(prefectureRes.data, 'en'), 'en');
  const prefectureJa = parsePrefecture(getInOneLanguage(prefectureRes.data, 'ja'), 'ja');
  const prefecture = language === 'en' ? prefectureEn : prefectureJa;

  const title = {
    en: formatLocationTitle('en', prefectureEn, cityEn),
    ja: formatLocationTitle('ja', prefectureJa, cityJa),
  };
  return { ...location, city, prefecture, title, zipCode };
}

// Get user without transforming data
export function getRawUserData(userId) {
  const headers = httpClient.defaults.headers.common;
  return axios
    .get(`${process.env.API_HOST}/api/users/${userId}`, { headers })
    .then((response) => response.data);
}

export function loadUser(userId) {
  const url = `${process.env.API_HOST}/api/users/${userId}`;
  return axios
    .get(url)
    .then((res) => res.data)
    .catch(() => null);
}

export async function getIsEmailInUse(email) {
  const url = `${process.env.API_HOST}/api/users?email=${encodeURIComponent(email)}`;
  try {
    await axios.head(url);
  } catch (error) {
    if (error.response?.status === StatusCodes.NOT_FOUND) {
      return false;
    }
    throw error;
  }
  return true;
}

export async function populateUserDefaultData(values, userId, language) {
  const isEn = language.startsWith('en');
  const userData = _.omit(values, ['password']);
  const utm = getUtm();
  const referer = getHttpReferrer();
  await httpClient.put(`/users/${userId}/notification-preferences`, {
    marketing: { betaUpdates: true, jobTypeUpdates: true, newsletter: true, proTips: true },
    transactional: {
      applyCreditsRefunded: true,
      newMessage: true,
      newRequest: true,
      requestActivity: true,
      requestReminders: true,
    },
  });
  const user = await getUser(userId);

  const updatedData = {
    ...user,
    ...userData,
    currentTierId: 'new-user',
    proRegistrationStep: 'confirm',
    profileComplete: false,
    settings: { defaultLanguage: isEn ? 'en' : 'ja' },
    utm,
  };

  const zipCode = _.get(values, 'proProfile.location.zipCode');
  const location = await getLocation(zipCode, language);
  let cities = [];

  if (location) {
    updatedData.proProfile.location = location;
    const prefectureId = _.get(location, 'prefecture.id');
    const cityId = _.get(location, 'city.id');
    if (prefectureId) {
      updatedData.proProfile.location.prefecture = prefectureId;
      cities = await getAvailableCities(prefectureId);

      // TODO: remove deprecated user proProfile.availableLocations after report / api cleanup PROD-794
      updatedData.proProfile.availableLocations = {
        cities: Object.fromEntries(cities.map((city) => [city.id, true])),
        clientGoToPro: true,
        proGoToClient: true,
        remote: false,
      };
    }
    if (cityId) {
      updatedData.proProfile.location.city = cityId;
    }
  }

  // TODO: Remove this.
  // Reason: This is really BAD to leave all those logic in client side
  const selectedJobTypes = _.get(values, 'proProfile.jobTypes');
  const jobTypesRes = await Promise.all(Object.keys(selectedJobTypes).map(getJobType));
  const enabledCategories = {};
  const enabledJobTypes = {};
  // NOTE: Move logic to server side
  // Delete logic after confirming stable (After Release PROD-289)
  const jobTypes = {};

  const initialCityIds = cities.map((city) => city.id);
  const initialJobTypes = [];
  let hasHowToProvideService = false;

  jobTypesRes.forEach((jobType) => {
    if (!jobType) return;
    if (!selectedJobTypes[jobType.id]) return;

    enabledCategories[jobType.categories[0]] = true;
    enabledJobTypes[jobType.id] = true;
    jobTypes[jobType.id] = {};
    hasHowToProvideService = false;

    jobType.fields.forEach((field) => {
      if (field.isMatchingCriteria) {
        jobTypes[jobType.id][field.key] = {};

        field.templateOptions.options.forEach((option) => {
          if (_.get(option.matchingCriteria, 'enableByDefault')) {
            jobTypes[jobType.id][field.key][option.value] = true;
          }
        });
      }

      // jobType option check for creating availableLocations data
      if (field.key === 'howToProvideService') {
        hasHowToProvideService = true;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (hasHowToProvideService) {
      initialJobTypes.push({
        jobTypeId: jobType.id,
        selectedMeetTypes: { clientGoToPro: true, proGoToClient: true, remote: true },
      });
    } else {
      initialJobTypes.push({
        jobTypeId: jobType.id,
        selectedMeetTypes: {},
      });
    }
  });

  // initialize new availableLocations
  createAvailableLocations(initialCityIds, initialJobTypes);

  const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const availableTimes = [];
  DAYS.forEach((day) => {
    // eslint-disable-next-line no-magic-numbers
    _.range(0, 24).forEach((hour) => {
      availableTimes.push(`${day}-${hour.toString().padStart(2, '0')}`);
    });
  });

  // origin jobtype id of the merged jobtypes (from 5 big verticals merge epic)
  const originJobTypeId = getSSItem('originJobTypeId');
  if (originJobTypeId) {
    const jobTypeAliases = await JobTypeAliasesService.getInstance().getJobTypeAliases();

    const originAlias = jobTypeAliases.find(
      (jobType) => jobType.jobTypeId === originJobTypeId
    ).alias;

    const jobTypeObjectsWithSameAlias = jobTypeAliases
      .filter((jobType) => jobType.alias === originAlias)
      .reduce((acc, jobType) => {
        acc[jobType.jobTypeId] = false;
        return acc;
      }, {});

    // display this pro only in originJobType's vertical LP
    updatedData.proProfile = {
      ...updatedData.proProfile,
      lpDisplayedJobTypes: {
        ...jobTypeObjectsWithSameAlias,
        [originJobTypeId]: true,
      },
      originJobTypeId,
    };
  }

  updatedData.proProfile = {
    ...updatedData.proProfile,
    availableTimes,
    enabledCategories,
    enabledJobTypes,
    jobTypes,
    referer,
    utm,
  };

  return updateUser(updatedData, userId).then((data) => {
    if (utm) {
      removeItem('ngStorage-utm');
    }
    if (referer !== null) {
      removeSSItem('httpReferrer');
    }
    if (data.proProfile) {
      return httpClient
        .get(`/private-profiles/${userId}`)
        .then((res) => ({ ...data, privateProfile: res.data }));
    }
    return data;
  });
}

export async function registerPro(values, language) {
  // Call register API to create new user
  let registerResp;
  // 'pro' always always has jobType at least one of them
  // i.g) values.proProfile.jpbTypes = {'personal-trainer' : true}
  const {
    proProfile: { jobTypes: jobTypeObject },
  } = values;

  const jobTypes = Object.keys(jobTypeObject).filter((jobType) => {
    if (jobTypeObject[jobType] === true) return jobType;
  });

  try {
    registerResp = await httpClient.post(
      '/auth/register',
      {
        email: values.email,
        mode: 'pro',
        password: values.password,
        jobTypes,
      },
      {
        headers: {
          [HEADER_ACCOUNT_REGISTERED_BY]: getRegistrationActor(values),
        },
      }
    );
    setLSItem('ngStorage-authData', JSON.stringify(registerResp.data));
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message);
  }
  const { token, uid } = registerResp.data;
  setHeaders({ token });
  try {
    updateLastOnline(uid);
    const res = await populateUserDefaultData(values, uid, language);
    await createBasicInfo(values, uid);
    return res;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return Promise.resolve();
  }
}

/**
 * @param {object} params
 * @param {string} params.email
 * @param {string} params.name
 * @param {string} params.phoneNumber
 * @param {boolean} params.phoneNumberShareable
 * @param {string} [params.loginPhoneNumber=undefined]
 * @param {string} [params.password=undefined]
 * @param {string} [params.phoneNumberVerificationToken=undefined]
 * @param language
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function registerClient(
  {
    email,
    name,
    phoneNumber,
    phoneNumberShareable,
    loginPhoneNumber,
    password,
    phoneNumberVerificationToken,
  },
  language
) {
  let registerResp;
  try {
    const registerPayload = loginPhoneNumber
      ? {
          payload: {
            loginPhoneNumber,
            password,
            phoneNumberVerificationToken,
          },
          mode: 'phoneNumberRegistration',
        }
      : {
          email,
          mode: 'client',
        };
    const additionalConfig = {
      headers: {
        ...makeSourceHeader(
          makeRequestFormClientSourceString({ mode: loginPhoneNumber ? 'phone' : 'email' })
        ),
      },
    };
    registerResp = await httpClient.post('/auth/register', registerPayload, additionalConfig);
    setLSItem('ngStorage-authData', JSON.stringify(registerResp.data));
  } catch (err) {
    throw new Error(err.response.data);
  }
  const { token, uid } = registerResp.data;
  const userResp = await httpClient.get(`/users/${uid}`);
  const user = userResp.data;

  const updatedUser = {
    ...user,
    email,
    phoneNumber,
    phoneNumberShareable,
    profile: {
      fullNameEnglish: language.startsWith('en') ? name : {},
      fullNameKanji: language.startsWith('en') ? {} : name,
      languages: { en: language.startsWith('en'), ja: !language.startsWith('en') },
    },
    profileComplete: true,
    settings: { defaultLanguage: language.startsWith('en') ? 'en' : 'ja' },
  };

  const utm = getUtm();
  if (utm) {
    updatedUser.utm = utm;
  }

  return httpClient.put(`/users/${uid}`, updatedUser, { headers: { 'x-access-token': token } });
}

export async function updateClientPersonalInfo(userId, name, phoneNumber, language) {
  const { data: user } = await httpClient.get(`/users/${userId}`);
  const namePropertyKey = language.startsWith('en') ? 'fullNameEnglish' : 'fullNameKanji';
  const updatedUser = {
    ...user,
    phoneNumber,
    profile: { ...user.profile, [namePropertyKey]: name },
  };

  return httpClient.put(`/users/${userId}`, updatedUser);
}

/**
 * https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
 * @param {string} string
 * @returns
 */
function isValidHttpsUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (err) {
    return false;
  }
  return url.protocol === 'https:';
}

export async function uploadAvatar(imgDataUrl, userId) {
  const data = new FormData();
  // if we have a social login avatar url it will be from some social provider CDN. Here imgDataUrl
  // is not actually an upload from the user but an existing url initially set on the user object during
  // social registration
  const uploadType = isValidHttpsUrl(imgDataUrl) ? 'socialLoginAvatarUrl' : 'imageDataUrl';
  uploadType === 'socialLoginAvatarUrl'
    ? data.append('socialLoginAvatarUrl', imgDataUrl)
    : data.append('imageDataUrl', imgDataUrl);
  let meta;
  try {
    ({ data: meta } = await httpClient.post('/upload/profile-photo', data));
  } catch (err) {
    let captureExceptionTags;
    if (err?.response?.data?.code === 'INVALID_SOCIAL_AVATAR_URL') {
      captureExceptionTags = {
        tags: {
          uploadType,
          proRegistrationStep: 'avatarPhotoUpload',
          // this should not be throwable as we checked isValidHttpsUrl above
          avatarHostname: new URL(imgDataUrl).hostname,
        },
      };
    }
    captureException(err, captureExceptionTags);
    throw err;
  }
  return httpClient
    .post(`/users/${userId}/avatar`, {
      proRegistrationStep: null,
      profile: { avatar: meta.url, avatarMeta: meta },
      profileComplete: true,
    })
    .then((res) => res.data)
    .catch((error) => {
      captureException(error, { tags: { uploadType, proRegistrationStep: 'userAvatarSave' } });
    });
}

function getRegistrationActor(values) {
  /** @see {@link https://zehitomo.atlassian.net/browse/PROD-1677} */
  return values.profile?.referrerDetail === 'リード' && values.referrer === 'sales'
    ? 'zehitomo-team-member'
    : 'user';
}
